import type {DrupalParagraph} from 'next-drupal';

import {COLOR_VARIANTS, TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {cn} from '@/lib/utils';
import {getAlignmentClasses} from 'lib/alignment';
import CTAButton from '../atoms/buttons/cta-button';

interface ParagraphCtaButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    paragraph: DrupalParagraph;
}

const ParagraphCtaButton = ({paragraph, className, ...props}: ParagraphCtaButtonProps) => {
    const alignmentClasses = getAlignmentClasses({
        horizontalAlignment: paragraph.field_horizontal_position,
        verticalAlignment: paragraph.field_vertical_position,
    });
    const buttonContent = paragraph.field_link?.title;
    const textColor = TEXT_COLOR_VARIANTS[paragraph.field_text_color] ?? TEXT_COLOR_VARIANTS['text-black'];
    const backgroundColor = paragraph?.field_primary_background_color
        ? COLOR_VARIANTS[paragraph?.field_primary_background_color]
        : COLOR_VARIANTS['bg-transparent'];

    if (!buttonContent) return null;

    return (
        <div {...props} className={cn(alignmentClasses, className)}>
            <CTAButton
                textColor={textColor}
                backgroundColor={backgroundColor}
                type={paragraph.field_cta_button_type ?? 'default'}
                href={paragraph.field_link?.url ?? '#'}
            >
                {buttonContent}
            </CTAButton>
        </div>
    );
};

export default ParagraphCtaButton;
