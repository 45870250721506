import type {DrupalMedia, DrupalNode, DrupalParagraph} from 'next-drupal';

import {NODE_TYPES, PARAGRAPH_TYPES, PRODUCT_FIELD_TYPES} from '@/lib/constants';
import {absoluteUrl} from '@/lib/utils';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import Body from './atoms/body';
import CTAButton from './atoms/buttons/cta-button';
import Heading from './atoms/heading';
import ParagraphImage from './paragraphs/paragraph--image';

interface SearchResults {
    node: DrupalNode;
}

const SearchResults = ({node}: SearchResults) => {
    const {t} = useTranslation();

    const getImage = () => {
        switch (node.type) {
            case NODE_TYPES.product:
                return (
                    node?.field_assets &&
                    node?.field_assets?.filter(
                        (asset: DrupalMedia) =>
                            asset.field_type === PRODUCT_FIELD_TYPES.packagingPicture || asset.field_type === PRODUCT_FIELD_TYPES.productPicture
                    )
                );
            case NODE_TYPES.article:
                return node?.field_image_paragraph && node?.field_image_paragraph;
            case NODE_TYPES.page:
                return node?.field_contents && node?.field_contents?.find((paragraph: DrupalParagraph) => paragraph.type === PARAGRAPH_TYPES.image);
            default:
                return false;
        }
    };

    const image = getImage();

    const getButtonTitle = () => {
        switch (node.type) {
            case NODE_TYPES.product:
                return t('productDetails');
            case NODE_TYPES.article:
            case NODE_TYPES.page:
                return t('readMore');
            default:
                return t('readMore');
        }
    };

    const renderBody = () => {
        switch (node.type) {
            case NODE_TYPES.product:
                return (
                    <>
                        {node?.title && (
                            <Heading type="h2" className="pb-2 text-[22px] font-semibold">
                                {node.title}
                            </Heading>
                        )}
                        {node?.field_headline && <Body className="font-regular pb-[1.3125rem]" value={node?.field_headline} />}
                    </>
                );
            case NODE_TYPES.article:
                return (
                    <>
                        {node?.field_category?.name && <p className="font-regular pb-[0.3125rem]">{node.field_category.name}</p>}
                        {node?.title && (
                            <Heading type="h2" className="pb-[1.3125rem] text-[22px] font-semibold">
                                {node.title}
                            </Heading>
                        )}
                    </>
                );
            case NODE_TYPES.page:
                return (
                    node?.title && (
                        <Heading type="h2" className="pb-[1.3125rem] text-[22px] font-semibold">
                            {node.title}
                        </Heading>
                    )
                );
            default:
                return null;
        }
    };

    const renderImage = () => {
        if (!image) {
            return null;
        }

        switch (node.type) {
            case NODE_TYPES.product:
                return (
                    image &&
                    image
                        .sort((a: DrupalMedia) => (a.field_type === PRODUCT_FIELD_TYPES.packagingPicture ? -1 : 1))
                        .map((asset: DrupalMedia) => {
                            if (!asset.field_media_image) return null;
                            const {uri, resourceIdObjMeta} = asset.field_media_image;
                            const {alt, width, height} = resourceIdObjMeta;
                            return (
                                <Image
                                    key={asset.name}
                                    className="h-full max-h-[130px] w-full object-contain"
                                    src={absoluteUrl(uri.url)}
                                    alt={alt ?? asset.name}
                                    width={+width}
                                    height={+height}
                                />
                            );
                        })
                        .filter(Boolean)[0]
                );
            case NODE_TYPES.article:
            case NODE_TYPES.page:
                return image && <ParagraphImage full imageClassName="rounded-lg max-w-[193px] w-full h-[127px]" paragraph={image} />;
            default:
                return null;
        }
    };

    return (
        <div className="border-b-1 flex flex-row border-b border-gray-300 pb-[1.875rem]">
            <div className="flex flex-1 flex-col items-start justify-between">
                <div>{renderBody()}</div>
                {node?.path?.alias && (
                    <CTAButton className="min-w-[150px] py-3 font-normal" backgroundColor="bg-lightgray" href={node.path.alias} excludeArrow>
                        {getButtonTitle()}
                    </CTAButton>
                )}
            </div>
            {<div className="flex max-w-[193px] flex-1 items-center justify-center">{renderImage()}</div>}
        </div>
    );
};

export default SearchResults;
