import type {DrupalNode, DrupalParagraph} from 'next-drupal';

import {PARAGRAPH_TYPES} from '@/lib/constants';
import Body from '../atoms/body';
import ParagraphSection from '../paragraphs/paragraph--section';

interface NodeBasicPageProps {
    node: DrupalNode;
    page?: number;
}

export const NodeBasicPage = ({node, page, ...props}: NodeBasicPageProps) => {
    const sections = node?.field_contents?.filter((child: DrupalNode) => child.type === PARAGRAPH_TYPES.section) ?? [];

    return (
        <article {...props}>
            {node.body?.processed && (
                <div className="prose mt-6 text-xl leading-loose">
                    <Body value={node.body?.processed} />
                </div>
            )}
            {sections.map((paragraph: DrupalParagraph, index) => {
                const nodeChildren = node.field_contents.filter((child: DrupalNode) => child.behavior_settings.layout_paragraphs.parent_uuid === paragraph.id);
                sections[index].allNodes = nodeChildren;

                return (
                    <ParagraphSection
                        currentSectionIndex={index}
                        allSections={sections}
                        paragraph={paragraph}
                        key={paragraph.id}
                        nodeChildren={nodeChildren}
                        meta={{path: node.path, page: page}}
                    />
                );
            })}
        </article>
    );
};
