import Heading from '@/components/atoms/heading';
import {useLanguageLinks} from '@/lib/language-links-context';
import {LanguageLink} from '@/lib/types';
import {LOCALES} from 'lib/constants';
import {capitalizeFirstLetter, cn} from 'lib/utils';
import {useTranslation} from 'next-i18next';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useState} from 'react';
import Flag from '../flag/flag';
import {Dialog, DialogContent, DialogTrigger} from './dialog';
import {DialogHeaderWrapper} from './dialog-elements';

export const LanguageSwitcher = () => {
    const {t} = useTranslation();
    const {locale: activeLocale, asPath} = useRouter();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(prev => !prev);
    };

    const localeSorter = (a: LanguageLink, b: LanguageLink) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    };

    const languageLinks = useLanguageLinks();
    const languagesList = Object.entries(languageLinks)
        .filter(([locale]) => locale !== 'default')
        .map(([_, value]: [string, LanguageLink]) => value);

    const safeActiveLocale = (activeLocale === LOCALES.default ? LOCALES.en : activeLocale) as LOCALES;
    const activeFlag = languageLinks[safeActiveLocale];

    const languages = languagesList?.sort(localeSorter);

    const getHref = (link: string, defaultLink: string) => {
        if (link.includes('/frontpage')) {
            return '/';
        }

        return link || defaultLink || asPath;
    };

    const getCode = () => (safeActiveLocale === LOCALES.en ? LOCALES.gb : safeActiveLocale);

    const renderLanguages = (showTitles: boolean = true) =>
        languages?.map(({locale, title, link, defaultLink}) => (
            <Link
                className={'block rounded-md p-4 py-2 text-center text-xl font-normal hover:bg-black/10 lg:py-0 lg:text-left lg:text-[27px] lg:leading-[56px]'}
                key={locale}
                title={title}
                href={getHref(link, defaultLink)}
                locale={locale}
                onClick={handleOpen}
            >
                {showTitles && (
                    <span
                        className={cn({
                            'font-semibold text-white': safeActiveLocale === locale,
                        })}
                    >
                        {capitalizeFirstLetter(title)}
                    </span>
                )}
            </Link>
        ));

    return (
        <Dialog open={open} onOpenChange={handleOpen}>
            <DialogTrigger asChild>
                <button className="inline-flex h-[26px] w-[26px] transform items-center justify-center transition-transform hover:scale-100 md:hover:scale-110">
                    <Flag
                        className={cn('h-full w-full rounded-full object-cover')}
                        code={getCode()}
                        fallback={() => <span>{activeFlag?.title || safeActiveLocale}</span>}
                        alt={activeFlag?.title || safeActiveLocale}
                        externalIcon={activeFlag?.icon}
                    />
                    <div className="hidden">{renderLanguages(false)}</div>
                </button>
            </DialogTrigger>
            <DialogContent className="px-2">
                <DialogHeaderWrapper handleClose={handleOpen} />
                <div className="mx-auto max-w-6xl grid-cols-[1fr_2fr] justify-center gap-12 py-8 text-[20px] lg:grid lg:max-h-[100vh-115px] lg:px-24 lg:pt-20">
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: t('languageSwitcherTitle'),
                        }}
                        className="mb-[50px] mt-5 text-center text-[27px] leading-[35px] lg:text-left lg:text-[40px] lg:leading-[50px]"
                    />
                    <div className="flex h-full flex-col flex-wrap items-start lg:max-h-[70vh]">{renderLanguages()}</div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
