import type {DrupalParagraph} from 'next-drupal';
import WebformComponent from '../webforms/WebformComponent';

interface ParagraphWebformProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphWebform = ({paragraph, className}: ParagraphWebformProps) =>
    Boolean(paragraph.field_webform?.webform) && <WebformComponent className={className} webform={paragraph.field_webform?.webform} />;

export default ParagraphWebform;
