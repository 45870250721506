import {DotsHorizontalIcon} from '@radix-ui/react-icons';
import {ArrowLeft, ArrowRight} from 'lucide-react';
import * as React from 'react';

import {ButtonProps, buttonVariants} from '@/components/atoms/buttons/button';
import {cn} from '@/lib/utils';
import Link from 'next/link';

const PaginationRoot = ({className, ...props}: React.ComponentProps<'nav'>) => (
    <nav role="navigation" aria-label="pagination" className={cn('mx-auto flex w-full justify-center', className)} {...props} />
);

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(({className, ...props}, ref) => (
    <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({className, ...props}, ref) => (
    <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
    isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
    React.ComponentProps<typeof Link>;

const PaginationLink = ({className, isActive, size = 'icon', ...props}: PaginationLinkProps) => (
    <PaginationItem>
        <Link
            aria-current={isActive ? 'page' : undefined}
            className={cn(
                buttonVariants({
                    variant: isActive ? 'secondary' : 'ghost',
                    size,
                    textSize: 'base',
                }),
                className
            )}
            {...props}
        />
    </PaginationItem>
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({className, ...props}: React.ComponentProps<typeof PaginationLink>) => (
    <PaginationLink aria-label="Go to previous page" size="default" className={cn(className)} {...props}>
        <ArrowLeft className="h-4 w-4" />
    </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({className, ...props}: React.ComponentProps<typeof PaginationLink>) => (
    <PaginationLink aria-label="Go to next page" size="default" className={cn(className)} {...props}>
        <ArrowRight className="h-4 w-4" />
    </PaginationLink>
);

const PaginationEllipsis = ({className, ...props}: React.ComponentProps<'span'>) => (
    <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center', className)} {...props}>
        <DotsHorizontalIcon className="h-4 w-4" />
        <span className="sr-only">More pages</span>
    </span>
);

export {PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious, PaginationRoot};
