import {cn} from 'lib/utils';

interface CloseButtonProps extends React.HTMLAttributes<HTMLDivElement> {}

const CloseButton = ({className}: CloseButtonProps) => (
    <div
        className={cn(
            'duration-400 relative block h-[21px] w-[31px] transition-all delay-0 ',
            'before:content[""] before:duration-400 before:absolute before:bottom-0 before:left-0 before:h-[3px] before:w-full before:bg-black before:transition-all',
            'after:content[""] after:duration-400 after:absolute after:left-0 after:top-0 after:h-[3px] after:w-full after:bg-black after:transition-all',
            'before:-translate-y-[9px] before:rotate-45 after:translate-y-[9px] after:-rotate-45',
            className
        )}
    />
);

export default CloseButton;
