import type {DrupalParagraph} from 'next-drupal';

import {useNavbarContext} from '@/context/NavbarContext';
import {CONTAINER_WIDTH_VARIANTS, SCREENS} from '@/lib/constants';
import {getResponsibleImageProps} from '@/lib/get-image-props';
import useScreenSize from 'hooks/useScreenSize';
import {cn} from 'lib/utils';
import Body from '../atoms/body';
import CTAButton from '../atoms/buttons/cta-button';
import ParagraphCtaButton from './paragraph--cta-button';
import ParagraphHeading from './paragraph--heading';

interface BackButtonProps {
    url?: string;
    title?: string;
    visible?: boolean;
    className?: string;
}
interface ParagraphHeroImageProps {
    paragraph: DrupalParagraph;
    imageClassName?: string;
    className?: string;
    teaserDisplay?: boolean;
    display?: string;
    backButton?: BackButtonProps;
    fullHeight?: boolean;
}

const ParagraphHeroImage = ({paragraph, imageClassName, className, display, teaserDisplay, backButton, fullHeight}: ParagraphHeroImageProps) => {
    const HORIZONTAL_ALIGNMENT_CLASSES = {
        left: 'md:justify-start md:text-left',
        center: 'md:justify-center md:text-center',
        right: 'md:justify-end md:text-right',
    } as const;

    const VERTICAL_ALIGNMENT_CLASSES = {
        top: 'md:items-start',
        center: 'md:items-center',
        bottom: 'md:items-end',
    } as const;

    const screen = useScreenSize();
    const {isOverlayed} = useNavbarContext();
    const isHeading = Boolean(paragraph.field_heading_paragraph?.field_title);
    const isButton = Boolean(paragraph.field_cta_button_paragraph?.field_link?.title);
    const isBody = Boolean(paragraph.field_body?.processed);
    const isBackButton = Boolean(backButton?.url || backButton?.visible);
    const displayType = display || paragraph.field_display_type;
    const paragraphImageDesktop = paragraph?.field_image;
    const paragraphImageMobile = paragraph?.field_image_mobile;
    const paragraphImageTablet = paragraph?.field_image_tablet;

    const headingHorizontalAlignment = paragraph?.field_heading_paragraph?.field_horizontal_position;
    const headingVerticalAlignment = paragraph?.field_heading_paragraph?.field_vertical_position;

    const buttonHorizontalAlignment = paragraph?.field_cta_button_paragraph?.field_horizontal_position;
    const buttonVerticalAlignment = paragraph?.field_cta_button_paragraph?.field_vertical_position;

    const headingHorizontalAlignmentClass = HORIZONTAL_ALIGNMENT_CLASSES[headingHorizontalAlignment] ?? HORIZONTAL_ALIGNMENT_CLASSES.left;
    const headingVerticalAlignmentClass = VERTICAL_ALIGNMENT_CLASSES[headingVerticalAlignment] ?? VERTICAL_ALIGNMENT_CLASSES.bottom;

    const buttonHorizontalAlignmentClass = HORIZONTAL_ALIGNMENT_CLASSES[buttonHorizontalAlignment] ?? HORIZONTAL_ALIGNMENT_CLASSES.left;
    const buttonVerticalAlignmentClass = VERTICAL_ALIGNMENT_CLASSES[buttonVerticalAlignment] ?? VERTICAL_ALIGNMENT_CLASSES.bottom;

    const containerWidth = paragraph?.behavior_settings?.layout_paragraphs?.config?.container_width
        ? CONTAINER_WIDTH_VARIANTS[paragraph?.behavior_settings.layout_paragraphs?.config?.container_width]
        : CONTAINER_WIDTH_VARIANTS.wide;

    const ctaButtonType = paragraph?.field_cta_button_paragraph?.field_cta_button_type;
    const isCtaBackButtonMobilePage = isButton && ctaButtonType === 'back' && !backButton && screen.name === SCREENS.mobile.name;
    const headingType = paragraph?.field_heading_paragraph?.field_heading_type ?? 'h1';

    const imgClassName = cn(
        'w-full',
        {
            'object-cover': teaserDisplay,
            'object-contain lg:object-cover': !teaserDisplay,
            ...(displayType === 'cut' &&
                !teaserDisplay && {
                    'lg:h-hero-screen-cut': isOverlayed,
                    'lg:h-hero-image-desktop-cut': !isOverlayed,
                }),
            ...(displayType === 'full' &&
                !teaserDisplay && {
                    'lg:h-safe-screen': isOverlayed,
                    'lg:h-hero-image-desktop': !isOverlayed,
                    'h-hero-image-mobile object-cover': backButton || fullHeight,
                    'h-hero-image-mobile-cut object-cover': !backButton && !fullHeight,
                }),
        },
        imageClassName
    );

    const {
        imgProps: {alt: imageAlt, ...restImageProps},
        desktopSource,
        mobileSource,
        tabletSource,
    } = getResponsibleImageProps(paragraphImageDesktop, paragraphImageTablet, paragraphImageMobile, imgClassName);

    return (
        <>
            {screen.name && paragraphImageDesktop && (
                <article className={cn('relative', className)}>
                    <picture>
                        <source {...desktopSource} />
                        {tabletSource && <source {...tabletSource} />}
                        {mobileSource && <source {...mobileSource} />}
                        <img alt={imageAlt} srcSet={desktopSource.srcSet} {...restImageProps} />
                    </picture>
                    {(isBackButton || isHeading || isBody || isButton) && (
                        <div
                            className={cn(
                                containerWidth,
                                'absolute left-1/2 top-0 z-10 flex h-full w-full -translate-x-1/2 flex-col justify-end gap-2 p-6 py-16 md:gap-8 md:p-16 lg:p-24 lg:pt-[var(--navbar-height-desktop)]',
                                {
                                    'justify-start': isCtaBackButtonMobilePage,
                                    'md:pb-2 lg:pb-2': isButton,
                                }
                            )}
                        >
                            {isBackButton && (
                                <span className={backButton?.className}>
                                    <CTAButton href={backButton?.url} type="back" textColor="text-white" className="pl-0 font-normal">
                                        {backButton?.title}
                                    </CTAButton>
                                </span>
                            )}
                            {isHeading && (
                                <ParagraphHeading
                                    className={cn('py-6 text-[27px] font-light leading-[35px]', {
                                        'lg:text-[40px] lg:leading-[50px]': headingType === 'h1',
                                    })}
                                    containerClassName={cn('h-full items-start mt-0', headingHorizontalAlignmentClass, headingVerticalAlignmentClass, {
                                        'justify-left text-left': isBackButton,
                                        'justify-center text-center': !isBackButton,
                                        'justify-start': isCtaBackButtonMobilePage || (ctaButtonType === 'continue' && backButton),
                                        'h-min': isCtaBackButtonMobilePage,
                                    })}
                                    paragraph={paragraph.field_heading_paragraph}
                                />
                            )}
                            {isBody && (
                                <Body
                                    className={cn('mx-auto h-full w-full', {
                                        'h-min': isCtaBackButtonMobilePage,
                                    })}
                                    value={paragraph.field_body?.processed}
                                />
                            )}
                            {isButton && (
                                <div
                                    className={cn('flex items-center justify-center', buttonHorizontalAlignmentClass, buttonVerticalAlignmentClass, {
                                        'h-[60px] justify-start': isCtaBackButtonMobilePage,
                                        'h-[40%]': screen.name !== SCREENS.mobile.name && !isBody,
                                        'h-[45%]': screen.name !== SCREENS.mobile.name && isBody,
                                        'h-[100%]': screen.name !== SCREENS.mobile.name && ctaButtonType === 'back' && !backButton,
                                    })}
                                >
                                    <ParagraphCtaButton className="py-2" paragraph={paragraph.field_cta_button_paragraph} />
                                </div>
                            )}
                        </div>
                    )}
                </article>
            )}
        </>
    );
};

export default ParagraphHeroImage;
