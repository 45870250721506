import type {DrupalNode} from 'next-drupal';

import {useTranslation} from 'next-i18next';
import Link from 'next/link';
import Body from '../atoms/body';
import CTAButton from '../atoms/buttons/cta-button';
import Heading from '../atoms/heading';
import ParagraphHeroImage from '../paragraphs/paragraph--hero-image';

interface NodeArticleTeaserProps {
    node: DrupalNode;
    more?: string;
}

export const NodeArticleTeaser = ({node, more}: NodeArticleTeaserProps) => {
    const {t} = useTranslation();

    return (
        <article className="space-y-10">
            {node.title && (
                <Heading type="h4" tag="h2" className="line-clamp-2 h-[calc(30px*2)] font-semibold leading-[30px]">
                    <Link href={node.path?.alias}>{node.title}</Link>
                </Heading>
            )}

            {node.field_image_paragraph && (
                <Link className="block" href={node.path?.alias}>
                    <ParagraphHeroImage imageClassName="rounded-lg h-[224px]" paragraph={node.field_image_paragraph} teaserDisplay />
                </Link>
            )}

            <div className="h-[calc(25px*4)]">
                {node.field_body?.processed && <Body className="line-clamp-4 h-full text-[16px] leading-[25px] " value={node.field_body?.processed} />}
            </div>

            {node.path?.alias && (
                <CTAButton backgroundColor="bg-primary" href={node.path?.alias}>
                    {more ?? t('readMore')}
                </CTAButton>
            )}
        </article>
    );
};

export default NodeArticleTeaser;
