import type {DrupalBlock, DrupalMenuLinkContent} from 'next-drupal';

import Menu from '@/components/molecules/menu/menu';
import {useNavbarContext} from '@/context/NavbarContext';
import useScreenSize from '@/hooks/useScreenSize';
import useScrollIndicator from '@/hooks/useScrollIndicator';
import {SCREENS} from '@/lib/constants';
import {AnimatePresence, motion} from 'framer-motion';
import {cn} from 'lib/utils';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import HamburgerButton from './atoms/buttons/hamburger-button';
import Logo from './atoms/logo';
import {LanguageSwitcher} from './molecules/dialog/language-switcher';
import SearchBar from './molecules/dialog/search-bar';
import {SocialMenu} from './molecules/menu/social-menu';

interface NavbarProps {
    menu: DrupalMenuLinkContent[];
    social: DrupalMenuLinkContent[];
    search: DrupalBlock;
}

const MenuContainer = ({children, isMenuActive}) => (
    <motion.div
        initial={{height: 0}}
        animate={{height: 'calc(100vh - var(--navbar-height-mobile))'}}
        exit={{height: 0}}
        className={cn('overflow-y-scroll bg-primary', {
            hidden: !isMenuActive,
        })}
    >
        <div className={cn('flex flex-col justify-between gap-8 p-6 pr-8 pt-0')}>{children}</div>
    </motion.div>
);

export const Navbar = ({menu, social, search}: NavbarProps) => {
    const {isMenuActive, setIsMenuActive, isScrolled, isOverlayed, backgroundColor, textColor} = useNavbarContext();
    const screen = useScreenSize();
    const router = useRouter();
    const isNavbarItemsScrolled = useScrollIndicator(150);

    useEffect(() => {
        router.isReady && setIsMenuActive(false);
    }, [router, setIsMenuActive]);

    return (
        <nav
            id="navbar"
            onKeyDown={e => {
                if (e.key === 'Escape') {
                    setIsMenuActive(false);
                }
            }}
            className={cn(
                'left-0 right-0 top-0 z-50 block max-h-screen whitespace-nowrap bg-primary shadow-lg transition-colors lg:shadow-none ',
                backgroundColor,
                {
                    [textColor]: screen.name === SCREENS.desktop.name,
                },
                {
                    'lg:text-black lg:shadow-lg': isScrolled && !isMenuActive,
                    'lg:absolute lg:bg-transparent': isOverlayed,
                    'lg:block': !isOverlayed,
                    'opacity-100 transition-opacity duration-500 ease-in-out lg:fixed lg:bg-primary': isScrolled,
                    'text-black lg:bg-transparent': isMenuActive,
                    'opacity-0': isNavbarItemsScrolled && !isScrolled && screen.name === SCREENS.desktop.name,
                    sticky: isScrolled || screen.name !== SCREENS.desktop.name,
                }
            )}
        >
            <MobileNavContent menu={menu} social={social} search={search} />
            <DesktopNavContent menu={menu} search={search} />
        </nav>
    );
};

const MobileNavContent = ({menu, social, search}) => {
    const {t} = useTranslation();
    const {isMenuActive, setIsMenuActive, setIsMobileMenuActive} = useNavbarContext();

    const openModal = () => {
        setIsMenuActive(prev => !prev);
        setIsMobileMenuActive(prev => !prev);
    };

    return (
        <div className="flex-grow lg:hidden">
            {isMenuActive && <div className="absolute left-0 right-0 top-0 h-mobile-navbar shadow-lg" />}

            <div className="relative flex h-mobile-navbar flex-wrap items-center justify-between pl-6 pr-5 ">
                <HamburgerButton color="black" onClick={openModal} isActive={isMenuActive} />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                    <Logo />
                </div>
                <div className="flex items-center justify-center gap-4">
                    {search && <SearchBar color="black" search={search} />}
                    {!isMenuActive && <LanguageSwitcher />}
                </div>
            </div>
            <AnimatePresence>
                {isMenuActive && (
                    <MenuContainer isMenuActive={isMenuActive}>
                        <Menu.Mobile menu={menu} className={'flex flex-col items-start'} />
                        <div className="flex flex-wrap items-center justify-between gap-4">
                            <SocialMenu menu={social} showTitle={false} inMenu />
                            <div className="flex gap-4">
                                {t('language')}
                                <LanguageSwitcher />
                            </div>
                        </div>
                    </MenuContainer>
                )}
            </AnimatePresence>
        </div>
    );
};

const DesktopNavContent = ({menu, search}) => {
    const {isScrolled} = useNavbarContext();

    return (
        <div
            className={cn('hidden h-[var(--navbar-height-desktop)] justify-center lg:flex', {
                'h-scrolled-navbar': isScrolled,
            })}
        >
            <Menu.Desktop menu={menu} search={search}>
                <Logo width={233} />
            </Menu.Desktop>
        </div>
    );
};
