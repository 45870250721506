import type {ParamValue} from '@/lib/types';

import {useSearchParams} from 'next/navigation';
import {useCallback} from 'react';

const useCreateQueryString = () => {
    const searchParams = useSearchParams();
    const createQueryString = useCallback(
        (paramsValues: ParamValue[]) => {
            const params = new URLSearchParams(searchParams);
            paramsValues.forEach(({name, value}) => {
                if (!value || !value.length) {
                    params.delete(name);
                    return;
                }
                params.set(name, value);
            });
            return params.toString();
        },
        [searchParams]
    );
    return createQueryString;
};

export default useCreateQueryString;
