import {ComponentType, PropsWithChildren} from 'react';
type Props = {
    customProps?: Record<string, unknown>;
};
export const withProps = <P extends Props>(Component: ComponentType<P>, customProps: P['customProps']): ComponentType<PropsWithChildren<P>> => {
    const ComponentWithProps = props => <Component {...props} {...customProps} />;
    const displayName = Component?.displayName || 'Component';
    ComponentWithProps.displayName = `withCustomProps(${displayName})`;
    return ComponentWithProps;
};
