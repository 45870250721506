import type {DrupalMenuLinkContentWithWebform} from '@/lib/interfaces';
import type {DrupalBlock, DrupalMenuLinkContent} from 'next-drupal';

import withLazyLoad from '@/hoc/withLazyLoad';
import {absoluteUrl} from '@/lib/utils';
import dynamic from 'next/dynamic';
import Body from './atoms/body';
import {ContactMenu} from './contact-menu';
import Menu from './molecules/menu/menu';
import {SocialMenu} from './molecules/menu/social-menu';

const Lottie = dynamic(() => import('react-lottie-player'), {ssr: false});
const LazyLoadLottie = withLazyLoad(Lottie);

interface FooterProps {
    menu: DrupalMenuLinkContent[];
    social: DrupalMenuLinkContent[];
    contact: DrupalMenuLinkContentWithWebform[];
    contactInfo: DrupalBlock;
    footerAnimation?: DrupalBlock;
}

export const Footer = ({menu, social, contact, contactInfo, footerAnimation}: FooterProps) => (
    <nav className="relative w-full bg-primary">
        {footerAnimation?.field_animation?.field_animation?.field_media_lottie_file?.uri?.url && (
            <LazyLoadLottie
                loop
                path={absoluteUrl(footerAnimation.field_animation.field_animation.field_media_lottie_file.uri.url)}
                play
                className="absolute bottom-[calc(100%)] left-1/2 z-10 w-full min-w-[300px] max-w-[600px] -translate-x-1/2 translate-y-20"
            />
        )}

        <div className="wide-container flex flex-col gap-12 px-6 py-12 md:pt-32">
            <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between md:gap-y-12">
                <ContactMenu menu={contact} />
                <SocialMenu showTitle={false} menu={social} />
            </div>
            <div className="flex flex-col gap-12 md:grid md:grid-flow-row md:grid-cols-2 md:gap-0">
                <div className="border-b border-t border-black py-12 md:border-b-0 md:pr-20">
                    <Menu.Footer menu={menu} />
                </div>
                {contactInfo.body?.processed && (
                    <div className="md:border-l md:border-t md:border-black md:px-20 md:py-12">
                        <Body value={contactInfo.body?.processed} />
                    </div>
                )}
            </div>
        </div>
    </nav>
);
