import type {MultiSelectItem, ParamValue, ViewFilter} from '@/lib/types';
import type {DrupalNode, JsonApiResource} from 'next-drupal';

import gmoFreeImage from '@/assets/gmo-free.svg';
import noAnimalTestingImage from '@/assets/no-animal-testing.svg';
import noArtificialAdditivesImage from '@/assets/no-artificial-additives.svg';
import noWheatSoyaImage from '@/assets/no-wheat-soya.svg';
import productListingFooterAsset from '@/assets/product-listing-footer.webp';
import useCreateQueryString from '@/hooks/useCreateQueryString';
import useEvent from '@/hooks/useEvent';
import useScreenSize from '@/hooks/useScreenSize';
import {SCREENS, STORAGE_KEYS} from '@/lib/constants';
import {productFetchOptions} from '@/lib/views';
import i18nConfig from '@/next-i18next.config';
import {zodResolver} from '@hookform/resolvers/zod';
import {Cross2Icon} from '@radix-ui/react-icons';
import {keepPreviousData, useQuery, useQueryClient} from '@tanstack/react-query';
import {cn, saveFilters} from 'lib/utils';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import {usePathname} from 'next/navigation';
import {useRouter} from 'next/router';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useForm, useFormContext} from 'react-hook-form';
import * as z from 'zod';
import {Button} from '../atoms/buttons/button';
import CTAButton from '../atoms/buttons/cta-button';
import DecorCurve from '../atoms/decor-curve';
import Heading from '../atoms/heading';
import Loader from '../atoms/loader';
import Filter from '../atoms/svg/filter';
import {Form, FormField, FormItem} from '../molecules/form/form';
import MultiSelect from '../molecules/multi-select/multi-select';
import Pagination from '../molecules/pagination/pagination';
import NodeProductTeaser from '../nodes/node--product--teaser';

const ViewProductListing = ({view: viewConfig, meta}) => {
    const screen = useScreenSize();
    const {t} = useTranslation();
    const pathname = usePathname();
    const router = useRouter();
    const defaultLocale = i18nConfig.defaultLocale;
    const {locale: activeLocale} = router;
    const searchParams = new URLSearchParams(`?${router.asPath.split('?')[1]}`);
    const createQueryString = useCreateQueryString();
    const [isFiltersShown, setIsFiltersShown] = useState(false);

    const displayId = viewConfig.resourceIdObjMeta.display_id;
    const viewName = `${viewConfig.resourceIdObjMeta.drupal_internal__target_id}--${displayId}`;
    const filters: ViewFilter[] = viewConfig?.display?.[displayId]?.display_options?.filters || viewConfig?.display?.default?.display_options?.filters;
    const exposedFilters: ViewFilter[] = Object.values(filters).filter((filter: ViewFilter) => filter.exposed);
    const paginationLength =
        viewConfig?.display?.[displayId]?.display_options?.pager?.options?.items_per_page ||
        viewConfig?.display?.default?.display_options?.pager?.options?.items_per_page;
    const page = isNaN(meta?.page) ? 0 : Number(meta?.page);
    const [currentPage, setCurrentPage] = useState(page);

    const formSchema = z.object(
        exposedFilters.reduce((acc, filter) => {
            if (filter.expose.identifier) {
                return {...acc, [filter.expose.identifier]: z.string().optional()};
            }
            return acc;
        }, {})
    );

    const defaultFilters = Object.values(filters)
        .filter((filter: ViewFilter) => filter.exposed)
        .reduce((acc, filter) => {
            const value = searchParams.get(filter.expose.identifier);
            if (value) {
                const searchParamsValue: Record<string, MultiSelectItem[]> = {
                    [filter.expose.identifier]: value
                        .split(',')
                        .map(filterValue => {
                            if (!filterValue || filterValue === '') return null;
                            return {value: filterValue, label: filter.value[filterValue]};
                        })
                        .filter(Boolean),
                };
                return {...acc, ...searchParamsValue};
            }
            return acc;
        }, {});

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: defaultFilters,
    });

    const {getValues, reset} = form;

    const queryClient = useQueryClient();
    const {
        data: view,
        isFetching,
        isLoading,
        isPlaceholderData,
        refetch,
    } = useQuery({
        ...productFetchOptions(viewName, currentPage, activeLocale, defaultLocale, getValues, exposedFilters),
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    });

    const createPagePath = (page: number) => {
        const pageRegex = new RegExp(`/\\d+$`);
        let newPath = pathname.replace(pageRegex, '');
        if (page <= 1) {
            return newPath;
        }
        newPath = `${newPath}/${page}`;
        return newPath;
    };

    const handlePageChange = (page?: number) => {
        if (!isPlaceholderData) {
            const exposedFiltersIds: string[] = exposedFilters.map(filter => filter.expose.identifier);
            const paramValues: ParamValue[] = exposedFiltersIds.map(filter => {
                const filterValues = getValues()
                    [filter]?.map(filterValue => filterValue.value)
                    .join(',');
                return {name: filter, value: filterValues ?? []} satisfies ParamValue;
            });
            const queryStrings = createQueryString(paramValues);
            const newPath = createPagePath(page + 1);
            router.replace(`${newPath}${queryStrings ? `?${queryStrings}` : ''}`, undefined, {shallow: true, scroll: false});
            setCurrentPage(page);
        }
    };

    const submitForm = () => {
        form.handleSubmit(async () => await queryClient.invalidateQueries({queryKey: [viewName]}));
        const exposedFiltersIds: string[] = exposedFilters.map(filter => filter.expose.identifier);
        const paramValues: ParamValue[] = exposedFiltersIds.map(filter => {
            const filterValues = getValues()
                [filter]?.map(filterValue => filterValue.value)
                .join(',');
            return {name: filter, value: filterValues ?? []} satisfies ParamValue;
        });
        const queryStrings = createQueryString(paramValues);
        const newPath = createPagePath(0);
        setCurrentPage(0);
        router.replace(`${newPath}?${queryStrings}`, undefined, {shallow: true, scroll: false});
        refetch();
    };

    const resetForm = async () => {
        await router.replace(
            {
                pathname: createPagePath(0),
            },
            null,
            {
                scroll: false,
                shallow: true,
            }
        );

        reset(Object.fromEntries(exposedFilters.map(filter => [filter.expose.identifier, []])));
        setCurrentPage(0);
        refetch();
    };

    const allProducts = Number(view?.meta?.count);

    useEffect(() => {
        saveFilters(STORAGE_KEYS.productFilters, router.asPath);
    }, [router.asPath]);

    const applyFilters = () => {
        submitForm();
        setIsFiltersShown(false);
    };

    const clearFilters = () => {
        resetForm();
        setIsFiltersShown(false);
    };

    const products = view?.results as JsonApiResource;

    return (
        <div className="relative z-10 mx-auto items-start gap-10 pb-8 pt-8">
            <div className="absolute -top-[1vw] left-0 -z-10 flex h-[calc(100%+1vw)] w-full flex-col">
                <DecorCurve className="-mb-1 w-full" />
                <div className="flex-grow bg-background" />
            </div>
            <Form {...form}>
                <Filters
                    isFiltersShown={isFiltersShown}
                    filters={exposedFilters}
                    applyFilters={applyFilters}
                    clearFilters={clearFilters}
                    setIsFiltersShown={setIsFiltersShown}
                />
            </Form>
            <div className="flex flex-col items-center">
                <div className="wide-container flex w-full flex-wrap items-center gap-4 px-4 pb-4 md:px-12">
                    <div className="relative my-6 flex flex-grow flex-wrap justify-between md:justify-center">
                        {products?.length && allProducts >= paginationLength ? (
                            <div
                                className={cn('flex flex-col justify-center', {
                                    'pointer-events-none animate-pulse  opacity-70': isLoading || isFetching,
                                })}
                            >
                                <Pagination
                                    simple={screen.name === SCREENS.mobile.name ? true : false}
                                    currentPage={currentPage + 1}
                                    onPageChange={page => handlePageChange(page - 1)}
                                    pageSize={paginationLength}
                                    totalCount={allProducts}
                                    path={meta?.path}
                                />
                            </div>
                        ) : null}
                        {!isFiltersShown ? (
                            <div className="ml-auto md:absolute md:right-0 md:top-1/2 md:-translate-y-1/2">
                                <div className="flex items-center gap-2 md:gap-4">
                                    <span className="text-text-secondary">{t('filter')}</span>
                                    <button onClick={() => setIsFiltersShown(prev => !prev)} className="flex items-center justify-center">
                                        <Filter />
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {products?.length ? (
                    <div
                        className={cn('wide-container grid grid-flow-row gap-10 md:grid-cols-2 md:px-12 lg:grid-cols-3', {
                            'pointer-events-none animate-pulse opacity-70': isLoading || isFetching,
                        })}
                    >
                        {products?.map((node: DrupalNode) => <NodeProductTeaser.Standard key={node.id} node={node} />)}
                    </div>
                ) : null}
                {(isFetching || isLoading) && !products?.length && (
                    <div className="h-48 py-8">
                        <Loader />
                    </div>
                )}
                {!isFetching && !isLoading && !products?.length && (
                    <Heading type="h5" className="py-20 text-center">
                        {t('noProductsFound')}
                    </Heading>
                )}
                <div
                    className={cn('flex px-2 pt-12 md:justify-center', {
                        'pointer-events-none animate-pulse opacity-70': isLoading || isFetching,
                    })}
                >
                    {products?.length && allProducts >= paginationLength ? (
                        <Pagination
                            currentPage={currentPage + 1}
                            onPageChange={page => handlePageChange(page - 1)}
                            pageSize={paginationLength}
                            totalCount={allProducts}
                            simple={screen.name === SCREENS.mobile.name ? true : false}
                            path={meta?.path}
                        />
                    ) : null}
                </div>
            </div>
            <ProductListingFooter />
        </div>
    );
};

const Filters = ({
    filters,
    applyFilters,
    clearFilters,
    setIsFiltersShown,
    isFiltersShown,
}: {
    isFiltersShown: boolean;
    filters: ViewFilter[];
    applyFilters: () => void;
    clearFilters: () => void;
    setIsFiltersShown: (showFilters: boolean) => void;
}) => {
    const {t} = useTranslation();
    const formContext = useFormContext();
    const containerRef = useRef<HTMLDivElement>(null);

    const removeOverflowHiddne = useCallback(() => {
        if (isFiltersShown) {
            containerRef.current?.classList.remove('overflow-hidden');
        }
    }, [isFiltersShown, containerRef]);

    const addOverflowHidden = useCallback(() => {
        containerRef.current?.classList.add('overflow-hidden');
    }, [containerRef]);

    const handleClose = () => {
        addOverflowHidden();
        setIsFiltersShown(false);
    };

    useEvent({
        callback: removeOverflowHiddne,
        event: 'transitionend',
        ref: containerRef,
    });

    return (
        <div
            className={cn('wide-container grid transition-[grid-template-rows] duration-500', {
                'grid-rows-[0fr] ease-in-out': !isFiltersShown,
                'grid-rows-[1fr] ease-in-out': isFiltersShown,
            })}
        >
            <div ref={containerRef} className={cn('flex flex-col items-center gap-4 overflow-hidden')}>
                <div
                    className={cn('flex w-full flex-row items-start transition-opacity duration-300 lg:items-center', {
                        'opacity-1 delay-200': isFiltersShown,
                        'opacity-0': !isFiltersShown,
                    })}
                >
                    <div className="flex w-full flex-wrap items-center justify-center gap-4 p-4">
                        {filters.map(filter => {
                            const selectItems: MultiSelectItem[] = Object.entries(filter.value).map(
                                ([key, value]) =>
                                    ({
                                        label: value,
                                        value: key,
                                    }) as MultiSelectItem
                            );
                            return (
                                <FormField
                                    key={filter?.expose.identifier}
                                    control={formContext.control}
                                    name={filter?.expose.identifier}
                                    render={({field}) => {
                                        return (
                                            <FormItem className="w-full max-w-[300px]">
                                                {/*TODO: Render MultiSelect and SingleSelect based on filter.expose.multiple*/}
                                                {/*TODO: Render text field by default*/}
                                                {(filter?.type === 'select' || ('plugin_id' in filter && filter?.plugin_id === 'list_field')) && (
                                                    <MultiSelect
                                                        onValueChange={field.onChange}
                                                        placeholder={filter.expose.label}
                                                        key={filter.expose.identifier}
                                                        selectItems={selectItems}
                                                        defaultValue={field.value}
                                                        className="shadow-xl"
                                                    />
                                                )}
                                            </FormItem>
                                        );
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className="flex min-h-[44px] px-4 pb-4 pt-4 md:pt-0">
                        <Button aria-label={t('close')} className="px-0 hover:bg-transparent" variant={'ghost'} onClick={handleClose}>
                            <Cross2Icon className="h-[24px] w-[24px] cursor-pointer sm:h-[40px] sm:w-[40px]" />
                        </Button>
                    </div>
                </div>
                <div
                    className={cn('flex flex-row gap-4 transition-opacity duration-300 lg:flex-col', {
                        'opacity-1 delay-300': isFiltersShown,
                        'opacity-0': !isFiltersShown,
                    })}
                >
                    <CTAButton
                        backgroundColor="bg-primary"
                        role="submit"
                        onClick={() => {
                            applyFilters();
                            handleClose();
                        }}
                    >
                        {t('showProducts')}
                    </CTAButton>
                    <button
                        className="font-normal"
                        onClick={() => {
                            clearFilters();
                            handleClose();
                        }}
                    >
                        {t('clearFilters')}
                    </button>
                </div>
            </div>
        </div>
    );
};

const ProductListingFooter = () => {
    const COLORS = ['black', 'red', 'yellow'];
    const ARGUMENTS = [
        {name: 'noAnimalTesting', image: noAnimalTestingImage},
        {name: 'noArtificialAdditives', image: noArtificialAdditivesImage},
        {name: 'noWheatSoya', image: noWheatSoyaImage},
        {name: 'gmoFree', image: gmoFreeImage},
    ];
    const {t} = useTranslation();

    return (
        <div className="mb-0 flex flex-col items-center justify-center pt-40 md:mb-16">
            <div className="flex flex-col items-center justify-center">
                <div className="mx-auto w-min">
                    <div className="mb-4 flex items-stretch gap-1">
                        {COLORS.map(color => (
                            <div
                                key={color}
                                className={cn(
                                    {
                                        'bg-black': color === 'black',
                                        'bg-red-500': color === 'red',
                                        'bg-primary': color === 'yellow',
                                    },
                                    'h-[5.4px] flex-grow'
                                )}
                            />
                        ))}
                    </div>
                    <span className="whitespace-nowrap text-[clamp(16px,10vw,36px)] font-medium text-neutral-700">{t('germanQuality').toUpperCase()}</span>
                </div>
                <div className="max-w-8xl my-24 grid w-full grid-cols-2 gap-x-2 gap-y-8 md:gap-x-32 md:gap-y-0 lg:grid-cols-4">
                    {ARGUMENTS.map(argument => (
                        <div key={argument.name} className="flex flex-col items-center justify-between p-4">
                            <Image
                                src={argument.image}
                                className="h-full max-h-[48px] w-full max-w-[48px] object-contain md:max-h-[96px] md:max-w-[96px]"
                                alt={t(argument.name)}
                            />
                            <span className="break-word mt-4 w-9/12 text-center md:mt-6 md:w-full">{t(argument.name)}</span>
                        </div>
                    ))}
                </div>
            </div>
            <Image src={productListingFooterAsset} className="w-full max-w-[75vw]" alt="Josera food" />
        </div>
    );
};

export default ViewProductListing;
