'use client';

import Caret from '@/components/atoms/svg/caret';
import {cn} from '@/lib/utils';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React, {forwardRef, useEffect, useRef} from 'react';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = forwardRef<React.ElementRef<typeof AccordionPrimitive.Item>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>>(
    ({className, ...props}, ref) => <AccordionPrimitive.Item ref={ref} className={cn(' border-b border-black/30 first:border-y', className)} {...props} />
);
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>>(
    ({className, children, ...props}, ref) => (
        <AccordionPrimitive.Header className="flex">
            <AccordionPrimitive.Trigger
                ref={ref}
                className={cn(
                    'flex flex-1 items-center justify-between py-4 pl-2 pr-7 text-xl font-semibold transition-all data-[state=open]:bg-middleground/40 [&[data-state=open]>svg]:rotate-180',
                    className
                )}
                {...props}
            >
                {children}
                <div className="w-8">
                    <Caret />
                </div>
            </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
    )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<React.ElementRef<typeof AccordionPrimitive.Content>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>>(
    ({className, children, forceMount, ...props}, _ref) => {
        const elementRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (!forceMount) return;
            const handleResize = () => {
                const element = elementRef.current;
                if (element) {
                    requestAnimationFrame(() => {
                        const height = element?.firstElementChild?.clientHeight;
                        element.style.setProperty('--radix-accordion-content-height', `${height}px`);
                        element.classList.add('data-[state=closed]:h-0');
                    });
                }
            };

            handleResize();

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, [elementRef, forceMount]);

        return (
            <AccordionPrimitive.Content
                ref={elementRef}
                forceMount={forceMount}
                className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down data-[state=open]:bg-middleground/40"
                {...props}
            >
                <div className={cn('px-2 pb-4 pt-0', className)}>{children}</div>
            </AccordionPrimitive.Content>
        );
    }
);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export {Accordion, AccordionContent, AccordionItem, AccordionTrigger};
