import type {DrupalParagraph} from 'next-drupal';

import Body from '@/components/atoms/body';
import {COLOR_VARIANTS, TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {absoluteUrl, cn} from 'lib/utils';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import ParagraphHeading from './paragraph--heading';
const Lottie = dynamic(() => import('react-lottie-player'), {ssr: false});

interface ParagraphInfographicBannerProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphInfographicBanner = ({paragraph, className}: ParagraphInfographicBannerProps) => {
    const backgroundColorPrimary = paragraph.field_primary_background_color
        ? COLOR_VARIANTS[paragraph.field_primary_background_color]
        : COLOR_VARIANTS['bg-white'];
    const textColor = paragraph?.field_text_color ? TEXT_COLOR_VARIANTS[paragraph?.field_text_color] : TEXT_COLOR_VARIANTS['text-black'];

    return (
        <div className={className}>
            <div className={cn('mx-auto flex flex-col gap-8 rounded-xl px-10 pb-14 pt-8 md:gap-4 lg:w-full lg:pb-10', backgroundColorPrimary)}>
                {paragraph.field_heading_paragraph && <ParagraphHeading paragraph={paragraph.field_heading_paragraph} />}
                {paragraph.field_body?.processed && <Body className={textColor} value={paragraph.field_body?.processed} />}
                {paragraph.field_infographic_items?.map((item: DrupalParagraph, index: number) => {
                    return (
                        <div className="my-8 flex flex-col items-center justify-center gap-4 md:my-4 md:flex-row md:gap-12" key={`${paragraph.id}${index}`}>
                            {item?.field_title && (
                                <div
                                    className={cn('order-1 w-full text-center md:w-[50%] md:text-start', {
                                        'md:order-0': index % 2 === 0,
                                        'md:order-1': index % 2 !== 0,
                                    })}
                                >
                                    <Body className={cn('text-lg', textColor)} value={item.field_title?.processed} />
                                </div>
                            )}
                            {item?.field_media_item && (
                                <div
                                    className={cn('order-0 w-full md:w-[50%]', {
                                        'md:order-0': index % 2 !== 0,
                                        'md:order-1': index % 2 === 0,
                                    })}
                                    key={item.id}
                                >
                                    {item?.field_media_item?.type === 'media--lottie_animation' &&
                                        item?.field_media_item?.field_media_lottie_file?.uri?.url && (
                                            <Lottie play loop path={absoluteUrl(item.field_media_item.field_media_lottie_file.uri.url)} />
                                        )}
                                    {item?.field_media_item?.type === 'media--image' && item?.field_media_item?.field_media_image?.uri?.url && (
                                        <Image
                                            key={item.field_media_item?.id}
                                            src={absoluteUrl(item.field_media_item?.field_media_image.uri.url)}
                                            alt={item.field_media_item?.field_media_image.resourceIdObjMeta.alt ?? item.field_media_item.name}
                                            width={item.field_media_item?.field_media_image.resourceIdObjMeta.width}
                                            height={item.field_media_item?.field_media_image.resourceIdObjMeta.height}
                                            className="object-contain"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ParagraphInfographicBanner;
