import type {DrupalParagraph} from 'next-drupal';

import {useNavbarContext} from '@/context/NavbarContext';
import useScreenSize from 'hooks/useScreenSize';
import {absoluteUrl, cn} from 'lib/utils';
import {PauseCircle, PlayCircle} from 'lucide-react';
import Image from 'next/image';
import {KeyboardEvent, MouseEvent, MutableRefObject, useEffect, useRef, useState} from 'react';
import {useSwiperSlide} from 'swiper/react';
import Loader from '../atoms/loader';

interface ParagraphVideoProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphVideo = ({paragraph, className, ...props}: ParagraphVideoProps) => {
    const IMAGE_FIELDS = {
        mobile: 'field_image_mobile',
        desktop: 'field_image',
    };
    const VIDEO_FIELDS = {
        mobile: 'field_video_mobile',
        desktop: 'field_video',
    };
    const swiper = useSwiperSlide();
    const screen = useScreenSize();
    const paragraphImage = paragraph[IMAGE_FIELDS[screen.name]];
    const paragraphVideo = paragraph[VIDEO_FIELDS[screen.name]];
    const videoRef = useRef<HTMLVideoElement>(null);
    const {isOverlayed} = useNavbarContext();

    useEffect(() => {
        if (videoRef.current && swiper) {
            if (swiper?.isActive) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [swiper?.isActive, swiper]);

    return (
        <article className={cn('group h-full', className)} {...props}>
            {screen.name && paragraphVideo && (
                <div
                    className={cn('relative flex justify-center', {
                        'max-h-[calc(85vh-var(--navbar-height-desktop))]': !isOverlayed && Boolean(swiper),
                        'max-h-[85vh]': isOverlayed && Boolean(swiper),
                    })}
                >
                    {!swiper && <Controls videoRef={videoRef} overlayImage={screen.name && paragraphImage} />}
                    <video
                        className="w-full object-cover"
                        loop={Boolean(swiper)}
                        muted={Boolean(swiper)}
                        ref={videoRef}
                        src={absoluteUrl(paragraphVideo.field_media_video_file.uri.url)}
                    ></video>
                </div>
            )}
        </article>
    );
};

function Controls({videoRef, overlayImage}: {videoRef: MutableRefObject<HTMLVideoElement>; overlayImage: any | undefined}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [overlayHidden, setOverlayHidden] = useState(false);
    const [mousePosition, setMousePosition] = useState(undefined);
    const [hidePause, setHidePause] = useState(false);

    const togglePlay = (e: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => {
        if (('key' in e && e.key === 'Enter') || ('type' in e && e.type === 'click')) {
            setIsPlaying(prev => !prev);
        }
    };

    useEffect(() => {
        videoRef.current.onloadstart = () => {
            setIsLoading(true);
        };
        videoRef.current.oncanplay = () => {
            setIsLoading(false);
        };
        videoRef.current.onended = () => {
            setIsPlaying(false);
            setOverlayHidden(false);
        };
        videoRef.current.onplay = () => {
            setOverlayHidden(true);
        };
        const ref = videoRef.current;
        return () => {
            ref.onended = null;
            ref.onplay = null;
            ref.oncanplay = null;
            ref.onloadstart = null;
        };
    }, [videoRef]);

    useEffect(() => {
        if (isPlaying) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    }, [isPlaying, videoRef]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        setHidePause(false);
        if (!hidePause) {
            timeoutId = setTimeout(() => {
                setHidePause(true);
            }, 1000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mousePosition]);

    return (
        <div
            onMouseEnter={() => setHidePause(false)}
            onMouseLeave={() => setHidePause(true)}
            onMouseMove={event => setMousePosition({x: event.clientX, y: event.clientY})}
            className={cn('absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center transition-colors', {
                'bg-black bg-opacity-50': !isPlaying,
            })}
        >
            <div className="relative z-10 h-full w-full">
                {overlayImage && (
                    <div className={cn('absolute h-full w-full')}>
                        <Image
                            draggable={false}
                            src={absoluteUrl(overlayImage.field_media_image.uri.url)}
                            alt={overlayImage.field_media_image.resourceIdObjMeta.alt ?? overlayImage.name}
                            width={overlayImage.field_media_image.resourceIdObjMeta.width}
                            height={overlayImage.field_media_image.resourceIdObjMeta.height}
                            className={cn('pointer-events-none h-full w-full touch-none select-none object-cover opacity-100 transition-opacity duration-300', {
                                'opacity-0': overlayHidden,
                            })}
                        />
                    </div>
                )}
                <button className="absolute-center absolute z-10 h-40 w-40 " onClick={togglePlay}>
                    <PlayCircle
                        className={cn('z-20 h-full w-full text-primary', {
                            hidden: isPlaying,
                        })}
                    />
                    <PauseCircle
                        className={cn('z-20 h-full w-full text-primary opacity-0', 'transition-opacity group-hover:opacity-50', {
                            hidden: !isPlaying,
                            '!opacity-0': hidePause,
                        })}
                    />
                </button>
                <Loader
                    className={cn('absolute-center absolute  h-48 w-48', {
                        hidden: !isLoading,
                    })}
                />
            </div>
        </div>
    );
}
export default ParagraphVideo;
