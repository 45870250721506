import type {DrupalParagraph} from 'next-drupal';

import {absoluteUrl, cn} from '@/lib/utils';
import Image from 'next/image';
import Body from '../atoms/body';
import ParagraphCtaButton from './paragraph--cta-button';
import ParagraphHeading from './paragraph--heading';

interface ParagraphKeyVisualProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphKeyVisual = ({paragraph, className}: ParagraphKeyVisualProps) => (
    <div className={cn('flex flex-col items-center justify-center gap-24  text-center', className)}>
        {paragraph.field_heading_paragraph && <ParagraphHeading className={'text-[40px] text-white'} paragraph={paragraph.field_heading_paragraph} />}
        <div className="flex flex-col items-center justify-center gap-24 lg:flex-row">
            <div className="w-full max-w-[40%]">
                {paragraph.field_image && (
                    <Image
                        key={paragraph.id}
                        src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                        alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                        width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                        height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                    />
                )}
            </div>
            <div className="flex flex-col items-center justify-center gap-14 text-center lg:items-start lg:text-left">
                {paragraph.field_body?.processed && (
                    <Body className="max-w-[80%] prose-p:text-white prose-p:[text-wrap:balance]" value={paragraph.field_body?.processed} />
                )}
                {paragraph.field_cta_button_paragraph && <ParagraphCtaButton paragraph={paragraph.field_cta_button_paragraph} />}
            </div>
        </div>
    </div>
);

export default ParagraphKeyVisual;
