import type {LayoutElements} from '@/lib/interfaces';
import type {PropsWithChildren} from 'react';

import {useNavbarContext} from '@/context/NavbarContext';
import useScreenSize from '@/hooks/useScreenSize';
import {SCREENS} from '@/lib/constants';
import {cn} from '@/lib/utils';
import {Footer} from 'components/footer';
import {Navbar} from 'components/navbar';
import {PreviewAlert} from 'components/preview-alert';
import NextNProgress from 'nextjs-progressbar';

type LayoutProps = LayoutElements &
    PropsWithChildren & {
        navbarConfig?: {
            field_navbar_overlay_mode: boolean;
            field_primary_background_color: string;
            field_text_color: string;
        };
    };

export const Layout = ({children, menus, blocks}: LayoutProps) => {
    const {isScrolled, isOverlayed} = useNavbarContext();
    const screen = useScreenSize();

    return (
        <>
            <NextNProgress color="#e0001a" />
            <PreviewAlert />
            <Navbar menu={menus.main} social={menus.social} search={blocks.search} />
            <div
                className={cn('mx-auto w-full', {
                    'pt-fill-content': isScrolled && !isOverlayed && screen.name == SCREENS.desktop.name,
                })}
            >
                <main className="mx-auto">{children}</main>
            </div>
            <Footer
                menu={menus.footer}
                social={menus.social}
                contact={menus.contact}
                contactInfo={blocks.contactInfo}
                footerAnimation={blocks.footerAnimation}
            />
        </>
    );
};
