import {useEffect} from 'react';

interface UseEventProps {
    ref: React.RefObject<HTMLElement>;
    event: string;
    callback: (event: Event) => void;
}

const useEvent = ({ref, event, callback}: UseEventProps) => {
    useEffect(() => {
        if (!event || !callback || !ref) throw new Error('useEvent requires a ref, event, and callback');
        if (ref.current) {
            ref.current.addEventListener(event, callback);
        }
        return () => {
            if (ref.current) {
                ref.current.removeEventListener(event, callback);
            }
        };
    }, [callback, ref]);
};

export default useEvent;
