import {cn} from '@/lib/utils';
import type {DrupalParagraph} from 'next-drupal';
import VideoPlayer from '../molecules/video-player/video-player';

interface ParagraphVideoProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphRemoteVideo = ({paragraph, className}: ParagraphVideoProps) => (
    <VideoPlayer
        containerClassName={cn('group mx-auto flex items-center justify-center lg:w-full', className)}
        key={paragraph.id}
        url={paragraph.field_video?.field_media_oembed_video}
    />
);

export default ParagraphRemoteVideo;
