import {cn} from '@/lib/utils';
import {motion, type Variants} from 'framer-motion';

interface ExtendedArrowProps extends React.HTMLAttributes<HTMLDivElement> {
    color?: 'white' | 'black' | 'primary';
    hoverColor?: 'white' | 'black' | 'primary';
    type?: 'back' | 'continue' | 'menu';
    animationVariant?: 'extend' | 'default';
}

enum HOVER_ARROW_COLOR_VARIANTS {
    white = 'group-hover/svg:stroke-white',
    black = 'group-hover/svg:stroke-black',
    primary = 'group-hover/svg:stroke-primary',
}

enum ARROW_COLOR_VARIANTS {
    white = 'stroke-white',
    black = 'stroke-black',
    primary = 'stroke-primary',
}

const ExtendedArrow = ({color = 'black', hoverColor = 'white', type = 'menu', children, animationVariant = 'default', ...restProps}: ExtendedArrowProps) => {
    const Components = {
        menu: NextMenu,
        back: ArrowBack,
        continue: ArrowContinue,
    };
    const Component = Components[type];

    return (
        <Component
            {...restProps}
            animationVariant={animationVariant}
            colorPicked={ARROW_COLOR_VARIANTS[color]}
            hoverColorPicked={HOVER_ARROW_COLOR_VARIANTS[hoverColor]}
        >
            {children}
        </Component>
    );
};

function NextMenu({
    children,
    className,
    colorPicked,
    hoverColorPicked,
}: Omit<ExtendedArrowProps, 'type'> & {hoverColorPicked: HOVER_ARROW_COLOR_VARIANTS; colorPicked: ARROW_COLOR_VARIANTS}) {
    const variantsLine: Variants = {
        hover: {
            d: 'M-12 12h41',
            transition: {
                duration: 0.1,
                ease: 'easeOut',
            },
        },
    };
    const variantsArrow: Variants = {
        hover: {
            d: 'm22 5 7 7-7 7',
            transition: {
                duration: 0.1,
                ease: 'easeOut',
            },
        },
    };

    return (
        <motion.div className="group/svg flex items-center gap-4 duration-300" whileHover={'hover'} exit={'exit'} initial={'initial'}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={cn(
                    'transition-[width] [transition-timing-function:cubic-bezier(0,0,0.2,0.45)] group-hover/svg:w-[60px]',
                    colorPicked,
                    hoverColorPicked,
                    className
                )}
            >
                <motion.path variants={variantsLine} d="M0 12h17" />
                <motion.path variants={variantsArrow} d="m12 5 7 7-7 7" />
            </svg>
            {children}
        </motion.div>
    );
}

function ArrowBack({
    children,
    className,
    colorPicked,
    hoverColorPicked,
    animationVariant,
}: Omit<ExtendedArrowProps, 'type'> & {hoverColorPicked: HOVER_ARROW_COLOR_VARIANTS; colorPicked: ARROW_COLOR_VARIANTS}) {
    const variantsLine: Variants = {
        hover: {
            d: 'M0 12h32',
            transition: {
                duration: 0.3,
            },
        },
    };
    const variantsArrow: Variants = {
        hover: {
            d: 'm28 7 5 5-5 5',
            transition: {
                duration: 0.3,
            },
        },
    };

    return (
        <motion.div
            className={cn('flex items-center gap-4 duration-300', {
                'gap-0 pl-2': animationVariant === 'extend',
            })}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={cn(
                    ' w-[45px] rotate-180 transition-[width]',
                    colorPicked,
                    hoverColorPicked,
                    {
                        'w-[35px] duration-300 ease-out group-hover:w-[45px]': animationVariant === 'extend',
                    },
                    className
                )}
            >
                <motion.path variants={variantsLine} d="M5 12h22" />
                <motion.path variants={variantsArrow} d="m22 7 5 5-5 5" />
            </svg>
            {children}
        </motion.div>
    );
}

function ArrowContinue({
    children,
    className,
    colorPicked,
    hoverColorPicked,
}: Omit<ExtendedArrowProps, 'type'> & {hoverColorPicked: HOVER_ARROW_COLOR_VARIANTS; colorPicked: ARROW_COLOR_VARIANTS}) {
    const variantsLine: Variants = {
        hover: {
            d: 'M0 12h28',
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            },
        },
    };
    const variantsArrow: Variants = {
        hover: {
            d: 'm24 7 5 5-5 5',
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <motion.div className="group/svg flex flex-col items-center gap-4 duration-300 md:flex-row">
            {children}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={cn('-translate-x-2 translate-y-[2px] rotate-90 scale-95 transition-[width]', colorPicked, hoverColorPicked, className)}
            >
                <motion.path variants={variantsLine} d="M5 12h12" />
                <motion.path variants={variantsArrow} d="m14 7 5 5-5 5" />
            </svg>
        </motion.div>
    );
}

export default ExtendedArrow;
