import useScrollIndicator from '@/hooks/useScrollIndicator';
import {COLOR_NAVBAR_VARIANTS, TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {Dispatch, SetStateAction, createContext, useContext, useState} from 'react';

interface INavbarContext {
    isOverlayed: boolean;
    isScrolled: boolean;
    isMenuActive: boolean;
    textColor: string;
    setIsMenuActive?: Dispatch<SetStateAction<boolean>>;
    backgroundColor: string | undefined;
    isMobileMenuActive?: boolean;
    setIsMobileMenuActive?: Dispatch<SetStateAction<boolean>>;
}

const NavbarContext = createContext<INavbarContext>({
    isOverlayed: false,
    isScrolled: false,
    isMenuActive: false,
    textColor: 'text-black',
    backgroundColor: undefined,
});

export const useNavbarContext = () => {
    const context = useContext(NavbarContext);
    return context;
};

interface NavbarProviderProps extends React.PropsWithChildren {
    navbarConfig?: any;
}

export const NavbarProvider = ({children, navbarConfig}: NavbarProviderProps) => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const isScrolled = useScrollIndicator(300);
    const isOverlayed = navbarConfig?.field_navbar_overlay_mode ?? false;

    const textColor = navbarConfig?.field_text_color ? TEXT_COLOR_VARIANTS[navbarConfig?.field_text_color] : TEXT_COLOR_VARIANTS['text-black'];
    const backgroundColor = navbarConfig?.field_primary_background_color
        ? COLOR_NAVBAR_VARIANTS[navbarConfig?.field_primary_background_color]
        : COLOR_NAVBAR_VARIANTS['bg-white'];

    return (
        <NavbarContext.Provider
            value={{isOverlayed, isScrolled, isMenuActive, textColor, backgroundColor, setIsMenuActive, isMobileMenuActive, setIsMobileMenuActive}}
        >
            {children}
        </NavbarContext.Provider>
    );
};
