import type {MultiSelectItem} from '@/lib/types';
import type {DrupalNode, DrupalParagraph} from 'next-drupal';

import {absoluteUrl, cn} from '@/lib/utils';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import {useCallback, useMemo, useState} from 'react';
import CTAButton from '../atoms/buttons/cta-button';
import MultiSelect from '../molecules/multi-select/multi-select';
import ParagraphHeading from './paragraph--heading';

interface ParagraphLinkSelectorProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphLinkSelector = ({paragraph, className}: ParagraphLinkSelectorProps) => {
    const {t} = useTranslation();
    const [selectedQueryParams, setSelectedQueryParams] = useState<string[]>([]);
    const linkSelectorItems = paragraph.field_link_selector_items;

    const generateQueryString = (selectedQueryParams: string[], linkSelectorItems): string => {
        const queryParams: Record<string, string> = selectedQueryParams.reduce((acc, attribute) => {
            const item = linkSelectorItems.find(item => item.id === attribute);
            const key = item.field_query_key;
            const value = item.field_query;
            if (acc[key]) {
                acc[key] += `,${value}`;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
        return Object.entries(queryParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    };

    const handleLinkChange = useCallback((selectValues: MultiSelectItem[]) => {
        setSelectedQueryParams(selectValues.map(({value}) => value));
    }, []);

    const selectItems: MultiSelectItem[] = useMemo(
        () =>
            linkSelectorItems?.map((item: DrupalNode) => {
                return {
                    label: item.field_title.value,
                    value: item.id,
                };
            }),
        [linkSelectorItems]
    );

    const queryString = generateQueryString(selectedQueryParams, linkSelectorItems);

    return (
        <div className={cn('mx-auto flex w-10/12 max-w-[428px] flex-col items-center justify-center self-end  md:w-full', className)}>
            {paragraph.field_image && (
                <Image
                    key={paragraph.id}
                    src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                    alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                    width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                    height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                    className="-mb-2 px-4"
                />
            )}
            <div className="relative flex w-full flex-col items-center justify-center rounded-2xl bg-gradient-to-tr from-accent-foreground to-accent px-9 pb-12 pt-9 md:pb-[87px] lg:px-12">
                {paragraph.field_heading_paragraph && (
                    <ParagraphHeading className="pb-11 text-center font-light text-white" paragraph={paragraph.field_heading_paragraph} />
                )}

                {paragraph.field_link_selector_items?.length && (
                    <div className="mb-8 w-full md:mb-14">
                        <MultiSelect
                            onValueChange={handleLinkChange}
                            placeholder={paragraph?.field_link?.title || t('showProducts')}
                            selectItems={selectItems}
                            listClassname="max-h-[180px]"
                            className="w-full"
                        />
                    </div>
                )}
                {paragraph.field_submit_text && (
                    <CTAButton backgroundColor="bg-primary" href={`${paragraph?.field_link?.url}?${queryString}`}>
                        {paragraph.field_submit_text}
                    </CTAButton>
                )}
            </div>
        </div>
    );
};

export default ParagraphLinkSelector;
