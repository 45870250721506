import {LOCALES} from '@/lib/constants';
import {absoluteUrl} from '@/lib/utils';
import Image from 'next/image';

const iconsContext = (require as any).context('./flag-icons', false, /\.svg$/);

const MappedFlags: Record<LOCALES, string> = iconsContext.keys().reduce(
    (acc, path) => {
        const locale = path.replace('./', '').replace('.svg', '') as LOCALES;
        acc[locale] = iconsContext(path).default;
        return acc;
    },
    {} as Record<LOCALES, string>
);

type Props = {
    className: string;
    code: LOCALES;
    fallback: () => JSX.Element | null;
    alt: string;
    externalIcon?: {
        url?: string;
        width?: number;
        height?: number;
    };
};

const Flag = ({alt, className, code, fallback, externalIcon}: Props) => {
    if (externalIcon) {
        return <Image src={absoluteUrl(externalIcon.url)} width={externalIcon.width} height={externalIcon.height} className={className} alt={alt} />;
    }
    const icon = MappedFlags[code];
    const FallbackComponent = fallback;
    if (!icon) return <FallbackComponent />;
    return <Image src={icon} className={className} alt={alt} />;
};

export default Flag;
