import type {DrupalParagraph} from 'next-drupal';

import {COLOR_VARIANTS} from '@/lib/constants';
import {absoluteUrl, cn} from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';
import ExtendedArrow from '../atoms/buttons/arrow';
import ParagraphHeading from './paragraph--heading';

interface ParagraphCtaBannerProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphCtaBanner = ({paragraph, className, ...props}: ParagraphCtaBannerProps) => {
    const backgroundColorPrimary = paragraph.field_primary_background_color
        ? COLOR_VARIANTS[paragraph.field_primary_background_color]
        : COLOR_VARIANTS['bg-white'];

    return (
        <Link href={paragraph.field_link?.url ?? '#'} className={cn('mx-auto my-8 flex max-h-[450px] max-w-[630px] justify-center lg:w-full', className)}>
            <div className={cn('group relative flex flex-grow flex-col justify-between overflow-hidden rounded-xl', backgroundColorPrimary)}>
                <div {...props} className="py-12">
                    {paragraph.field_heading_paragraph && <ParagraphHeading className="text-center" paragraph={paragraph.field_heading_paragraph} />}
                </div>
                {paragraph.field_image && (
                    <Image
                        key={paragraph.id}
                        src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                        alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                        width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                        height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                        className="mx-auto h-[320px] w-full max-w-sm origin-bottom object-cover px-4 transition-transform duration-300 ease-in-out group-hover:scale-110"
                    />
                )}
                <ExtendedArrow
                    color="white"
                    hoverColor="white"
                    className="absolute bottom-8 right-8 -translate-x-12 opacity-0 transition-[transform,opacity] duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
                />
            </div>
        </Link>
    );
};

export default ParagraphCtaBanner;
