import type {DrupalParagraphWithFieldContent} from '@/lib/types';
import type {DrupalNode} from 'next-drupal';
import type {ReactElement} from 'react';

import {NODE_TYPES} from '@/lib/constants';
import {cn, splitArrayIntoChunks} from 'lib/utils';
import {useEffect, useState} from 'react';
import {A11y, Autoplay, EffectFade, Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperNavigation from '../molecules/swiper-navigation/swiper-navigation';
import NodeProductTeaser from '../nodes/node--product--teaser';
import ParagraphHeading from './paragraph--heading';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

interface ParagraphContentSliderProps {
    paragraph: DrupalParagraphWithFieldContent;
    className?: string;
}

const ParagraphContentSlider = ({paragraph, className}: ParagraphContentSliderProps) => {
    const SWIPER_BREAKPOINTS = {
        xlarge: 1344,
        large: 1280,
        medium: 1184,
        small: 768,
        xsmall: 720,
    };

    const [currentElement, setCurrentElement] = useState(1);

    useEffect(() => {
        const setElement = (): void => {
            if (
                window.innerWidth >= SWIPER_BREAKPOINTS.xlarge ||
                (window.innerWidth < SWIPER_BREAKPOINTS.large && window.innerWidth >= SWIPER_BREAKPOINTS.medium) ||
                (window.innerWidth < SWIPER_BREAKPOINTS.small && window.innerWidth > SWIPER_BREAKPOINTS.xsmall)
            ) {
                setCurrentElement(1);
            } else {
                setCurrentElement(2);
            }
        };

        setElement();

        window.addEventListener('resize', setElement);

        return () => {
            window.removeEventListener('resize', setElement);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const slides = paragraph.field_content?.filter(node => node.status).slice(0, 6) ?? [];

    const swiperElements = {
        1: (
            <Swiper
                modules={[Autoplay, EffectFade, Pagination, A11y, Navigation]}
                autoplay={{
                    delay: 5000,
                }}
                pagination={{clickable: true}}
                slidesPerView={1}
                centeredSlides
                spaceBetween={100}
                className={cn('content-slider', '!relative !w-full !px-16 !pb-[20px] @2xl:!block', {
                    '!pb-[40px]': slides.length > 3,
                })}
            >
                {!!slides.length && (
                    <div className="mt-6">
                        {splitArrayIntoChunks(slides, 3).map((slides: DrupalNode[], index) => (
                            <SwiperSlide key={index} className="!grid !h-full !grid-cols-3 !gap-4">
                                {slides.map(
                                    (slide: DrupalNode, index) =>
                                        slide.type === NODE_TYPES.product && (
                                            <NodeProductTeaser.Desktop key={`${slide.id}${index}`} node={slide} more={paragraph.field_more} />
                                        )
                                )}
                            </SwiperSlide>
                        ))}
                    </div>
                )}
                <SwiperNavigation />
            </Swiper>
        ),
        2: (
            <Swiper
                modules={[Autoplay, EffectFade, Pagination, A11y]}
                autoplay={{
                    delay: 5000,
                }}
                pagination={{clickable: true}}
                slidesPerView={1.3}
                centeredSlides
                spaceBetween={24}
                className={cn('content-slider', '!overflow-visible !pb-[40px] md:!overflow-hidden')}
            >
                {!!slides.length && (
                    <div className="mt-6">
                        {slides.map((slide: DrupalNode, index: number) => (
                            <SwiperSlide className="!h-full" key={`${slide.id}${index}`}>
                                {slide.type === NODE_TYPES.product && <NodeProductTeaser.Standard node={slide} more={paragraph.field_more} />}
                            </SwiperSlide>
                        ))}
                    </div>
                )}
            </Swiper>
        ),
    };

    const renderSwiper = (): ReactElement => swiperElements[currentElement];

    return (
        <div className="flex h-full flex-col justify-end">
            <div className={cn('relative w-full self-end @container', className)}>
                {paragraph.field_heading_paragraph && (
                    <>
                        <div className="pb-12 pt-24 text-center">
                            <ParagraphHeading paragraph={paragraph.field_heading_paragraph} />
                        </div>
                        {renderSwiper()}
                    </>
                )}
            </div>
        </div>
    );
};

export default ParagraphContentSlider;
