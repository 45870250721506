import type {DrupalParagraph} from 'next-drupal';

import Body from '@/components/atoms/body';
import {COLOR_VARIANTS} from '@/lib/constants';
import {absoluteUrl, cn} from '@/lib/utils';
import Image from 'next/image';
import CTAButton from '../atoms/buttons/cta-button';
interface DocumentBannerProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const DocumentBanner = ({paragraph, className}: DocumentBannerProps) => {
    const backgroundColorPrimary = paragraph.field_primary_background_color
        ? COLOR_VARIANTS[paragraph.field_primary_background_color]
        : COLOR_VARIANTS['bg-white'];
    const backgroundColorSecondary = paragraph.field_secondary_background_color
        ? COLOR_VARIANTS[paragraph.field_secondary_background_color]
        : COLOR_VARIANTS['bg-white'];

    return (
        <div className={cn(className)}>
            <div className={cn('mx-auto  flex flex-col overflow-hidden rounded-xl lg:w-full', backgroundColorPrimary)}>
                <div className="flex min-h-[337px] flex-col md:flex-row">
                    {paragraph.field_body?.processed && (
                        <div className="justifty-center flex w-full items-center md:w-[50%]">
                            <Body className="my-[40px] ml-[20px] mr-[20px] md:my-0 md:ml-[40px] md:mr-[20px]" value={paragraph.field_body?.processed} />
                        </div>
                    )}
                    {paragraph.field_image && (
                        <div className="relative flex w-full items-end justify-end md:w-[50%]">
                            <Image
                                key={paragraph.id}
                                src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                                alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                                width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                                height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                                className="w-full object-cover"
                            />
                        </div>
                    )}
                </div>

                <div className={cn('flex min-h-[112px] rounded-b-xl md:items-center md:justify-center', backgroundColorSecondary)}>
                    {paragraph.field_document?.field_media_document?.uri?.url && (
                        <CTAButton
                            className="h-full min-h-[112px] w-full whitespace-pre-wrap text-left md:text-center"
                            textColor="text-white"
                            href={absoluteUrl(paragraph.field_document?.field_media_document?.uri?.url)}
                            target="_blank"
                        >
                            {paragraph.field_title?.processed && <Body className="text-xl" value={paragraph.field_title?.processed} />}
                        </CTAButton>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentBanner;
