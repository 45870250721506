import type {DrupalParagraph} from 'next-drupal';

import {useNavbarContext} from '@/context/NavbarContext';
import {PARAGRAPH_TYPES} from '@/lib/constants';
import {cn} from '@/lib/utils';
import {A11y, Autoplay, EffectFade, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import ParagraphImage from './paragraph--image';
import ParagraphVideo from './paragraph--video';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

interface ParagraphSliderProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphSlider = ({paragraph, className}: ParagraphSliderProps) => {
    const {isOverlayed} = useNavbarContext();

    return (
        <div className={cn('w-full bg-gray-300', className)}>
            <Swiper
                modules={[Autoplay, EffectFade, Pagination, A11y]}
                autoplay={{
                    delay: 5000,
                }}
                pagination={{clickable: true}}
                effect="fade"
                fadeEffect={{
                    crossFade: true,
                }}
                className={cn('slider', {
                    '!h-hero-image-mobile-cut lg:!h-hero-image-desktop-cut': !isOverlayed,
                    '!h-safe-screen-cut': isOverlayed,
                })}
            >
                {paragraph.field_slides && (
                    <div className="mt-6">
                        {paragraph.field_slides.map((slide: DrupalParagraph) => (
                            <SwiperSlide key={slide.id}>
                                {slide.type === PARAGRAPH_TYPES.image && <ParagraphImage buttonClassName="mb-8 md:mb-0" slide full paragraph={slide} />}
                                {slide.type === PARAGRAPH_TYPES.video && <ParagraphVideo paragraph={slide} />}
                            </SwiperSlide>
                        ))}
                    </div>
                )}
            </Swiper>
        </div>
    );
};

export default ParagraphSlider;
