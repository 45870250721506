import type {DrupalLink, ExtendedDrupalMenuLinkContent} from '@/lib/interfaces';
import type {DrupalBlock, DrupalMenuLinkContent} from 'next-drupal';
import type {ReactNode} from 'react';

import LinkButton from '@/components/atoms/buttons/link-button';
import {useNavbarContext} from '@/context/NavbarContext';
import {absoluteUrl, cn} from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import {DropdownChild, DropdownClose, DropdownContent, DropdownItemRoot, DropdownRoot, DropdownTrigger} from '../dialog/dropdown-dialog';
import {LanguageSwitcher} from '../dialog/language-switcher';
import SearchBar from '../dialog/search-bar';

interface StandardMenuProps {
    menu: DrupalMenuLinkContent[];
    className?: string;
}

interface DesktopMenuProps extends StandardMenuProps {
    search: DrupalBlock;
    children: ReactNode;
}

export const Footer = ({menu, className}: StandardMenuProps) => {
    const renderLink = (link: DrupalLink) => {
        const url = link.field_link_override?.uri || link.url;
        return (
            <li
                key={link.id}
                className={cn('border-divider', {
                    'border-b-2 first:border-t-2': Boolean(link.field_main),
                    'py-1': Boolean(url),
                })}
            >
                {!url && <span className={cn('mb-7 block text-[15px] font-bold uppercase')}>{link.title}</span>}
                {url && (
                    <Link
                        href={url}
                        locale={link.langcode}
                        className={cn('text-[15px]', {
                            'font-bold': Boolean(link.field_main),
                        })}
                    >
                        {link.title}
                    </Link>
                )}
                {link.items && <ul>{link.items.map(childLink => renderLink(childLink))}</ul>}
            </li>
        );
    };

    return (
        <ul
            className={cn(
                {
                    'flex flex-1 flex-grow flex-wrap items-start justify-start gap-8 md:gap-12 lg:gap-[71px]': !className,
                },
                className
            )}
        >
            {menu.map(link => renderLink(link))}
        </ul>
    );
};

export const Standard = ({menu, className}: StandardMenuProps) => {
    const renderLink = (link: DrupalLink) => {
        const url = link.field_link_override?.uri || link.url;
        return (
            <li
                key={link.id}
                className={cn('w-full border-divider', {
                    'border-b-2 pl-4 first:border-t-2': Boolean(link.field_main),
                    'pl-4': Boolean(!link.field_main && !link.parent),
                    'py-1': Boolean(url),
                })}
            >
                {!url && <span className={cn('mb-7 block text-[15px] font-bold uppercase')}>{link.title}</span>}
                {url && (
                    <Link
                        href={url}
                        locale={link.langcode}
                        className={cn('text-[15px]', {
                            'font-bold': Boolean(link.field_main),
                        })}
                    >
                        {link.title}
                    </Link>
                )}
                {link.items && <ul>{link.items.map(childLink => renderLink(childLink))}</ul>}
            </li>
        );
    };

    return (
        <ul
            className={cn(
                {
                    'flex flex-1 items-start justify-between space-x-4': !className,
                },
                className
            )}
        >
            {menu.map(link => renderLink(link))}
        </ul>
    );
};

export const Mobile = ({menu}: StandardMenuProps) => {
    const renderLink = (link: DrupalLink, index: number) => {
        const url = link.field_link_override?.uri || link.url;
        return (
            <React.Fragment key={index}>
                {link.field_main && index === 0 && <Divider key={`${index}_d1`} />}
                <li
                    key={link.id}
                    className={cn('mb-2', {
                        'ml-4 font-bold': Boolean(link.field_main),
                    })}
                >
                    {!url && <span className={cn('mb-2 block')}>{link.title}</span>}
                    {url && (
                        <Link
                            href={url}
                            locale={link.langcode}
                            className={cn({
                                'font-normal': !Boolean(link.field_main),
                            })}
                        >
                            {link.title}
                        </Link>
                    )}
                    {link.items && <ul key={link.id + 'ul'}>{link.items.map(renderLink)}</ul>}
                </li>
                {link.field_main && <Divider key={`${index}_d2`} />}
            </React.Fragment>
        );
    };

    return <ul className={cn('text-xl')}>{menu.map(renderLink)}</ul>;
};

export const Desktop = ({menu, search, children}: DesktopMenuProps) => {
    const {setIsMenuActive, isScrolled, isOverlayed} = useNavbarContext();

    const renderModalContent = (link: DrupalLink) => {
        const url = link.field_link_override?.uri || link.url;

        return (
            <li key={link.id}>
                {!url && <span>{link.title}</span>}
                {url && <LinkButton href={url}>{link.title}</LinkButton>}
                {link.items && <ul>{link.items.map(renderLink)}</ul>}
            </li>
        );
    };

    const renderLink = (link: DrupalLink) => {
        const url = link?.field_link_override?.uri || link?.url;
        const image = link.field_image?.field_media_image?.uri?.url ? absoluteUrl(link.field_image?.field_media_image?.uri?.url) : null;
        const {alt, height, width} = link.field_image?.field_media_image?.resourceIdObjMeta || {};
        const renderLinks = () =>
            link.items?.map((link: ExtendedDrupalMenuLinkContent) => {
                const url = link?.field_link_override?.uri || link?.url;
                return (
                    <a key={url} href={url} className="hidden">
                        {link.title}
                    </a>
                );
            });

        return (
            <li key={link.id}>
                {url && (
                    <DropdownChild>
                        <Link className="hover:opacity-40" href={url} locale={link.langcode}>
                            {link.title}
                        </Link>
                    </DropdownChild>
                )}
                {link.items && !url && (
                    <DropdownItemRoot>
                        <DropdownTrigger activeTextColor="black">{link.title}</DropdownTrigger>
                        {renderLinks()}
                        <DropdownContent className="fixed hidden">
                            <div className="relative h-full overflow-hidden">
                                <div className="relative flex h-full items-center px-[90px]">
                                    <span className="pointer-events-none absolute left-1/2 top-[30%] -translate-x-1/2 -translate-y-[30%] select-none text-[calc(50vw/1.5)] font-extrabold text-white opacity-25">
                                        {link.title}
                                    </span>
                                    <ul className="z-10 space-y-8">{link.items.map(renderModalContent)}</ul>
                                </div>
                                {image && (
                                    <Image
                                        src={image}
                                        alt={alt}
                                        width={+width}
                                        height={+height}
                                        className="pointer-events-none absolute -bottom-8 -right-8 z-50 max-h-full w-1/2 select-none object-contain object-right"
                                    />
                                )}
                            </div>
                        </DropdownContent>
                    </DropdownItemRoot>
                )}
            </li>
        );
    };

    const menuLeft = menu.slice(0, Math.ceil(menu.length / 2));
    const menuRight = menu.slice(menuLeft.length, menu.length);

    return (
        <DropdownRoot isOpen={setIsMenuActive}>
            <ul className={cn('wide-container grid w-full grid-cols-[1fr_min-content_1fr] gap-[clamp(30px,3vw,120px)] transition-[box-shadow]')}>
                <div className="flex items-center justify-between text-xl font-semibold xl:pr-20">{menuLeft.map(renderLink)}</div>
                <div
                    className={cn('flex w-[clamp(106px,14vw,233px)] items-center justify-center', {
                        'w-[clamp(106px,10vw,203px)]': isScrolled && isOverlayed,
                    })}
                >
                    {children}
                </div>
                <div className="flex w-full items-center ">
                    <div className="mr-[44px] flex flex-grow justify-between text-base font-medium xl:pl-20">{menuRight.map(renderLink)}</div>
                    <div className="flex items-center gap-4">
                        {search && <SearchBar search={search} />}
                        <DropdownClose as={<LanguageSwitcher />} />
                    </div>
                </div>
            </ul>
        </DropdownRoot>
    );
};

const Divider = () => <hr className="my-4 w-full border-black opacity-25" />;

const variants = {Standard, Mobile, Desktop, Footer};

export default variants;
