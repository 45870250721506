import type {DrupalParagraph} from 'next-drupal';

import {cn} from '@/lib/utils';
import Body from '../atoms/body';

interface ParagraphRichTextProps extends React.HTMLProps<HTMLHeadingElement> {
    paragraph: DrupalParagraph;
}

const ParagraphRichText = ({paragraph, className}: ParagraphRichTextProps) => {
    const proseOverride = 'prose-h1:text-center prose-h2:text-center prose-h3:text-center prose-h4:text-center prose-h5:text-center prose-h6:text-center';
    return (
        <div className={cn('clear-both mx-auto lg:w-full', className)}>
            {paragraph.field_body?.processed && <Body className={proseOverride} value={paragraph.field_body?.processed} />}
        </div>
    );
};

export default ParagraphRichText;
