import {SCREENS} from '@/lib/constants';
import {useEffect, useState} from 'react';

interface Screen {
    name: 'desktop' | 'tablet' | 'mobile';
    size: number;
}

const useScreenSize = () => {
    const [screen, setScreen] = useState<Screen>({
        name: undefined,
        size: undefined,
    });

    useEffect(() => {
        const setScreenSize = (): void => {
            if (window.innerWidth >= SCREENS.desktop.size) {
                setScreen(SCREENS.desktop);
            } else if (window.innerWidth >= SCREENS.tablet.size) {
                setScreen(SCREENS.tablet);
            } else {
                setScreen(SCREENS.mobile);
            }
        };
        setScreenSize();

        window.addEventListener('resize', setScreenSize);

        return () => {
            window.removeEventListener('resize', setScreenSize);
        };
    }, []);

    return screen;
};

export default useScreenSize;
