import type {DrupalMenuLinkContentWithWebform, LinkMedia, UriLink} from '@/lib/interfaces';
import type {WebformObject} from 'nextjs-drupal-webform';

import {absoluteUrl} from 'lib/utils';
import Image from 'next/image';
import CTAButton from './atoms/buttons/cta-button';
import Heading from './atoms/heading';
import ContactDialog from './molecules/dialog/contact-dialog';
import {DropdownChild} from './molecules/dialog/dropdown-dialog';

interface ContactMenuProps {
    menu: DrupalMenuLinkContentWithWebform[];
    showTitle?: boolean;
}

export const ContactMenu = ({menu, showTitle = true}: ContactMenuProps) => {
    const renderLink = (
        link: DrupalMenuLinkContentWithWebform & {
            field_link_override?: UriLink;
            field_icon?: LinkMedia;
            field_webform?: WebformObject;
        }
    ) => {
        const url = link.field_link_override?.uri || link.url;
        const webform = link.field_webform?.webform;
        const iconSrc = absoluteUrl(link.field_icon?.field_media_image?.uri?.url);
        const iconAlt = link.field_icon?.field_media_image?.resourceIdObjMeta?.alt || link.title;

        return (
            <li key={link.id}>
                {link.field_icon?.field_media_image?.uri?.url && url && (
                    <DropdownChild>
                        <CTAButton
                            className="gap-2 text-sm font-semibold transition-[padding,gap] hover:fill-white"
                            icon={<Image className="mr-0.5" src={iconSrc} alt={iconAlt} width={18} height={18} />}
                            backgroundColor="bg-black"
                            textColor="text-primary"
                            excludeArrow
                            href={url}
                        >
                            {link.title}
                        </CTAButton>
                    </DropdownChild>
                )}
                {Boolean(webform) && (
                    <ContactDialog
                        buttonClassName="hover:fill-white transition-[padding,gap]"
                        webform={webform}
                        triggerText={link.title}
                        icon={<Image className="mr-1.5" src={iconSrc} alt={iconAlt} width={18} height={18} />}
                    />
                )}
                {Boolean(link.items) && <ul className="flex flex-wrap justify-center gap-4">{link.items.map(childLink => renderLink(childLink))}</ul>}
            </li>
        );
    };

    const renderHeading = (link: DrupalMenuLinkContentWithWebform) =>
        !link.url &&
        showTitle && (
            <Heading type="h4" key={link.id} className="mb-6 mt-[46px] text-[20px] font-medium md:m-0 md:text-base">
                {link.title}
            </Heading>
        );

    return (
        <div className="flex flex-col items-center md:flex-row md:gap-8">
            {menu.map(link => renderHeading(link))}
            <ul className="flex flex-col items-center">{menu.map(link => renderLink(link))}</ul>
        </div>
    );
};
