import type {HTMLAttributeAnchorTarget} from 'react';

import {COLOR_VARIANTS, TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {motion} from 'framer-motion';
import {cn} from 'lib/utils';
import {ArrowRight} from 'lucide-react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {Fragment} from 'react';
import ExtendedArrow from './arrow';

interface CTAProps extends React.HTMLAttributes<HTMLElement> {
    href?: string;
    children?: React.ReactNode;
    type?: 'default' | 'continue' | 'back';
    icon?: React.ReactNode;
    className?: string;
    excludeArrow?: boolean;
    textColor?: (typeof TEXT_COLOR_VARIANTS)[keyof typeof TEXT_COLOR_VARIANTS];
    backgroundColor?: (typeof COLOR_VARIANTS)[keyof typeof COLOR_VARIANTS];
    target?: HTMLAttributeAnchorTarget;
}

type CTAWrapperProps = any & {
    href?: string;
    className?: string;
    children?: React.ReactNode;
};

export const DynamicLink = ({href, children, className, ...restProps}: CTAWrapperProps) => {
    const isInternalLink = href && (href.startsWith('/') || href.startsWith('#'));

    const MotionLink = motion(Link);

    if (!href)
        return (
            <motion.button {...restProps} className={className}>
                {children}
            </motion.button>
        );

    return isInternalLink ? (
        <MotionLink {...restProps} className={className} href={href}>
            {children}
        </MotionLink>
    ) : (
        <motion.a {...restProps} className={className} href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </motion.a>
    );
};

const CTAButton: React.FC<CTAProps> = ({
    href,
    children,
    icon,
    className,
    type = 'default',
    textColor,
    backgroundColor = COLOR_VARIANTS['bg-transparent'],
    excludeArrow,
    target = '_self',
    ...restProps
}) => {
    const {back} = useRouter();
    const COMPONENT_CLASSES: Record<CTAProps['type'], string> = {
        default: '',
        continue: 'text-lg px-0 hover:px-0 hover:gap-0 hover:px-0',
        back: 'm-0 px-0 hover:gap-0 hover:px-0',
    } as const;

    const ARROW_COLORS = {
        'text-black': 'black',
        'text-white': 'white',
        'text-primary': 'primary',
    } as const;

    const isArrowRightExcluded = (['continue', 'back'] as CTAProps['type'][]).includes(type) || excludeArrow;

    const isExtendedArrow = type === 'back' || type === 'continue';
    const ArrowProxy = isExtendedArrow ? ExtendedArrow : Fragment;
    const arrowProps = isExtendedArrow
        ? {
              animationVariant: (backgroundColor !== COLOR_VARIANTS['bg-transparent'] ? 'extend' : 'default') as 'extend' | 'default',
              type: type as 'back' | 'continue',
              hoverColor: ARROW_COLORS[textColor] ?? 'black',
              color: ARROW_COLORS[textColor] ?? 'black',
          }
        : null;

    return (
        <DynamicLink
            {...restProps}
            whileHover={'hover'}
            exit={'exit'}
            initial={'initial'}
            className={cn(
                'group relative',
                'm-0 inline-flex cursor-pointer items-center justify-center gap-0 whitespace-nowrap rounded-xl px-3 py-[10px] text-center text-base font-medium text-black transition-all duration-300 active:ring-offset-background',
                {
                    'hover:gap-3 hover:px-5': !isArrowRightExcluded,
                    'px-5 hover:gap-3 hover:px-6': isArrowRightExcluded,
                },
                {
                    '!pl-7': type === 'continue' && backgroundColor && backgroundColor !== COLOR_VARIANTS['bg-transparent'],
                },
                {
                    '!gap-1 !pl-3 !pr-6': type === 'back' && backgroundColor && backgroundColor !== COLOR_VARIANTS['bg-transparent'],
                },
                COMPONENT_CLASSES[type],
                backgroundColor,
                textColor,
                className
            )}
            href={href}
            {...(href && {
                target,
            })}
            {...(type === 'back' &&
                !href && {
                    onClick: back,
                })}
        >
            {icon && type !== 'back' && <span className="inline-flex items-center">{icon}</span>}

            <ArrowProxy {...arrowProps}>
                <span
                    className={cn({
                        'duration-750 translate-x-2 transition-all group-hover:translate-x-1': !isArrowRightExcluded,
                    })}
                >
                    {children}
                </span>
            </ArrowProxy>
            {!isArrowRightExcluded && (
                <span className="scale-0 transition-all duration-300 group-hover:block group-hover:w-auto group-hover:scale-100">
                    <ArrowRight width={18} />
                </span>
            )}
        </DynamicLink>
    );
};

export default CTAButton;
