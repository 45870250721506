import type {DrupalParagraph} from 'next-drupal';

import {COLOR_VARIANTS, CONTAINER_WIDTH_VARIANTS, PARAGRAPH_TYPES} from '@/lib/constants';
import {NodePageMeta} from '@/lib/interfaces';
import {getSpacing} from '@/lib/spacing';
import {AllSections} from '@/lib/types';
import {absoluteUrl, cn} from 'lib/utils';
import {PropsWithChildren} from 'react';
import ParagraphAccordion from './paragraph--accordion';
import ParagraphAnimation from './paragraph--animation';
import ParagraphAuthor from './paragraph--author';
import ParagraphCallToAction from './paragraph--call-to-action';
import ParagraphContentSlider from './paragraph--content-slider';
import ParagraphCtaBanner from './paragraph--cta-banner';
import ParagraphCtaButton from './paragraph--cta-button';
import ParagraphDocumentBanner from './paragraph--document-banner';
import ParagraphHeading from './paragraph--heading';
import ParagraphHeroImage from './paragraph--hero-image';
import ParagraphHorizontalBanner from './paragraph--horizontal-banner';
import ParagraphIcon from './paragraph--icon';
import ParagraphImage from './paragraph--image';
import ParagraphImageGallery from './paragraph--image-gallery';
import ParagraphInfoBanner from './paragraph--info-banner';
import ParagraphInfographicBanner from './paragraph--infographic-banner';
import ParagraphKeyVisual from './paragraph--key-visual';
import ParagraphLinkSelector from './paragraph--link-selector';
import ParagraphQuote from './paragraph--quote';
import ParagraphRemoteVideo from './paragraph--remote-video';
import ParagraphRichText from './paragraph--rich-text';
import ParagraphSearch from './paragraph--search';
import ParagraphSlider from './paragraph--slider';
import ParagraphVideo from './paragraph--video';
import ParagraphView from './paragraph--view';
import ParagraphWebform from './paragraph--webform';

interface ParagraphSectionProps extends React.HTMLAttributes<HTMLElement> {
    paragraph: DrupalParagraph;
    nodeChildren: DrupalParagraph[];
    children?: React.ReactNode;
    containerClassName?: string;
    containerWidth?: string;
    meta?: NodePageMeta;
    currentSectionIndex: number;
    allSections: AllSections;
}

const mapChildParagraphs = (
    nodeChildren: DrupalParagraph[],
    meta: NodePageMeta,
    currentSectionIndex: number,
    allSections: AllSections,
    columnType: 1 | 2 | 3
) => {
    const currentSectionAllNodes = nodeChildren;

    const upperSection = allSections?.[currentSectionIndex - 1];
    const lowerSection = allSections?.[currentSectionIndex + 1];

    return nodeChildren.map((child, index) => (
        <Paragraphs
            key={`${child.id}${index}paragraph`}
            paragraph={child}
            meta={meta}
            currentSectionIndex={currentSectionIndex}
            allSections={allSections}
            className={getSpacing(child, index, currentSectionAllNodes, currentSectionIndex, allSections, columnType, upperSection, lowerSection)}
            imageClassName={child.type === PARAGRAPH_TYPES.image && !upperSection ? 'rounded-none' : ''}
            columnType={columnType}
        />
    ));
};

interface Props extends PropsWithChildren {
    paragraph: DrupalParagraph;
    meta?: NodePageMeta;
    className?: string;
    imageClassName?: string;
    allSections: AllSections;
    currentSectionIndex: number;
    columnType: 1 | 2 | 3;
}

const Paragraphs = ({meta, currentSectionIndex, allSections, columnType, ...restProps}: Props) => {
    const {paragraph} = restProps;
    return (
        <>
            {paragraph.type === PARAGRAPH_TYPES.image && <ParagraphImage {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.slider && <ParagraphSlider {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.contentSlider && <ParagraphContentSlider {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.callToAction && <ParagraphCallToAction {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.keyVisual && <ParagraphKeyVisual {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.heading && <ParagraphHeading {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.ctaButton && <ParagraphCtaButton {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.documentBanner && <ParagraphDocumentBanner {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.view && <ParagraphView {...restProps} meta={meta} />}
            {paragraph.type === PARAGRAPH_TYPES.linkSelector && <ParagraphLinkSelector {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.richText && <ParagraphRichText {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.imageGallery && <ParagraphImageGallery {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.ctaBanner && <ParagraphCtaBanner {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.infoBanner && <ParagraphInfoBanner {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.infographicBanner && <ParagraphInfographicBanner {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.author && <ParagraphAuthor {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.accordion && <ParagraphAccordion {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.video && <ParagraphVideo {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.remoteVideo && <ParagraphRemoteVideo {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.animation && <ParagraphAnimation {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.webform && <ParagraphWebform {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.search && <ParagraphSearch {...restProps} nodeMeta={meta} />}
            {paragraph.type === PARAGRAPH_TYPES.quote && <ParagraphQuote {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.heroImage && <ParagraphHeroImage {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.horizontalBanner && <ParagraphHorizontalBanner {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.icon && <ParagraphIcon {...restProps} />}
            {paragraph.type === PARAGRAPH_TYPES.reusableParagraph &&
                mapChildParagraphs([paragraph.field_reusable_paragraph.paragraphs], meta, currentSectionIndex, allSections, columnType)}
        </>
    );
};

const OneColumnSection: React.FC<ParagraphSectionProps> = ({
    paragraph,
    children,
    nodeChildren,
    className,
    containerClassName,
    containerWidth,
    allSections,
    currentSectionIndex,
    meta,
    ...props
}) => {
    const title = paragraph.field_title?.value;
    return (
        <section className={cn(className)} {...props}>
            {title && (
                <div className="container">
                    <h2>{title}</h2>
                </div>
            )}
            <div className={cn('', containerWidth, containerClassName)}>{mapChildParagraphs(nodeChildren, meta, currentSectionIndex, allSections, 1)}</div>
            {children}
        </section>
    );
};

const TwoColumnSection: React.FC<ParagraphSectionProps> = ({
    paragraph,
    nodeChildren,
    children,
    className,
    containerClassName,
    containerWidth,
    currentSectionIndex,
    allSections,
    meta,
    ...props
}) => {
    const firstColumn = nodeChildren.filter(child => child.behavior_settings.layout_paragraphs.region === 'first');
    const secondColumn = nodeChildren.filter(child => child.behavior_settings.layout_paragraphs.region === 'second');
    const title = paragraph.field_title?.value;
    const columnsWidths = paragraph.behavior_settings.layout_paragraphs.config.column_widths as string;

    const gridColsVariants = {
        '50-50': 'md:grid-cols-2',
        '33-67': 'md:grid-cols-[minmax(0,1fr)_minmax(0,2fr)]',
        '67-33': 'md:grid-cols-[minmax(0,2fr)_minmax(0,1fr)]',
        '25-75': 'md:grid-cols-[minmax(0,1fr)_minmax(0,3fr)]',
        '75-25': 'md:grid-cols-[minmax(0,3fr)_minmax(0,1fr)]',
    };

    return (
        <section className={cn(className)} {...props}>
            {title && (
                <div className="container">
                    <h2>{title}</h2>
                </div>
            )}

            <div className={cn('grid grid-cols-1 gap-0 px-8 sm:gap-12', gridColsVariants[columnsWidths], containerClassName, containerWidth)}>
                <div>{mapChildParagraphs(firstColumn, meta, currentSectionIndex, allSections, 2)}</div>
                <div>{mapChildParagraphs(secondColumn, meta, currentSectionIndex, allSections, 2)}</div>
            </div>
            {children}
        </section>
    );
};

const ThreeColumnSection: React.FC<ParagraphSectionProps> = ({
    paragraph,
    children,
    className,
    nodeChildren,
    containerClassName,
    containerWidth,
    meta,
    currentSectionIndex,
    allSections,
    ...props
}) => {
    const firstColumn = nodeChildren.filter(child => child.behavior_settings.layout_paragraphs.region === 'first');
    const secondColumn = nodeChildren.filter(child => child.behavior_settings.layout_paragraphs.region === 'second');
    const thirdColumn = nodeChildren.filter(child => child.behavior_settings.layout_paragraphs.region === 'third');
    const title = paragraph.field_title?.value;
    const columnsWidths = paragraph.behavior_settings.layout_paragraphs.config.column_widths as string;

    const gridColsVariants = {
        '25-50-25': 'lg:grid-cols-[minmax(0,1fr)_minmax(0,2fr)_minmax(0,1fr)] ',
        '33-34-33': 'lg:grid-cols-3 ',
        '25-25-50': 'lg:grid-cols-[minmax(0,1fr)_minmax(0,1fr)_minmax(0,2fr)] ',
        '50-25-25': 'lg:grid-cols-[minmax(0,2fr)_minmax(0,1fr)_minmax(0,1fr)] ',
    };

    return (
        <section className={cn(className)} {...props}>
            {title && (
                <div className="container">
                    <h2>{title}</h2>
                </div>
            )}
            <div className={cn('grid grid-cols-1 gap-0 px-8 sm:gap-12 md:grid-cols-2', gridColsVariants[columnsWidths], containerClassName, containerWidth)}>
                <div>{mapChildParagraphs(firstColumn, meta, currentSectionIndex, allSections, 3)}</div>
                <div>{mapChildParagraphs(secondColumn, meta, currentSectionIndex, allSections, 3)}</div>
                <div className={cn('self-end md:col-span-2 lg:col-span-1')}>{mapChildParagraphs(thirdColumn, meta, currentSectionIndex, allSections, 3)}</div>
            </div>
            {children}
        </section>
    );
};

const ParagraphSection: React.FC<ParagraphSectionProps> = ({paragraph, nodeChildren, containerClassName, meta, currentSectionIndex, allSections}) => {
    const layout = paragraph.behavior_settings.layout_paragraphs.layout;
    const backgroundImageUrl = paragraph.field_image?.field_media_image?.uri.url;
    const id = paragraph.behavior_settings.layout_paragraphs?.config?.id;
    const idProp = id ? {id} : {};

    const backgroundImageStyle = backgroundImageUrl && {backgroundImage: `url(${absoluteUrl(backgroundImageUrl)})`};
    const backgroundColorPrimary = paragraph.field_primary_background_color
        ? COLOR_VARIANTS[paragraph.field_primary_background_color]
        : COLOR_VARIANTS['bg-white'];
    const backgroundColorSecondary = paragraph.field_secondary_background_color
        ? COLOR_VARIANTS[paragraph.field_secondary_background_color]
        : COLOR_VARIANTS['bg-white'];
    const containerWidth = paragraph.behavior_settings.layout_paragraphs?.config?.container_width
        ? CONTAINER_WIDTH_VARIANTS[paragraph.behavior_settings.layout_paragraphs?.config?.container_width]
        : '';

    const className = cn('z-10 relative clear-both', {
        'bg-fill bg-center': backgroundImageUrl,
        [backgroundColorPrimary]: !backgroundImageUrl,
    });

    const renderSecondaryBackground = () => (
        <>
            {paragraph.field_secondary_background_color && !backgroundImageUrl && (
                <div className={cn('absolute bottom-0 -z-10 h-24 w-full', backgroundColorSecondary)} />
            )}
        </>
    );

    const getColumnType = () => {
        switch (layout) {
            case 'git_decoupled_one_column':
                return OneColumnSection;
            case 'git_decoupled_two_column':
                return TwoColumnSection;
            case 'git_decoupled_three_column':
                return ThreeColumnSection;
            default:
                return null;
        }
    };

    const Section = getColumnType();

    return (
        <Section
            {...idProp}
            nodeChildren={nodeChildren}
            allSections={allSections}
            paragraph={paragraph}
            className={cn(className)}
            containerClassName={cn(containerClassName, '')}
            style={backgroundImageStyle}
            containerWidth={containerWidth}
            meta={meta}
            currentSectionIndex={currentSectionIndex}
        >
            {renderSecondaryBackground()}
        </Section>
    );
};

export default ParagraphSection;
