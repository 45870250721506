'use client';

import type {MultiSelectItem} from '@/lib/types';

import Caret from '@/components/atoms/svg/caret';
import {cn} from '@/lib/utils';
import {Command as CommandPrimitive} from 'cmdk';
import {AnimatePresence, Variants, motion} from 'framer-motion';
import {useTranslation} from 'next-i18next';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Command, CommandEmpty, CommandGroup, CommandItem, CommandList} from '../command/command';

interface MultiSelectProps {
    placeholder: string;
    onValueChange?: (value: MultiSelectItem[]) => void;
    selectItems: MultiSelectItem[];
    defaultValue?: MultiSelectItem[];
    emptyStateText?: string;
    listClassname?: string;
    className?: string;
}

const MultiSelect = ({
    placeholder,
    onValueChange,
    defaultValue = [],
    selectItems,
    emptyStateText = 'noResultsFound',
    listClassname,
    className,
}: MultiSelectProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<MultiSelectItem[]>(defaultValue);
    const [inputValue, setInputValue] = useState('');
    const {t} = useTranslation();

    const handleUnselect = useCallback((item: MultiSelectItem) => {
        setInputValue('');
        setSelected(prev => prev.filter(s => s.value !== item.value));
    }, []);

    const handleSelect = useCallback((item: MultiSelectItem) => {
        setInputValue('');
        setSelected(prev => [...prev, item]);
    }, []);

    useEffect(() => {
        if (!onValueChange) return;
        onValueChange(selected);
    }, [selected, onValueChange]);

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (input && e.key === 'Escape') {
            setInputValue('');
            input.blur();
        }
    }, []);

    const openMultiSelect = () => {
        setOpen(true);
        buttonRef.current.dataset.focus = 'true';
        inputRef.current.dataset.focus = 'true';
    };

    const closeMultiSelect = () => {
        setInputValue('');
        setOpen(false);
        buttonRef.current.dataset.focus = 'false';
        inputRef.current.dataset.focus = 'false';
    };

    const onInternalValueChange = (value: string) => {
        if (!open) {
            openMultiSelect();
        }
        setInputValue(value);
    };

    const commandListVariants: Variants = {
        open: {
            gridTemplateRows: '1fr',
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
        closed: {
            gridTemplateRows: '0fr',
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
    };

    return (
        <Command onKeyDown={handleKeyDown} className={cn('z-50 overflow-visible bg-transparent', className)}>
            <div
                className={cn('group rounded-xl bg-white text-sm transition-[border-radius] ', {
                    'rounded-b-none duration-0': open,
                    'delay-200 duration-200': !open,
                })}
            >
                <div className="relative flex gap-1">
                    <CommandPrimitive.Input
                        ref={inputRef}
                        value={inputValue}
                        onValueChange={onInternalValueChange}
                        onBlur={closeMultiSelect}
                        onFocus={openMultiSelect}
                        placeholder={placeholder}
                        className={cn(
                            'z-10 w-full flex-1 flex-shrink bg-transparent px-3 py-3 outline-none placeholder:text-black placeholder:transition-[font]',
                            {
                                'placeholder:font-bold': open,
                            }
                        )}
                    />
                    <button
                        tabIndex={-1}
                        ref={buttonRef}
                        onFocus={() => {
                            const isFocused = inputRef.current.dataset.focus === 'true';
                            if (isFocused) {
                                openMultiSelect();
                            }
                        }}
                        onMouseDown={() => {
                            const isFocused = buttonRef.current.dataset.focus === 'true' || inputRef.current.dataset.focus === 'true';
                            if (isFocused) {
                                closeMultiSelect();
                            } else {
                                openMultiSelect();
                            }
                        }}
                        onBlur={closeMultiSelect}
                        className="flex outline-none"
                    >
                        <div aria-hidden className={cn('flex items-center justify-center p-2 transition-transform', {'rotate-180': open})}>
                            <Caret />
                        </div>
                    </button>
                    <AnimatePresence>
                        {open && (
                            <motion.div
                                variants={commandListVariants}
                                initial="closed"
                                animate="open"
                                exit="closed"
                                className={cn(
                                    'absolute top-[44px] z-50 grid w-full rounded-xl rounded-b-xl rounded-t-none bg-white text-black shadow-md outline-none'
                                )}
                            >
                                <div className="overflow-hidden">
                                    <CommandList
                                        onMouseDown={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <CommandEmpty>{t(emptyStateText)}</CommandEmpty>
                                        <CommandGroup className={cn('h-full overflow-auto', listClassname)}>
                                            {selectItems.map((item, index) => {
                                                const isSelected = selected?.map(item => item.value).includes(item.value);
                                                return (
                                                    <CommandItem
                                                        key={item.value + index}
                                                        onMouseDown={e => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        onSelect={() => {
                                                            if (isSelected) {
                                                                handleUnselect(item);
                                                            } else {
                                                                handleSelect(item);
                                                            }
                                                        }}
                                                        className={cn('cursor-pointer', {
                                                            '!bg-primary': isSelected,
                                                        })}
                                                    >
                                                        {item.label}
                                                    </CommandItem>
                                                );
                                            })}
                                        </CommandGroup>
                                    </CommandList>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </Command>
    );
};

export default MultiSelect;
