import type {MultiSelectItem, ParamValue, ViewFilter} from '@/lib/types';

import useCreateQueryString from '@/hooks/useCreateQueryString';
import useScreenSize from '@/hooks/useScreenSize';
import {SCREENS, STORAGE_KEYS} from '@/lib/constants';
import {articleFetchOptions} from '@/lib/views';
import i18nConfig from '@/next-i18next.config';
import {zodResolver} from '@hookform/resolvers/zod';
import {keepPreviousData, useQuery, useQueryClient} from '@tanstack/react-query';
import {capitalizeFirstLetter, cn, saveFilters} from 'lib/utils';
import {JsonApiResource} from 'next-drupal';
import {useTranslation} from 'next-i18next';
import {usePathname} from 'next/navigation';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {useForm, useFormContext} from 'react-hook-form';
import * as z from 'zod';
import CTAButton from '../atoms/buttons/cta-button';
import Heading from '../atoms/heading';
import Loader from '../atoms/loader';
import {Form, FormField, FormItem} from '../molecules/form/form';
import MultiSelect from '../molecules/multi-select/multi-select';
import Pagination from '../molecules/pagination/pagination';
import NodeArticleTeaser from '../nodes/node--article--teaser';
import ParagraphImage from '../paragraphs/paragraph--image';

const ViewArticleListing = ({view: viewConfig, meta}) => {
    const screen = useScreenSize();
    const {t} = useTranslation();
    const router = useRouter();
    const pathname = usePathname();
    const {locale: activeLocale} = router;
    const defaultLocale = i18nConfig.defaultLocale;
    const searchParams = new URLSearchParams(`?${router.asPath.split('?')[1]}`);
    const createQueryString = useCreateQueryString();
    const displayId = viewConfig.resourceIdObjMeta.display_id;
    const viewName = `${viewConfig.resourceIdObjMeta.drupal_internal__target_id}--${displayId}`;
    const articleListingTitle = pathname.split('/')[1].split('-').join(' ');
    const filters: ViewFilter[] = viewConfig?.display?.[displayId]?.display_options?.filters || viewConfig?.display?.default?.display_options?.filters;
    const exposedFilters: ViewFilter[] = Object.values(filters).filter((filter: ViewFilter) => filter.exposed);
    const paginationLength =
        viewConfig?.display?.[displayId]?.display_options?.pager?.options?.items_per_page ||
        viewConfig?.display?.default?.display_options?.pager?.options?.items_per_page;
    const headers = viewConfig?.display?.[displayId]?.display_options?.header || viewConfig?.display?.default?.display_options?.header;
    const headerViewName = headers?.view?.view_to_insert ? headers.view.view_to_insert.replace(':', '--') : '';
    const page = isNaN(meta?.page) ? 0 : Number(meta?.page);
    const [currentPage, setCurrentPage] = useState(page);

    const formSchema = z.object(
        exposedFilters.reduce((acc, filter) => {
            if (filter.expose.identifier) {
                return {...acc, [filter.expose.identifier]: z.string().optional()};
            }
            return acc;
        }, {})
    );

    const defaultFilters = Object.values(filters)
        .filter((filter: ViewFilter) => filter.exposed)
        .reduce((acc, filter) => {
            const value = searchParams.get(filter.expose.identifier);

            if (value) {
                const searchParamsValue: Record<string, MultiSelectItem[]> = {
                    [filter.expose.identifier]: value
                        .split(',')
                        .map(filterValue => {
                            if (!filterValue || filterValue === '') return null;
                            return {value: filterValue, label: filter.value[filterValue]};
                        })
                        .filter(Boolean),
                };
                return {...acc, ...searchParamsValue};
            }
            return acc;
        }, {});

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: defaultFilters,
    });

    const {getValues} = form;

    useEffect(() => {
        saveFilters(STORAGE_KEYS.articleFilters, router.asPath);
    }, [router.asPath]);

    const queryClient = useQueryClient();
    const {data, isFetching, isLoading, isPlaceholderData} = useQuery({
        ...articleFetchOptions(viewName, headerViewName, currentPage, activeLocale, defaultLocale, getValues, exposedFilters),
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    });

    const createPagePath = (page: number) => {
        const pageRegex = new RegExp(`/\\d+$`);
        let newPath = pathname.replace(pageRegex, '');
        if (page <= 0) {
            return newPath;
        }
        newPath = `${newPath}/${page}`;
        return newPath;
    };

    const handlePageChange = (page?: number) => {
        if (!isPlaceholderData) {
            const exposedFiltersIds: string[] = exposedFilters.map(filter => filter.expose.identifier);
            const paramValues: ParamValue[] = exposedFiltersIds.map(filter => {
                const filterValues = getValues()[filter]?.join(',');
                return {name: filter, value: filterValues ?? []} satisfies ParamValue;
            });
            const queryStrings = createQueryString(paramValues);
            const newPath = createPagePath(page + 1);
            router.push(`${newPath}${queryStrings ? `?${queryStrings}` : ''}`, undefined, {shallow: true});
            setCurrentPage(page);
        }
    };

    const submitForm = form.handleSubmit(async () => {
        await queryClient.invalidateQueries({queryKey: [viewName]});
    });

    const [view, headerView] = data || [];
    const allArticles = Number(view?.meta?.count);
    const firstArticle = headerView?.results?.[0];
    const articles = view?.results as JsonApiResource;

    useEffect(() => {
        const subscription = form.watch(values => {
            const exposedFiltersIds: string[] = exposedFilters.map(filter => filter.expose.identifier);
            const paramValues: ParamValue[] = exposedFiltersIds.map(filter => {
                const filterValues = values[filter]?.map(filterValue => filterValue.value).join(',');
                return {name: filter, value: filterValues} satisfies ParamValue;
            });
            const queryStrings = createQueryString(paramValues);
            const newPath = createPagePath(0);
            setCurrentPage(0);
            router.replace(`${newPath}${queryStrings ? `?${queryStrings}` : ''}`, undefined, {shallow: true, scroll: false});
            submitForm();
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createQueryString, exposedFilters, form, pathname, router, searchParams, submitForm]);

    return (
        <div
            className={cn('relative z-10 mx-auto items-start gap-10', {
                'pb-20': Boolean(articles?.length) || allArticles >= paginationLength,
            })}
        >
            <div
                className={cn('h-auto bg-primary lg:h-[calc(100vh-150px)]', {
                    'pt-[150px] lg:h-min': !firstArticle,
                })}
            >
                <div
                    className={cn(
                        'wide-container z-20 flex h-auto w-full flex-col items-center bg-primary pb-[39px] pt-8 lg:h-[180px] lg:flex-row lg:items-end lg:pb-16 lg:pt-0'
                    )}
                >
                    <Heading className="flex h-[1em] w-full items-center justify-center text-[48px] font-normal leading-[35px] lg:-ml-1 lg:justify-start lg:text-[80px] lg:font-light">
                        <span className="mr-[1rem] font-medium text-white">{capitalizeFirstLetter(articleListingTitle.split(' ')[0])}</span>
                        {articleListingTitle.split(' ')[1] && capitalizeFirstLetter(articleListingTitle.split(' ')[1])}
                    </Heading>
                    <Form {...form}>
                        <Filters filters={exposedFilters} />
                    </Form>
                </div>
                {firstArticle && <HeroArticle product={firstArticle} />}
            </div>
            <div
                className={cn('flex flex-col items-center', {
                    'pt-20': Boolean(articles?.length) || allArticles >= paginationLength,
                })}
            >
                {articles?.length ? (
                    <div
                        className={cn('wide-container grid grid-flow-row gap-10 md:grid-cols-2 md:gap-y-28 md:px-12 lg:grid-cols-3', {
                            'pointer-events-none animate-pulse opacity-70': isLoading || isFetching,
                        })}
                    >
                        {articles.map(node => (
                            <div className="border-t border-middleground first:border-none lg:border-none" key={node.id}>
                                <div className="px-4 pt-12 lg:px-0">
                                    <NodeArticleTeaser node={node} />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
                {(isFetching || isLoading) && !articles?.length && (
                    <div className="h-48 py-8">
                        <Loader />
                    </div>
                )}
                {!firstArticle && !isFetching && !isLoading && !articles?.length && (
                    <Heading type="h5" className="py-20 text-center">
                        {t('noArticlesFound')}
                    </Heading>
                )}
                {articles?.length && allArticles >= paginationLength ? (
                    <div
                        className={cn('flex px-2 pt-12 md:justify-center', {
                            'pointer-events-none animate-pulse opacity-70': isLoading || isFetching,
                        })}
                    >
                        <Pagination
                            currentPage={currentPage + 1}
                            onPageChange={page => handlePageChange(page - 1)}
                            pageSize={paginationLength}
                            totalCount={allArticles}
                            simple={screen.name !== SCREENS.desktop.name ? true : false}
                            path={meta?.path}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const Filters = ({filters}: {filters: ViewFilter[]}) => {
    const {t} = useTranslation();
    const formContext = useFormContext();
    return (
        <div className="flex w-full flex-col items-center justify-between lg:flex-row lg:justify-end lg:gap-8">
            <span className="pb-12 pt-4 text-center lg:p-0 lg:text-right">{t('pickCategory')}</span>
            {filters.map(filter => {
                const selectItems: MultiSelectItem[] = Object.entries(filter.value).map(
                    ([key, value]) =>
                        ({
                            label: value,
                            value: key,
                        }) as MultiSelectItem
                );
                return (
                    <FormField
                        key={filter?.expose.identifier}
                        control={formContext.control}
                        name={filter?.expose.identifier}
                        render={({field}) => (
                            <FormItem className="w-full max-w-[320px]">
                                {/*TODO: Render MultiSelect and SingleSelect based on filter.expose.multiple*/}
                                {/*TODO: Render text field by default*/}
                                {(filter?.type === 'select' || ('plugin_id' in filter && filter?.plugin_id === 'list_field')) && (
                                    <MultiSelect
                                        onValueChange={field.onChange}
                                        placeholder={filter.expose.label}
                                        key={filter.expose.identifier}
                                        selectItems={selectItems}
                                        defaultValue={field.value}
                                    />
                                )}
                            </FormItem>
                        )}
                    />
                );
            })}
        </div>
    );
};

const HeroArticle = ({product}) => {
    const {t} = useTranslation();

    return (
        <div className="relative h-screen text-white lg:h-[calc(100%-180px)]">
            {product.field_image_paragraph && <ParagraphImage full imageClassName="h-full" className="h-full" paragraph={product.field_image_paragraph} />}
            <div className="wide-container absolute left-1/2 top-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 flex-col justify-between py-16 lg:h-auto lg:space-y-8">
                <div className="space-y-8">
                    <span>{t('newestArticle')}</span>
                    <Heading className="text-[27px] font-light lg:max-w-[40%] lg:text-[40px]">{product.title}</Heading>
                </div>
                <span>
                    <CTAButton backgroundColor="bg-primary" href={product.path?.alias}>
                        {t('readMore')}
                    </CTAButton>
                </span>
            </div>
        </div>
    );
};

export default ViewArticleListing;
