import type {DrupalParagraph} from 'next-drupal';

import {absoluteUrl, cn} from '@/lib/utils';
import {getAlignmentClasses} from 'lib/alignment';
import Image from 'next/image';

interface ParagraphIconProps {
    paragraph: DrupalParagraph;
    imageClassName?: string;
    className?: string;
}

const ParagraphIcon = ({paragraph, imageClassName, className}: ParagraphIconProps) => {
    const alignmentClasses = getAlignmentClasses({
        horizontalAlignment: paragraph.field_horizontal_position,
        verticalAlignment: paragraph.field_vertical_position,
    });

    return (
        <div className={cn(className, alignmentClasses)}>
            {paragraph.field_image?.type === 'media--icon' && (
                <Image
                    key={paragraph.id}
                    src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                    alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                    width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                    height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                    className={imageClassName}
                />
            )}
            {paragraph.field_image?.type === 'media--svg_image' && (
                <Image
                    key={paragraph.id}
                    src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                    alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                    width={paragraph.field_image.field_width ?? paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                    height={paragraph.field_image.field_height ?? paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                    className={imageClassName}
                />
            )}
        </div>
    );
};

export default ParagraphIcon;
