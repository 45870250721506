import type {DrupalMedia, DrupalNode} from 'next-drupal';

import {PRODUCT_FIELD_TYPES} from '@/lib/constants';
import {absoluteUrl, cn} from '@/lib/utils';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import Body from '../atoms/body';
import CTAButton from '../atoms/buttons/cta-button';
import Heading from '../atoms/heading';

interface NodePimcoreProductTeaserProps {
    node: DrupalNode;
    more?: string;
    isSlide?: boolean;
}

export const Standard = ({node, more, isSlide = false, ...props}: NodePimcoreProductTeaserProps) => {
    const {t} = useTranslation();

    return (
        <article
            className={cn('flex h-72 justify-between gap-4 rounded-xl bg-white px-4 pb-[18px] pt-[28px] shadow-xl lg:p-[25px]', {
                'mt-12 h-auto flex-col': isSlide,
                'flex-row': !isSlide,
            })}
            {...props}
        >
            <div
                className={cn('flex flex-1 flex-col items-start justify-between', {
                    'mt-32': isSlide,
                })}
            >
                <div>
                    {node.title && (
                        <Heading type="h4" tag="h2" className="break-word mb-4 line-clamp-2 text-left font-semibold">
                            <Link href={node.path?.alias ?? '/'}>{node.title}</Link>
                        </Heading>
                    )}
                    {node.field_headline && <Body className="break-word line-clamp-2 text-[16px] leading-[24px]" value={node.field_headline} />}
                </div>
                {node.path?.alias && (
                    <span>
                        <CTAButton backgroundColor="bg-lightgray" className="font-normal" href={node.path?.alias ?? '/'} excludeArrow>
                            {more ?? t('seeDetails')}
                        </CTAButton>
                    </span>
                )}
            </div>
            <div
                className={cn('max-w-[135px] flex-1', {
                    'absolute -top-8 w-full max-w-[200px]': isSlide,
                })}
            >
                {node?.field_assets?.length ? (
                    node?.field_assets
                        .filter((asset: DrupalMedia) => asset.field_type === PRODUCT_FIELD_TYPES.packagingPicture)
                        .map((asset: DrupalMedia) => {
                            if (!asset.field_media_image) return null;
                            const {uri, resourceIdObjMeta} = asset.field_media_image;
                            const {alt, width, height} = resourceIdObjMeta;

                            if (!width || !height) {
                                return <div key={asset.name || alt} className="h-full w-full rounded-xl bg-gray-200" />;
                            }

                            return (
                                <Link key={asset.name} href={node.path?.alias ?? '/'}>
                                    <Image
                                        className="h-full w-full rounded-xl object-contain"
                                        src={absoluteUrl(uri.url)}
                                        alt={alt ?? asset.name}
                                        width={+width}
                                        height={+height}
                                    />
                                </Link>
                            );
                        })
                        .filter(Boolean)[0] ?? <div className="h-full w-full rounded-xl bg-gray-200" />
                ) : (
                    <div className="h-full w-full rounded-xl bg-gray-200" />
                )}
            </div>
        </article>
    );
};

const Desktop = ({node, more, ...props}: NodePimcoreProductTeaserProps) => {
    const {t} = useTranslation();

    return (
        <article className={cn('relative flex justify-between gap-4  px-3 pb-[28px]')} {...props}>
            <div className="absolute inset-0 top-24 z-10 rounded-xl bg-white shadow-lg" />
            <div className={cn('z-20 flex flex-1 flex-col items-center justify-between')}>
                {node.field_assets &&
                    node.field_assets.length &&
                    (node.field_assets
                        .filter((asset: DrupalMedia) => asset.field_type === PRODUCT_FIELD_TYPES.packagingPicture)
                        .map((asset: DrupalMedia) => {
                            if (!asset.field_media_image) return null;
                            const {uri, resourceIdObjMeta} = asset.field_media_image;
                            const {alt, width, height} = resourceIdObjMeta;

                            if (!width || !height) {
                                return <div key={asset.name || alt} className="h-[280px] w-full rounded-xl bg-gray-200" />;
                            }

                            return (
                                <Link key={asset.name} href={node.path?.alias ?? '/'}>
                                    <Image
                                        className="h-[280px] w-full rounded-xl object-contain"
                                        src={absoluteUrl(uri.url)}
                                        alt={alt ?? asset.name}
                                        width={+width}
                                        height={+height}
                                    />
                                </Link>
                            );
                        })
                        .filter(Boolean)[0] ?? <div className="h-[280px] w-full rounded-xl bg-gray-200" />)}

                <div className="mb-8">
                    {node.title && (
                        <Heading type="h4" tag="h2" className="break-word mb-3 mt-4 line-clamp-1 text-center font-semibold">
                            <Link href={node.path?.alias ?? '/'}>{node.title}</Link>
                        </Heading>
                    )}
                    <div className="flex h-[calc(24px*2)] justify-center">
                        {node.field_headline && <Body className="line-clamp-2 h-full  text-center text-[16px] leading-6" value={node.field_headline} />}
                    </div>
                </div>
                {node.path?.alias && (
                    <span>
                        <CTAButton backgroundColor="bg-lightgray" className="font-normal" href={node.path?.alias ?? '/'} excludeArrow>
                            {more ?? t('seeDetails')}
                        </CTAButton>
                    </span>
                )}
            </div>
        </article>
    );
};

const variants = {Standard, Desktop};

export default variants;
