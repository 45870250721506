import type {DrupalLink} from '@/lib/interfaces';
import type {DrupalMenuLinkContent} from 'next-drupal';

import {absoluteUrl} from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import Heading from '../../atoms/heading';

interface SocialMenuProps {
    menu: DrupalMenuLinkContent[];
    showTitle?: boolean;
    inMenu?: boolean;
}

export const SocialMenu = ({menu, showTitle = true, inMenu = false}: SocialMenuProps) => {
    const renderLink = (link: DrupalLink) => {
        const url = link.field_link_override?.uri || link.url;
        return (
            <React.Fragment key={`${link.id}--fragment`}>
                {showTitle && link.url && (
                    <Link href={url} locale={link.langcode}>
                        {link.title}
                    </Link>
                )}

                <li key={link.id}>
                    {link.field_icon?.field_media_image?.uri?.url && url && (
                        <Link href={url} locale={link.langcode} target="_blank" className="inline-block h-10 w-10">
                            <Image
                                src={absoluteUrl(link.field_icon?.field_media_image?.uri?.url)}
                                alt={link.field_icon?.field_media_image?.resourceIdObjMeta?.alt || link.title}
                                width={link.field_icon?.field_width ?? link.field_icon?.field_media_image?.resourceIdObjMeta?.width}
                                height={link.field_icon?.field_height ?? link.field_icon?.field_media_image?.resourceIdObjMeta?.height}
                            />
                            <span className="sr-only">{link.title}</span>
                        </Link>
                    )}
                    {link.items && <ul className="flex gap-4">{link.items.map(childLink => renderLink(childLink))}</ul>}
                </li>
            </React.Fragment>
        );
    };

    const renderHeading = link =>
        !link.url &&
        !inMenu && (
            <Heading type="h4" key={link.id} className="mb-6 mt-[46px] text-[20px] font-medium md:m-0 md:text-base">
                {link.title}
            </Heading>
        );

    return (
        <div className="flex flex-col items-center md:flex-row md:gap-8">
            {menu.map(link => renderHeading(link))}
            <ul className="flex flex-col items-center">{menu.map(link => renderLink(link))}</ul>
        </div>
    );
};
