import {PathAlias} from 'next-drupal';
import {PaginationContent, PaginationEllipsis, PaginationLink, PaginationNext, PaginationPrevious, PaginationRoot} from './pagination-component';
import {DOTS, usePagination} from './usePagination';

interface PaginationProps {
    onPageChange: (page: number) => void;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
    className?: string;
    simple?: boolean;
    path: PathAlias;
}

const Pagination = ({onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, simple = false, path}: PaginationProps) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        simple,
    });

    if (isNaN(totalCount) || currentPage === 0 || !paginationRange?.length || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange[paginationRange?.length - 1];

    const createPagePath = (page: number) => `${path?.alias}/${page}`;

    return (
        <PaginationRoot>
            <PaginationContent>
                {currentPage > 1 && <PaginationPrevious onClick={onPrevious} href={createPagePath(currentPage - 1)} />}
                {paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return <PaginationEllipsis key={index + 'dots'} />;
                    }

                    return (
                        <PaginationLink
                            onClick={e => {
                                e.preventDefault();
                                onPageChange(pageNumber as number);
                            }}
                            key={index}
                            shallow
                            isActive={pageNumber === currentPage}
                            href={createPagePath(pageNumber as number)}
                        >
                            {pageNumber}
                        </PaginationLink>
                    );
                })}
                {currentPage !== lastPage && <PaginationNext onClick={onNext} href={createPagePath(currentPage + 1)} />}
            </PaginationContent>
        </PaginationRoot>
    );
};

export default Pagination;
