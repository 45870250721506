import {useMemo} from 'react';

const range = (start: number, end: number) => Array.from({length: end - start + 1}, (_, idx) => idx + start);

export const DOTS = '...';

export const usePagination = ({totalCount, pageSize, siblingCount = 1, currentPage, simple = false}) => {
    return useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        const totalPageNumbers = siblingCount + 5;

        if (simple) {
            const left = Math.max(currentPage === totalPageCount ? currentPage - 2 : currentPage - 1, 1);
            const right = Math.min(currentPage === 1 ? currentPage + 2 : currentPage + 1, totalPageCount);
            return range(left, right);
        }

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount;
            const leftRange = range(1, leftItemCount);
            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount;
            const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalCount, pageSize, siblingCount, currentPage, simple]);
};
