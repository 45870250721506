import ExtendedArrow from './arrow';
import {DynamicLink} from './cta-button';

const LinkButton = ({children, ...restProps}) => (
    <DynamicLink {...restProps}>
        <ExtendedArrow>
            <span className="text-[42px] font-light">{children}</span>
        </ExtendedArrow>
    </DynamicLink>
);

export default LinkButton;
