import type {DrupalParagraph} from 'next-drupal';

import {NodePageMeta} from '@/lib/interfaces';
import ViewArticleListing from '../views/view--article-listing';
import ViewProductListing from '../views/view--product-listing';

interface ParagraphViewProps {
    paragraph: DrupalParagraph;
    meta: NodePageMeta;
    className?: string;
}

const ParagraphView = ({paragraph, meta, className}: ParagraphViewProps) => (
    <div className={className}>
        {paragraph.field_view?.resourceIdObjMeta?.drupal_internal__target_id === 'product_listing' && (
            <ViewProductListing view={paragraph.field_view} meta={meta} />
        )}
        {paragraph.field_view?.resourceIdObjMeta?.drupal_internal__target_id === 'article_listing' && (
            <ViewArticleListing view={paragraph.field_view} meta={meta} />
        )}
    </div>
);

export default ParagraphView;
