import type {HeadingProps} from '@/lib/interfaces';
import type {DOMNode, Element, HTMLReactParserOptions} from 'html-react-parser';

import parse, {domToReact} from 'html-react-parser';
import {absoluteUrl, cn} from 'lib/utils';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import {DynamicLink} from './buttons/cta-button';
import Heading from './heading';
import Text from './text';
const Lottie = dynamic(() => import('react-lottie-player'), {ssr: false});

interface BodyProps {
    className?: string;
    value: string;
}

const TEXT_ALIGN_MAP = {
    'text-align-left': 'md:text-left',
    'text-align-right': 'md:text-right',
    'text-align-center': 'md:text-center',
    'text-align-justify': 'md:text-justify',
} as const;

const options: HTMLReactParserOptions = {
    replace: domNode => {
        if (isElement(domNode)) {
            let textAlign = TEXT_ALIGN_MAP['text-align-left'];

            if (domNode.attribs && domNode.attribs.class) {
                textAlign = TEXT_ALIGN_MAP[domNode.attribs.class];
            }

            if (domNode.name === 'p') {
                const Tag = domNode.name;
                return <Tag className={cn(domNode.attribs.class, textAlign)}>{domToReact(domNode.children as DOMNode[], options)}</Tag>;
            }

            if (domNode.name === 'figure' || domNode.name === 'figcaption') {
                const Tag = domNode.name;
                const {class: className} = domNode.attribs;
                return (
                    <Tag className={cn(className, 'm-0', textAlign)} {...domNode.attribs}>
                        {domToReact(domNode.children as DOMNode[], options)}
                    </Tag>
                );
            }

            if (domNode.name === 'img') {
                const {src, alt, width, height} = domNode.attribs;
                return <Image src={absoluteUrl(src)} width={+width} height={+height} alt={alt} className="w-full rounded-xl object-contain" />;
            }

            if (domNode.name === 'a') {
                const {href, class: className} = domNode.attribs;
                return (
                    <DynamicLink className={cn(className, textAlign)} href={href}>
                        {domToReact(domNode.children as DOMNode[], options)}
                    </DynamicLink>
                );
            }

            if (domNode.name === 'lottie-player') {
                const {src} = domNode.attribs;
                return <Lottie loop path={absoluteUrl(src)} play />;
            }

            if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(domNode.name)) {
                const {class: className} = domNode.attribs;
                const type = domNode.name as HeadingProps['type'];
                return (
                    <Heading type={type} className={cn(className, 'mb-8', textAlign)}>
                        {domToReact(domNode.children as DOMNode[], options)}
                    </Heading>
                );
            }

            if (domNode.name === 'caption') {
                return <caption className="mb-8 text-base">{domToReact(domNode.children as DOMNode[], options)}</caption>;
            }

            if (domNode.name === 'th') {
                return <th className="font-semibold">{domToReact(domNode.children as DOMNode[], options)}</th>;
            }
        }
    },
};

const isElement = (domNode: DOMNode): domNode is Element => {
    const isTag = domNode.type === 'tag';
    const hasAttributes = (domNode as Element).attribs !== undefined;
    return isTag && hasAttributes;
};

const Body = ({value, className, ...props}: BodyProps) => {
    const componentClasses = {
        proseOverrides: 'prose-p:text-black text-black prose-ul:list-inside prose-ol:list-inside',
    };

    return (
        <Text className={cn('prose max-w-full break-words', componentClasses.proseOverrides, className)} {...props}>
            {parse(value, options)}
        </Text>
    );
};

export default Body;
