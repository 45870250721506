import type {DrupalParagraph} from 'next-drupal';

import {absoluteUrl, cn} from '@/lib/utils';
import dynamic from 'next/dynamic';
const Lottie = dynamic(() => import('react-lottie-player'), {ssr: false});

interface ParagraphAnimationProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphAnimation = ({paragraph, className, ...props}: ParagraphAnimationProps) => (
    <article className={cn('h-full', className)} {...props}>
        {paragraph.field_animation.type === 'media--lottie_animation' && paragraph.field_animation?.field_media_lottie_file?.uri?.url && (
            <Lottie play loop path={absoluteUrl(paragraph.field_animation.field_media_lottie_file.uri.url)} />
        )}
    </article>
);

export default ParagraphAnimation;
