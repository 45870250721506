import type {DrupalNode, DrupalParagraph} from 'next-drupal';

import Body from '@/components/atoms/body';
import {TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {cn} from '@/lib/utils';
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '../molecules/accordion/accordion';
interface ParagraphAccordionProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphAccordion = ({paragraph, className}: ParagraphAccordionProps) => {
    const textColor = paragraph?.field_text_color ? TEXT_COLOR_VARIANTS[paragraph?.field_text_color] : TEXT_COLOR_VARIANTS['text-black'];

    return (
        <Accordion type="single" className={cn('mx-auto max-w-[872px]  lg:w-full', className)} collapsible>
            {paragraph.field_accordion_items?.map((item: DrupalNode) => {
                return (
                    <AccordionItem value={item.id} key={item.id}>
                        <AccordionTrigger>
                            {item.field_title?.processed && (
                                <Body
                                    className={cn('text-left text-[20px] font-semibold leading-[30px]', 'prose', {textColor})}
                                    value={item.field_title?.processed}
                                />
                            )}
                        </AccordionTrigger>
                        <AccordionContent forceMount>
                            {item.field_body?.processed && (
                                <Body
                                    className={cn('font-regular text-left text-base leading-[25px] ', 'prose', {textColor})}
                                    value={item.field_body?.processed}
                                />
                            )}
                        </AccordionContent>
                    </AccordionItem>
                );
            })}
        </Accordion>
    );
};

export default ParagraphAccordion;
