import {cn} from 'lib/utils';

interface HamburgerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isActive: boolean;
    color: 'black' | 'white';
}

const HamburgerButton = ({isActive, color, className, ...restProps}: HamburgerButtonProps) => (
    <button
        {...restProps}
        data-color={color}
        className={cn(
            'duration-400 relative block h-[21px] w-[31px] transition-all delay-0 [&>span]:data-[color=black]:bg-black [&>span]:data-[color=white]:bg-white',
            'before:content[""] before:duration-400 before:absolute before:bottom-0 before:left-0 before:h-[3px] before:w-full before:transition-all data-[color=black]:before:bg-black data-[color=white]:before:bg-white',
            'after:content[""] after:duration-400 after:absolute after:left-0 after:top-0 after:h-[3px] after:w-full after:transition-all data-[color=black]:after:bg-black data-[color=white]:after:bg-white',
            {
                'before:-translate-y-[9px] before:rotate-45 after:translate-y-[9px] after:-rotate-45': isActive,
            },
            className
        )}
        role="menu"
        aria-label="Menu"
    >
        <span
            className={cn('block h-[3px] w-full transition-all', {
                'scale-0': isActive,
            })}
            aria-hidden
        />
    </button>
);

export default HamburgerButton;
