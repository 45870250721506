import type {HeadingProps} from '@/lib/interfaces';

import {cn} from 'lib/utils';

const Heading: React.FC<HeadingProps> = ({children, className, type = 'h1', tag: DynamicTag = type, ...props}) => {
    const COMPONENT_CLASSES: Record<HeadingProps['type'], string> = {
        h1: 'text-[27px] leading-[35px] md:text-[40px] md:leading-[50px]',
        h2: 'text-[32px] leading-[42px]',
        h3: 'text-[27px] leading-[35px]',
        h4: 'text-[20px] leading-[30px]',
        h5: 'text-[18px] leading-[25px]',
        h6: 'text-[16px] leading-[25px]',
    } as const;

    return (
        <DynamicTag {...props} className={cn(COMPONENT_CLASSES[type], 'font-sans font-light [text-wrap:balance] [&_strong]:font-semibold', className)}>
            {children}
        </DynamicTag>
    );
};

export default Heading;
