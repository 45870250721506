import {DrupalParagraph} from 'next-drupal';
import {PARAGRAPH_TYPES} from './constants';
import {AllSections} from './types';
import {cn} from './utils';

export function getSpacing(
    currentNode: DrupalParagraph,
    currentNodeIndex: number,
    currentSectionAllNodes: DrupalParagraph[],
    currentSectionIndex: number,
    allSections: AllSections,
    columnType: 1 | 2 | 3,
    upperSection?: DrupalParagraph,
    lowerSection?: DrupalParagraph
) {
    const upperNode = currentSectionAllNodes?.[currentNodeIndex - 1];
    const lowerNode = currentSectionAllNodes?.[currentNodeIndex + 1];
    let additionalClass = '';

    const getTwClassname = () => {
        const lowerSectionFirstNode = lowerSection?.allNodes?.find(node => node?.behavior_settings?.layout_paragraphs?.parent_uuid === lowerSection?.id);
        const lowerSectionFirstNodeProcessed =
            lowerSectionFirstNode?.type === PARAGRAPH_TYPES.reusableParagraph
                ? lowerSectionFirstNode?.field_reusable_paragraph.paragraphs?.length
                    ? lowerSectionFirstNode?.field_reusable_paragraph?.paragraphs[0]
                    : lowerSectionFirstNode?.field_reusable_paragraph?.paragraphs
                : lowerSectionFirstNode;

        const upperSectionLastNode = upperSection?.allNodes
            ?.reverse()
            ?.find(node => node?.behavior_settings?.layout_paragraphs?.parent_uuid === upperSection?.id);
        const upperSectionLastNodeProcessed =
            upperSectionLastNode?.type === PARAGRAPH_TYPES.reusableParagraph
                ? upperSectionLastNode?.field_reusable_paragraph.paragraphs.length
                    ? upperSectionLastNode?.field_reusable_paragraph.paragraphs[0]
                    : upperSectionLastNode?.field_reusable_paragraph.paragraphs
                : upperSectionLastNode;

        if (
            [PARAGRAPH_TYPES.accordion].includes(lowerSectionFirstNodeProcessed?.type as PARAGRAPH_TYPES) &&
            currentSectionAllNodes.length === currentNodeIndex + 1
        ) {
            additionalClass = cn(additionalClass, 'pb-[40px] md:pb-[80px]');
        }

        if (
            [PARAGRAPH_TYPES.author].includes(lowerSectionFirstNodeProcessed?.type as PARAGRAPH_TYPES) &&
            currentSectionAllNodes.length === currentNodeIndex + 1
        ) {
            additionalClass = cn(additionalClass, 'pb-[50px] md:pb-[80px] lg:pb-[80px]');
        }

        if (upperSectionLastNodeProcessed?.type === PARAGRAPH_TYPES.heroImage && currentNodeIndex === 0) {
            additionalClass = cn(additionalClass, 'pt-12 lg:pt-[120px]');
        }

        if (
            columnType === 1 &&
            ![
                PARAGRAPH_TYPES.image,
                PARAGRAPH_TYPES.heroImage,
                PARAGRAPH_TYPES.slider,
                PARAGRAPH_TYPES.contentSlider,
                PARAGRAPH_TYPES.imageGallery,
                PARAGRAPH_TYPES.keyVisual,
                PARAGRAPH_TYPES.view,
                PARAGRAPH_TYPES.horizontalBanner,
            ].includes(currentNode.type as PARAGRAPH_TYPES)
        ) {
            additionalClass = cn(additionalClass, 'px-8 lg:px-0');
        }

        if (
            !lowerSection &&
            currentSectionAllNodes.length === currentNodeIndex + 1 &&
            ![
                PARAGRAPH_TYPES.heroImage,
                PARAGRAPH_TYPES.slider,
                PARAGRAPH_TYPES.contentSlider,
                PARAGRAPH_TYPES.imageGallery,
                PARAGRAPH_TYPES.keyVisual,
                PARAGRAPH_TYPES.view,
                PARAGRAPH_TYPES.ctaBanner,
                PARAGRAPH_TYPES.horizontalBanner,
                PARAGRAPH_TYPES.search,
            ].includes(currentNode.type as PARAGRAPH_TYPES)
        ) {
            additionalClass = cn(additionalClass, 'pb-[110px]');
        }

        switch (currentNode?.type) {
            case PARAGRAPH_TYPES.callToAction:
                return 'py-20 lg:py-48';
            case PARAGRAPH_TYPES.image:
                if (!upperSection) {
                    return 'self-end rounded-none';
                }
                if (currentSectionAllNodes?.length > 1) {
                    return 'self-end py-[30px]';
                }
                return 'self-end';
            case PARAGRAPH_TYPES.keyVisual:
                return 'pb-[105px] pt-[55px] md:pt-[105px]';
            case PARAGRAPH_TYPES.documentBanner:
                return 'px-6 py-8 lg:px-0';
            case PARAGRAPH_TYPES.linkSelector:
                return 'pt-[80px] pb-[40px]';
            case PARAGRAPH_TYPES.contentSlider: {
                let spacing: string;
                if (upperNode) {
                    spacing = cn(spacing, 'pt-[40px]');
                }
                if (lowerNode) {
                    spacing = cn(spacing, 'pb-[40px]');
                }
                return cn(spacing);
            }
            case PARAGRAPH_TYPES.heading:
            case PARAGRAPH_TYPES.ctaButton:
            case PARAGRAPH_TYPES.imageGallery:
                return 'py-[60px]';
            case PARAGRAPH_TYPES.richText: {
                const defaultSpacing = 'pt-[40px] pb-[15px] lg:pt-[80px] lg:pb-[30px]';
                if (!upperSection && currentNodeIndex === 0) {
                    return '';
                }
                if (
                    upperSection?.field_primary_background_color !== allSections?.[currentSectionIndex]?.field_primary_background_color &&
                    currentNodeIndex === 0
                ) {
                    return defaultSpacing;
                }
                if (upperNode?.type === PARAGRAPH_TYPES.richText || upperSectionLastNodeProcessed?.type === PARAGRAPH_TYPES.richText) {
                    return 'py-[15px] lg:py-[30px]';
                }
                return defaultSpacing;
            }
            case PARAGRAPH_TYPES.author:
                return 'py-[50px] md:py-[80px]';
            case PARAGRAPH_TYPES.accordion:
                if (upperNode?.type === PARAGRAPH_TYPES.richText) {
                    return 'pb-16';
                }
                return 'py-16';
            case PARAGRAPH_TYPES.quote:
                return 'py-[40px]';
            case PARAGRAPH_TYPES.horizontalBanner:
                if (!lowerSection) {
                    return 'md:py-32 pt-16';
                }
                return 'md:py-32 py-16';
            case PARAGRAPH_TYPES.infoBanner:
            case PARAGRAPH_TYPES.remoteVideo:
                return 'py-[30px]';
            default:
                return '';
        }
    };

    return cn(getTwClassname(), additionalClass);
}
