import type {DrupalNode, DrupalParagraph} from 'next-drupal';

import useCreateQueryString from '@/hooks/useCreateQueryString';
import useScreenSize from '@/hooks/useScreenSize';
import {QUERY_PARAMS, SCREENS} from '@/lib/constants';
import {NodePageMeta} from '@/lib/interfaces';
import i18nConfig from '@/next-i18next.config';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {DrupalJsonApiParams} from 'drupal-jsonapi-params';
import {cn} from 'lib/utils';
import {JsonApiResource, deserialize, getSearchIndex} from 'next-drupal';
import {useTranslation} from 'next-i18next';
import {usePathname, useSearchParams} from 'next/navigation';
import {useRouter} from 'next/router';
import Loader from '../atoms/loader';
import Pagination from '../molecules/pagination/pagination';
import SearchResults from '../search-results';

interface ParagraphSearchProps {
    paragraph: DrupalParagraph;
    className?: string;
    nodeMeta: NodePageMeta;
}

interface SearchApiResource extends JsonApiResource {
    meta: {
        count: number;
    };
}

const PAGINATION_LENGTH = 10;

const ParagraphSearch = ({paragraph, nodeMeta, className}: ParagraphSearchProps) => {
    const {t} = useTranslation();
    const router = useRouter();
    const pathname = usePathname();
    const {locale: activeLocale} = router;
    const defaultLocale = i18nConfig.defaultLocale;
    const screen = useScreenSize();
    const searchParams = useSearchParams();
    const searchIndex = paragraph?.field_search_index?.resourceIdObjMeta?.drupal_internal__target_id;
    const page = isNaN(Number(searchParams.get(QUERY_PARAMS.page))) ? 0 : Number(searchParams.get(QUERY_PARAMS.page));
    const searchValue = searchParams.get(QUERY_PARAMS.query);

    const {data, isFetching, isLoading, isPlaceholderData} = useQuery({
        queryKey: [searchIndex, page, searchValue, activeLocale],
        queryFn: async () => {
            const params = new DrupalJsonApiParams()
                .addInclude(['search_api_media', 'search_api_terms'])
                .addFields('node--article', ['title', 'field_image_paragraph', 'field_category', 'path'])
                .addFields('node--product', ['title', 'field_assets', 'field_headline', 'path'])
                .addFields('node--page', ['title', 'field_contents', 'path'])
                .addPageLimit(PAGINATION_LENGTH)
                .addPageOffset(page * PAGINATION_LENGTH);

            if (searchValue !== null) {
                params.addFilter('fulltext', searchValue);
            }

            return getSearchIndex(searchIndex, {
                params: params.getQueryObject(),
                locale: activeLocale,
                defaultLocale: defaultLocale,
                deserialize: false,
            });
        },
        placeholderData: keepPreviousData,
    });
    const meta = (data as unknown as SearchApiResource)?.meta;
    const results = deserialize(data);
    const createQueryString = useCreateQueryString();

    function handlePageChange(page?: number) {
        if (!isPlaceholderData) {
            router.push(pathname + '?' + createQueryString([{name: 'page', value: String(Math.max(page, 0))}]), undefined, {
                shallow: true,
            });
        }
    }

    const allResultsCount = meta?.count ?? 0;

    const showSearchResultCount = () => {
        if (allResultsCount) {
            return !isLoading;
        }
        return !isLoading && !isFetching;
    };

    return (
        <div
            className={cn('relative z-10 mx-auto items-start gap-10 md:w-full', className, {
                'pb-8': Boolean(results?.length) || allResultsCount >= PAGINATION_LENGTH,
            })}
        >
            <div className="flex flex-col items-center">
                <div
                    className={cn('narrow-container grid w-full grid-flow-row gap-10 md:px-12', {
                        'pointer-events-none animate-pulse opacity-70': isLoading || isFetching,
                    })}
                >
                    {showSearchResultCount() && (
                        <p
                            className={cn('my-16 text-[2.5rem] font-light md:my-16', {
                                'text-center': !allResultsCount,
                            })}
                        >
                            <span className="font-semibold">{allResultsCount}</span> {`${t('searchResults')}${allResultsCount ? ':' : '.'}`}
                        </p>
                    )}
                    {results?.map((result: DrupalNode) => <SearchResults key={result.id} node={result} />)}
                </div>

                {(isFetching || isLoading) && !results?.length && (
                    <div className="my-16 h-48">
                        <Loader />
                    </div>
                )}

                {results?.length &&
                    (allResultsCount >= PAGINATION_LENGTH ? (
                        <div className="text:black flex px-2 pt-12 md:justify-center">
                            <Pagination
                                currentPage={page + 1}
                                onPageChange={page => {
                                    handlePageChange(page - 1);
                                }}
                                pageSize={PAGINATION_LENGTH}
                                totalCount={allResultsCount}
                                simple={screen.name !== SCREENS.desktop.name}
                                path={nodeMeta.path}
                            />
                        </div>
                    ) : (
                        <div className="px-2 pt-12" />
                    ))}
            </div>
        </div>
    );
};

export default ParagraphSearch;
