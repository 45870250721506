import logo from '@/assets/logo.svg';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';

interface LogoProps extends React.HTMLAttributes<HTMLAnchorElement> {
    width?: number;
}

const Logo = ({width, className}: LogoProps) => {
    const {locale: activeLocale} = useRouter();
    const {t} = useTranslation();

    return (
        <Link key={activeLocale} href={'/'} locale={activeLocale}>
            <Image className={className} src={logo} alt={t('logoAlt')} width={width ?? 106} height={40} />
        </Link>
    );
};

export default Logo;
