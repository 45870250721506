import {cn} from '@/lib/utils';

type Props = {
    tall?: boolean;
};

const Textfield = ({tall}: Props) => {
    const maxWidth = 300;
    const minWidth = 200;
    const randomWidth = Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth;
    return (
        <div
            className={cn('animate-pulse rounded-xl bg-gray-200 p-[14px]', {
                'h-48': tall,
                'h-12': !tall,
            })}
        >
            <div style={{width: randomWidth}} className="h-full rounded-lg bg-gray-300" />
        </div>
    );
};

const Button = () => (
    <div className={cn('flex h-20 w-52 animate-pulse items-center justify-center rounded-md bg-gray-200 p-[14px]')}>
        <div className="h-8 w-1/2 rounded-lg bg-gray-300" />
    </div>
);

const Webform = () => <div className={cn('h-96 w-full animate-pulse rounded-lg bg-gray-200')} />;

const components = {Textfield, Button, Webform};

export default components;
