import {cn} from '@/lib/utils';
import {ArrowLeft, ArrowRight} from 'lucide-react';
import {useEffect, useState} from 'react';
import {useSwiper} from 'swiper/react';

const SwiperNavigation = () => {
    const swiper = useSwiper();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setActiveIndex(swiper.activeIndex);
        swiper.on('slideChange', e => {
            setActiveIndex(e.activeIndex);
        });
        return () => swiper.off('slideChange');
    }, [swiper]);

    const handleNext = () => {
        swiper.slideNext();
    };

    const handlePrev = () => {
        swiper.slidePrev();
    };

    return (
        <div className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 justify-between">
            <button
                className={cn('z-50', 'rounded-full p-4 transition-colors hover:bg-black/[0.15]', {
                    'pointer-events-none opacity-25': activeIndex === 0,
                })}
                onClick={handlePrev}
            >
                <ArrowLeft />
            </button>
            <button
                className={cn('z-50', 'rounded-full p-4 transition-colors hover:bg-black/[0.15]', {
                    'pointer-events-none opacity-25': activeIndex === swiper.slides.length - 1,
                })}
                onClick={handleNext}
            >
                <ArrowRight />
            </button>
        </div>
    );
};

export default SwiperNavigation;
