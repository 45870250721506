import {useEffect, useState} from 'react';

const useScrollIndicator = (scrollPosition: number): boolean => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const checkScrollPosition = () => {
            const scrolled = document?.scrollingElement?.scrollTop ?? 0;
            if (scrolled >= scrollPosition) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        document.addEventListener('scroll', checkScrollPosition);

        return () => {
            document.removeEventListener('scroll', checkScrollPosition);
        };
    }, [scrollPosition]);

    return isScrolled;
};

export default useScrollIndicator;
