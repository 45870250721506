import type {DrupalClient, DrupalParagraph, FetchOptions} from 'next-drupal';
import type {WebformObject} from 'nextjs-drupal-webform/src/types';
import type {DrupalMenuLinkContentWithWebform} from './interfaces';
import type {WebformSubmissionStatus} from './types';
import type {GetStaticPropsContext} from 'next';

import {normalizeElements} from 'nextjs-drupal-webform'
import {PARAGRAPH_TYPES} from './constants';

async function resolveWebformContent(
    id: string,
    drupal: DrupalClient,
    fetchOptions?: FetchOptions,
    context?: GetStaticPropsContext
  ): Promise<WebformObject> {
    const buildLocaleUrl = (path: string) => {
        const localePath = context?.locale && context?.locale !== context?.defaultLocale
            ? `/${context.locale}${path}`
            : path;
        return drupal.buildUrl(localePath);
    }
    const url = buildLocaleUrl(`/webform/${id}?_format=json`);
    const elementsUrl = buildLocaleUrl(`/webform_rest/${id}/elements?_format=json`);
    const [result, elementsResult] = await Promise.all([
      drupal.fetch(url.toString(), {
        ...fetchOptions,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      drupal.fetch(elementsUrl.toString(), {
        ...fetchOptions,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ]);
    if (!result.ok) {
      const message = await result.json();
      throw new Error(message);
    }
    if (!elementsResult.ok) {
      const message = await elementsResult.json();
      throw new Error(message);
    }
  
    // Clean up some commonly provided, unused properties to reduce the overall
    // size of props.
    const normalizedElements = normalizeElements(await elementsResult.json());
  
    const webform = await result.json();
    return {
      id: id,
      uuid: webform.uuid,
      title: webform.title,
      description: webform.description,
      status: webform.status,
      confirmation: {
        type: webform.settings.confirmation_type,
        url: webform.settings.confirmation_url,
        message: webform.settings.confirmation_message,
      },
      elements: normalizedElements,
    };
  }

export const loadWebformsParagraphs = async (contents: DrupalParagraph[], context: GetStaticPropsContext, drupal: DrupalClient): Promise<void> => {
    for (const content of contents) {
        if (content.type === PARAGRAPH_TYPES.webform) {
            if (content?.field_webform?.resourceIdObjMeta?.drupal_internal__target_id) {
                const targetId = content.field_webform.resourceIdObjMeta.drupal_internal__target_id;
                const webform = await resolveWebformContent(targetId, drupal, {withAuth: true}, context);
                content.field_webform.webform = webform;
            }
        }
    }
};

export const loadWebformsMenu = async (items: DrupalMenuLinkContentWithWebform[], context: GetStaticPropsContext, drupal: DrupalClient): Promise<void> => {
    for (const item of items) {
        if (item?.field_webform?.resourceIdObjMeta?.drupal_internal__target_id) {
            const targetId = item.field_webform.resourceIdObjMeta.drupal_internal__target_id;
            const webform = await resolveWebformContent(targetId, drupal, {withAuth: true}, context);
            item.field_webform.webform = webform;
        }
        if (item.items) {
            await loadWebformsMenu(item.items, context, drupal);
        }
    }
};

export async function customSubmit({id, _event, data, setData, setStatus, setErrors, apiUrl, executeRecaptcha}): Promise<WebformSubmissionStatus> {
    const token = await executeRecaptcha('submit_' + id);
    const body = {...(data as object), ...{webform_id: id}, ...{token}};
    const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
        setStatus('error');
        const message = await response.json();
        const {getNormalizedErrorMessages} = await import('nextjs-drupal-webform');
        setErrors(getNormalizedErrorMessages(message.message.error));
        return 'error';
    } else {
        if (response.status !== 200) {
            setStatus('error');
            setErrors({error: 'Something went wrong'});
            return 'error';
        } else {
            setStatus('success');
            setData({});
            // Clear webform element errors.
            setErrors({});
            return 'success';
        }
    }
}
