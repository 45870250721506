import type {DrupalBlock} from 'next-drupal';

import {Button} from '@/components/atoms/buttons/button';
import {Input} from '@/components/atoms/input';
import {absoluteUrl} from '@/lib/utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {SearchIcon} from 'lucide-react';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import {useRouter} from 'next/navigation';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';
import {Form, FormField} from '../form/form';
import {Dialog, DialogContent, DialogTrigger} from './dialog';
import {DialogHeaderWrapper} from './dialog-elements';

interface SearchBarProps {
    search: DrupalBlock;
    color?: string;
}

const searchSchema = z.object({
    searchValue: z.string(),
});

export type SearchFormValues = z.infer<typeof searchSchema>;

const SearchBar = ({search, color}: SearchBarProps) => {
    const {t} = useTranslation();
    const router = useRouter();
    const [open, setOpen] = useState(false);
    const form = useForm<SearchFormValues>({
        resolver: zodResolver(searchSchema),
    });

    const onSubmit = (values: SearchFormValues) => {
        const {searchValue} = values;
        router.push(search.field_link.url + `?query=${searchValue}`);
        setOpen(false);
        form.reset();
    };

    const handleOpen = () => {
        setOpen(prev => !prev);
    };
    return (
        <>
            <Dialog open={open} onOpenChange={handleOpen}>
                <DialogTrigger asChild>
                    <button className="flex origin-center transform items-center transition-transform hover:scale-100 md:hover:scale-110">
                        <SearchIcon color={color} width={21} height={21} />
                    </button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeaderWrapper handleClose={handleOpen} />
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(onSubmit)}
                            className="mx-auto flex h-[calc(100vh-147px)] max-w-7xl flex-col justify-center gap-[26px] text-[20px] md:flex-row md:items-center md:px-12"
                        >
                            <div className="relative md:flex-grow md:basis-3/4">
                                <label htmlFor="search">
                                    {search.field_image?.field_media_image?.uri?.url && (
                                        <Image
                                            key={search.id}
                                            src={absoluteUrl(search.field_image.field_media_image.uri.url)}
                                            alt={search.field_image.field_media_image.resourceIdObjMeta.alt ?? search.field_image.name}
                                            width={search.field_image.field_media_image.resourceIdObjMeta.width}
                                            height={search.field_image.field_media_image.resourceIdObjMeta.height}
                                            draggable={false}
                                            className="absolute bottom-[42px] left-1/2 z-50 -translate-x-1/2 select-none"
                                        />
                                    )}
                                </label>
                                <FormField
                                    control={form.control}
                                    name="searchValue"
                                    defaultValue=""
                                    render={({field}) => (
                                        <Input
                                            onChange={field.onChange}
                                            value={field.value}
                                            className="h-[80px] rounded-xl p-8 text-left font-medium text-black placeholder:font-medium placeholder:text-black"
                                            id="search"
                                            placeholder={search.field_placeholder_text ?? t('searchPlaceholder')}
                                        />
                                    )}
                                />
                            </div>
                            <Button
                                className="h-[64px] justify-center rounded-xl bg-black text-xl font-semibold text-primary hover:bg-black hover:text-white md:h-[80px] md:flex-grow md:basis-1/4"
                                type="submit"
                            >
                                {search.field_submit_text || t('search')}
                            </Button>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SearchBar;
