import type {DrupalParagraph, JsonApiResource} from 'next-drupal';

import {DehydratedState, QueryClient, dehydrate, queryOptions} from '@tanstack/react-query';
import {DrupalJsonApiParams} from 'drupal-jsonapi-params';
import {GetStaticPropsContext} from 'next';
import {DrupalClient, DrupalView, getView} from 'next-drupal';
import {FETCH_PARAMS, PARAGRAPH_TYPES, QUERY_PARAMS, SORT_ORDER} from './constants';
import {MultiSelectItem, ViewFilter} from './types';

export const addDynamicFilters = async (contents: DrupalParagraph[], context: GetStaticPropsContext, drupal: DrupalClient): Promise<void> => {
    if (contents.some(content => content.type === PARAGRAPH_TYPES.view)) {
        contents
            .filter(content => content.type === PARAGRAPH_TYPES.view)
            .forEach(async content => {
                const displayId = content?.field_view?.resourceIdObjMeta?.display_id;
                const filters: ViewFilter[] =
                    content?.field_view?.display?.[displayId].display_options?.filters || content?.field_view?.display?.default?.display_options?.filters;

                if (filters) {
                    Object.values(filters).forEach(async filter => {
                        const params = new DrupalJsonApiParams();
                        if (filter.exposed) {
                            if ('vid' in filter && filter.vid) {
                                const categoryTerms = await drupal.getResourceCollectionFromContext(`taxonomy_term--${filter.vid}`, context, {
                                    params: params
                                        .addFields(`taxonomy_term--${filter.vid}`, ['drupal_internal__tid', 'id', 'name', 'langcode'])
                                        .getQueryObject(),
                                });
                                if (Object.keys(filter.value).length === 0) {
                                    filter.value = categoryTerms.reduce((acc, term) => {
                                        acc[term.drupal_internal__tid] = term.name;
                                        return acc;
                                    }, {});
                                } else {
                                    categoryTerms.forEach(term => {
                                        if (term.drupal_internal__tid in filter.value) {
                                            filter.value[term.drupal_internal__tid] = term.name;
                                        }
                                    });
                                }
                            }
                            if ('plugin_id' in filter && filter.plugin_id === 'list_field' && Object.keys(filter.value).length === 0) {
                                const listItems = await drupal.getResourceCollectionFromContext('field_storage_config--field_storage_config', context, {
                                    params: params.addFilter('drupal_internal__id', `${filter.table.replace(/__/g, '.')}`).getQueryObject(),
                                    withAuth: true,
                                });
                                if (listItems[0]?.settings?.allowed_values) {
                                    filter.value = listItems[0].settings.allowed_values;
                                }
                            }
                        }
                    });
                }
            });
    }
};

export const preloadViews = async (
    contents: DrupalParagraph[],
    context: GetStaticPropsContext,
    page: number,
    drupal?: DrupalClient
): Promise<DehydratedState> => {
    if (contents.some(content => content.type === PARAGRAPH_TYPES.view)) {
        const queryClient = new QueryClient();
        const activeLocale = context.locale;
        const defaultLocale = context.defaultLocale;

        const prefetchPromises = contents
            .filter(content => content.type === PARAGRAPH_TYPES.view)
            .map(async content => {
                const displayId = content?.field_view?.resourceIdObjMeta?.display_id;
                const viewId = content?.field_view?.resourceIdObjMeta?.drupal_internal__target_id;
                const viewName = `${viewId}--${displayId}`;

                if (viewId === 'product_listing') {
                    return queryClient.prefetchQuery({
                        ...productFetchOptions(
                            viewName,
                            page,
                            activeLocale,
                            defaultLocale,
                            () => {
                                return {};
                            },
                            [],
                            drupal
                        ),
                    });
                }

                if (viewId === 'article_listing') {
                    const headers =
                        content?.field_view?.display?.[displayId]?.display_options?.header || content?.field_view?.display?.default?.display_options?.header;
                    const headerViewName = headers?.view?.view_to_insert ? headers.view.view_to_insert.replace(':', '--') : '';

                    return queryClient.prefetchQuery({
                        ...articleFetchOptions(
                            viewName,
                            headerViewName,
                            page,
                            activeLocale,
                            defaultLocale,
                            () => {
                                return {};
                            },
                            [],
                            drupal
                        ),
                    });
                }
            });

        // Wait for all prefetch queries to complete
        await Promise.all(prefetchPromises);

        return dehydrate(queryClient);
    }
};

export const productFetchOptions = (
    viewName: string,
    page: number,
    activeLocale: string,
    defaultLocale: string,
    getValues: () => any,
    exposedFilters: ViewFilter[],
    drupal?: DrupalClient
) => {
    const fetchData = (
        viewName: string,
        page: number,
        activeLocale: string,
        defaultLocale: string,
        getValues: () => any,
        exposedFilters: ViewFilter[],
        drupal: DrupalClient
    ): Promise<DrupalView<JsonApiResource>> => {
        const filters = getValues();
        const params = {
            [QUERY_PARAMS.page]: String(page),
            [FETCH_PARAMS.viewsArguments]: activeLocale,
            [FETCH_PARAMS.sortBy]: 'sticky',
            [FETCH_PARAMS.sortOrder]: SORT_ORDER.desc,
            [FETCH_PARAMS.include]: 'field_assets,field_assets.field_media_image',
            'fields[node--product]': 'title,field_short_description,field_headline,field_assets,path',
            ...Object.entries(filters).reduce((acc, [key, value]: [key: string, value: MultiSelectItem[]]) => {
                const nextValue = exposedFilters.length
                    ? value &&
                      value?.map(filterValue => filterValue.value).reduce((acc, item, index) => ({...acc, [`views-filter[${key}][${index}]`]: item}), {})
                    : {[`views-filter[${key}]`]: value};
                return {
                    ...acc,
                    ...nextValue,
                };
            }, {}),
        };

        if (drupal?.baseUrl) {
            return drupal.getView(viewName, {
                params: params,
                locale: activeLocale,
                defaultLocale: defaultLocale,
            });
        }
        return getView(viewName, {
            params: params,
            locale: activeLocale,
            defaultLocale: defaultLocale,
        }) as Promise<DrupalView<JsonApiResource>>;
    };

    return queryOptions({
        queryKey: [viewName, activeLocale, page, getValues()],
        queryFn: () => fetchData(viewName, page, activeLocale, defaultLocale, getValues, exposedFilters, drupal),
    });
};

export const articleFetchOptions = (
    viewName: string,
    headerViewName: string,
    page: number,
    activeLocale: string,
    defaultLocale: string,
    getValues: () => any,
    exposedFilters: ViewFilter[],
    drupal?: DrupalClient
) => {
    const fetchData = (
        viewName: string,
        headerViewName: string,
        page: number,
        activeLocale: string,
        defaultLocale: string,
        getValues: () => any,
        exposedFilters: ViewFilter[],
        drupal?: DrupalClient
    ): Promise<DrupalView<JsonApiResource>[]> => {
        const filters = getValues();
        const params = {
            [QUERY_PARAMS.page]: String(page),
            [FETCH_PARAMS.viewsArguments]: activeLocale,
            [FETCH_PARAMS.sortBy]: 'created',
            [FETCH_PARAMS.sortOrder]: SORT_ORDER.desc,
            [FETCH_PARAMS.include]:
                'field_image_paragraph.field_image.field_media_image,field_image_paragraph.field_image_mobile.field_media_image,field_image_paragraph.field_image_tablet.field_media_image',
            'fields[node--article]': 'title,field_body,field_image_paragraph,path',
            'fields[paragraph--hero_image]': 'field_image,field_image_mobile,field_image_tablet',
            ...Object.entries(filters).reduce((acc, [key, value]: [key: string, value: MultiSelectItem[]]) => {
                const nextValue = exposedFilters.length
                    ? value &&
                      value
                          ?.map(filterValue => filterValue.value)
                          .reduce((accumulator, item, index) => ({...accumulator, [`views-filter[${key}][${index}]`]: item}), {})
                    : {[`views-filter[${key}]`]: value};
                return {
                    ...acc,
                    ...nextValue,
                };
            }, {}),
        };
        if (drupal?.baseUrl) {
            return Promise.all([
                drupal.getView(viewName, {params, locale: activeLocale, defaultLocale}),
                drupal.getView(headerViewName, {params, locale: activeLocale, defaultLocale}),
            ]);
        }

        return Promise.all([
            getView(viewName, {params, locale: activeLocale, defaultLocale}) as Promise<DrupalView<JsonApiResource>>,
            getView(headerViewName, {params, locale: activeLocale, defaultLocale}) as Promise<DrupalView<JsonApiResource>>,
        ]);
    };

    return queryOptions({
        queryKey: [viewName, activeLocale, page, getValues()],
        queryFn: () => fetchData(viewName, headerViewName, page, activeLocale, defaultLocale, getValues, exposedFilters, drupal),
    });
};
