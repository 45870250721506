import Logo from 'components/atoms/logo';
import {DialogHeader} from './dialog';

interface DialogHeaderWrapperProps {
    handleClose: () => void;
}

export const DialogHeaderWrapper = ({handleClose}: DialogHeaderWrapperProps) => (
    <DialogHeader className="flex items-center">
        <div onClick={handleClose}>
            <Logo className="h-[83px] w-full max-w-[130px] md:max-w-[180px] lg:max-w-[228px]" />
        </div>
    </DialogHeader>
);
