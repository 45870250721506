import type {DrupalMedia, DrupalParagraph} from 'next-drupal';

import {SCREENS} from '@/lib/constants';
import {absoluteUrl, cn} from '@/lib/utils';
import Image from 'next/image';
import {A11y, Autoplay, EffectFade, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

interface ParagraphImageGalleryProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphImageGallery = ({paragraph, className}: ParagraphImageGalleryProps) => (
    <div className={cn(className)}>
        <Swiper
            modules={[Autoplay, EffectFade, Pagination, A11y]}
            autoplay={{
                delay: 5000,
            }}
            pagination={{clickable: true}}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                400: {
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                480: {
                    slidesPerView: 1.75,
                    centeredSlides: true,
                },
                [SCREENS.mobile.size]: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                [SCREENS.desktop.size]: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
            }}
            slidesPerView={1.5}
            centeredSlides
            className="content-slider swiper-responsive !pb-10 !pt-8"
            spaceBetween={24}
        >
            {paragraph.field_images &&
                paragraph.field_images.map((image: DrupalMedia, index: number) => {
                    if (!image.field_media_image) return null;
                    const {uri, resourceIdObjMeta} = image.field_media_image;
                    const {alt, width, height} = resourceIdObjMeta;
                    return (
                        <SwiperSlide className="" key={`${image.id}${index}`}>
                            <Image
                                className="mx-auto max-h-[250px] min-h-[250px] min-w-[275px] max-w-[275px] rounded-xl object-cover shadow-lg"
                                src={absoluteUrl(uri.url)}
                                alt={alt ?? image.name}
                                width={+width}
                                height={+height}
                            />
                        </SwiperSlide>
                    );
                })}
        </Swiper>
    </div>
);

export default ParagraphImageGallery;
