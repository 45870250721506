const DecorCurve = ({foreground = '#F7F7F7', className}) => (
    <svg className={className} viewBox="0 0 1600 484" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1600 483.963C1589.73 340.963 1575.1 240.063 1551.35 103.716C1544.77 65.4322 1513.04 36.2882 1473.98 32.6332C1052.87 -6.58183 545.007 -11.9168 0 24.6212V483.963H1600Z"
            fill={foreground}
        />
    </svg>
);

export default DecorCurve;
