import type {DrupalParagraph} from 'next-drupal';

import {ImgProps} from 'next/dist/shared/lib/get-img-props';
import {getImageProps as getImageNextProps, ImageProps} from 'next/image';
import {SCREENS} from './constants';
import {absoluteUrl} from './utils';

type DefaultImageProps = ImgProps & {
    alt?: string;
};

export const getImageFields = (imageParagraph: DrupalParagraph): ImageProps => ({
    key: imageParagraph?.id,
    src: imageParagraph?.field_media_image?.uri?.localUrl ?? absoluteUrl(imageParagraph?.field_media_image?.uri?.url),
    alt: imageParagraph?.field_media_image?.resourceIdObjMeta.alt ?? imageParagraph.name,
    width: imageParagraph?.field_media_image?.resourceIdObjMeta?.width ?? 0,
    height: imageParagraph?.field_media_image?.resourceIdObjMeta?.height ?? 0,
});

export const getImageProps = (imageParagraph: DrupalParagraph): DefaultImageProps => {
    if (!imageParagraph?.id) {
        return null;
    }

    return getImageNextProps(getImageFields(imageParagraph))?.props;
};

export const getResponsibleImageProps = (
    paragraphDesktop: DrupalParagraph,
    paragraphTablet: DrupalParagraph,
    paragraphMobile: DrupalParagraph,
    className: string
) => {
    const desktopProps = getImageProps(paragraphDesktop);
    const tabletProps = getImageProps(paragraphTablet);
    const mobileProps = getImageProps(paragraphMobile);

    return {
        imgProps: {
            key: desktopProps?.key,
            alt: desktopProps?.alt,
            decoding: desktopProps?.decoding,
            className,
        },
        desktopSource: {
            srcSet: desktopProps?.srcSet,
            height: desktopProps?.height ?? 0,
            width: desktopProps?.width ?? 0,
            style: desktopProps?.style,
            media: SCREENS.desktop.media,
        },
        tabletSource: tabletProps
            ? {
                  srcSet: tabletProps?.srcSet,
                  height: tabletProps?.height ?? 0,
                  width: tabletProps?.width ?? 0,
                  style: tabletProps?.style,
                  media: SCREENS.tablet.media,
              }
            : null,
        mobileSource: mobileProps
            ? {
                  srcSet: mobileProps?.srcSet,
                  height: mobileProps?.height ?? 0,
                  width: mobileProps?.width ?? 0,
                  style: mobileProps?.style,
                  media: SCREENS.mobile.media,
              }
            : null,
    };
};
