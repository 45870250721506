import {useNavbarContext} from '@/context/NavbarContext';
import useScreenSize from '@/hooks/useScreenSize';
import {SCREENS} from '@/lib/constants';
import {cn} from '@/lib/utils';
import FocusTrap from 'focus-trap-react';
import {AnimatePresence, Variants, motion} from 'framer-motion';
import {X} from 'lucide-react';
import {useRouter} from 'next/router';
import {createContext, useContext, useEffect, useId, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

interface DropdownRootContext {
    open: string;
    handleOpen?: (id: string) => void;
}
interface DropdownItemContext {
    id: string | null;
}

const DropdownRootContext = createContext<DropdownRootContext>({
    open: null,
});

const DropdownItemContext = createContext<DropdownItemContext>({
    id: null,
});

const useDropdownRootContext = () => useContext(DropdownRootContext);
const useDropdownItemContext = () => useContext(DropdownItemContext);

const DropdownPortal = ({children}) => {
    const ref = useRef<Element | null>(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        ref.current = document.querySelector<HTMLElement>('#portal');
        setMounted(true);
    }, []);

    return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export const DropdownItemRoot = ({children}) => <DropdownItemContext.Provider value={{id: useId()}}>{children}</DropdownItemContext.Provider>;

interface DropdownRootProps extends React.PropsWithChildren {
    isOpen?: (value: boolean) => void;
}

export const DropdownRoot = ({children, isOpen}: DropdownRootProps) => {
    const [open, setOpen] = useState(null);
    const {isMenuActive, setIsMenuActive, isMobileMenuActive, setIsMobileMenuActive} = useNavbarContext();
    const router = useRouter();
    const screen = useScreenSize();

    const handleOpen = (id: string) => setOpen(id);

    useEffect(() => {
        if (!isOpen) return;
        isOpen(open !== null);
    }, [open, isOpen]);

    const handleBodyOverflow = () => {
        if (open !== null && isMenuActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };

    useEffect(() => {
        if (screen.name === SCREENS.mobile.name && !isMenuActive) {
            document.body.style.overflow = 'auto';
        }

        if (isMenuActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [screen, isMenuActive]);

    useEffect(() => {
        if (isMobileMenuActive && screen.name === SCREENS.desktop.name) {
            setIsMenuActive(false);
            setIsMobileMenuActive(false);
            setOpen(null);
        }

        if (isMenuActive && !isMobileMenuActive && screen.name !== SCREENS.desktop.name) {
            setIsMobileMenuActive(true);
        }

        if (!isMenuActive && !isMobileMenuActive) {
            setOpen(null);
        }
    }, [isMenuActive, screen, isMobileMenuActive, setIsMenuActive, setIsMobileMenuActive]);

    useEffect(() => {
        router.isReady && setOpen(null);
    }, [router]);

    useEffect(() => {
        handleBodyOverflow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <FocusTrap>
            <DropdownRootContext.Provider value={{open, handleOpen}}>{children}</DropdownRootContext.Provider>
        </FocusTrap>
    );
};

export const DropdownTrigger = ({children, activeTextColor}) => {
    const {id} = useDropdownItemContext();
    const {handleOpen, open} = useDropdownRootContext();
    if (!handleOpen) return null;

    return (
        <button
            className={cn('transition-opacity hover:opacity-40', {
                [activeTextColor]: id === open,
                'opacity-40': id !== open && open !== null,
                'hover:opacity-20': id !== open && open,
            })}
            onClick={() => handleOpen(id)}
        >
            {children}
        </button>
    );
};

export const DropdownChild = ({children}) => {
    const {open} = useDropdownRootContext();

    return (
        <div
            className={cn('transition-opacity', {
                'opacity-40': open !== null,
            })}
        >
            {children}
        </div>
    );
};

interface DropdownContentProps extends React.HTMLAttributes<HTMLDivElement> {}

export const DropdownContent = ({children, ...restProps}: DropdownContentProps) => <DropdownContainer {...restProps}>{children}</DropdownContainer>;

export const DropdownClose = ({as: Component}) => {
    const {open, handleOpen} = useDropdownRootContext();
    return (
        <>
            {open !== null ? (
                <X className="h-8 w-8 transform cursor-pointer transition-transform hover:scale-100 md:hover:scale-110" onClick={() => handleOpen(null)} />
            ) : (
                Component
            )}
        </>
    );
};

interface DropdownContainerProps extends DropdownContentProps {}

const DropdownContainer = ({children, className}: DropdownContainerProps) => {
    const {id} = useDropdownItemContext();
    const {open, handleOpen} = useDropdownRootContext();

    const animationVariants: Variants = {
        open: {
            y: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            },
        },
        close: {
            y: '-100vh',
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <DropdownPortal>
            <AnimatePresence mode="wait">
                {open === id && (
                    <motion.div
                        initial="close"
                        exit="close"
                        animate={'open'}
                        variants={animationVariants}
                        className={cn('absolute left-0 right-0 top-0 z-40 h-full bg-primary lg:block', className)}
                    >
                        <FocusTrap
                            focusTrapOptions={{
                                preventScroll: true,
                                escapeDeactivates: () => {
                                    handleOpen(null);
                                    return true;
                                },
                                onActivate: () => handleOpen(id),
                                allowOutsideClick: true,
                            }}
                        >
                            <div tabIndex={-1} className={cn('h-full w-full')}>
                                {children}
                            </div>
                        </FocusTrap>
                    </motion.div>
                )}
            </AnimatePresence>
        </DropdownPortal>
    );
};
