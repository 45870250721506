import dynamic from 'next/dynamic';
import {memo} from 'react';

interface VideoPlayerProps {
    url: string;
    title?: string;
    containerClassName?: string;
}

const VideoPlayer = ({url, title, containerClassName}: VideoPlayerProps) => {
    const ReactPlayer = dynamic(() => import('react-player/youtube'), {ssr: false});

    return (
        <div title={title} className={containerClassName}>
            <ReactPlayer width="100%" height="100%" style={{aspectRatio: '16 / 9'}} url={url} controls />
        </div>
    );
};

export default memo(VideoPlayer);
