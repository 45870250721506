import type {DrupalParagraph} from 'next-drupal';

import Body from '@/components/atoms/body';
import useScreenSize from '@/hooks/useScreenSize';
import {SCREENS, TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {absoluteUrl, cn} from '@/lib/utils';
import Image from 'next/image';
interface ParagraphQuoteProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphQuote = ({paragraph, className}: ParagraphQuoteProps) => {
    const screen = useScreenSize();
    const isDesktop = screen.name === SCREENS.desktop.name;

    const IMAGE_POSITIONS = {
        left: 'ml-[-80px] mr-[30px] h-[187px] max-h-[187px] w-[187px] max-w-[187px] flex-1 rounded-full object-cover order-0',
        right: 'mr-[-80px] ml-[30px] h-[187px] max-h-[187px] w-[187px] max-w-[187px] flex-1 rounded-full object-cover order-1',
        center: 'max-h-[187px] w-[187px] h-[187px] max-w-[187px] flex-1 rounded-full object-cover',
    };

    const CONTAINER_STYLES = {
        left: 'w-100 mb-[32px] flex flex-row items-center',
        right: 'w-100 mb-[32px] flex flex-row items-center',
        center: 'w-100 mb-[32px] flex flex-col items-center',
    };

    const imageDesktopClass = 'h-[187px] max-h-[187px] w-[187px] max-w-[187px] flex-1 rounded-full object-cover md:mx-12';
    const imageMobileClass = paragraph?.field_horizontal_position ? IMAGE_POSITIONS[paragraph?.field_horizontal_position] : IMAGE_POSITIONS.left;

    const containerDesktopClass = 'flex-1';
    const containerMobileClass = paragraph?.field_horizontal_position ? CONTAINER_STYLES[paragraph?.field_horizontal_position] : CONTAINER_STYLES.left;

    const textColor = paragraph?.field_text_color ? TEXT_COLOR_VARIANTS[paragraph?.field_text_color] : TEXT_COLOR_VARIANTS['text-black'];
    const imageProps = paragraph?.field_image
        ? {
              alt: paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name,
              attributes: {
                  key: paragraph.id,
                  src: absoluteUrl(paragraph.field_image.field_media_image.uri.url),
                  width: paragraph.field_image.field_media_image.resourceIdObjMeta.width,
                  height: paragraph.field_image.field_media_image.resourceIdObjMeta.height,
              },
          }
        : null;

    const renderImage = () =>
        paragraph?.field_image ? <Image alt={imageProps.alt} {...imageProps.attributes} className={isDesktop ? imageDesktopClass : imageMobileClass} /> : null;

    const renderTitle = () =>
        paragraph?.field_title?.processed ? (
            <Body
                className={cn('prose text-left text-[17px] font-medium leading-[30px]', isDesktop ? 'py-[63px]' : 'flex-1 pt-[32px]', {textColor})}
                value={paragraph.field_title?.processed}
            />
        ) : null;

    const renderText = () =>
        paragraph?.field_body?.processed ? (
            <Body className={cn('font-regular text-left text-base leading-[25px]', 'prose', {textColor})} value={paragraph.field_body?.processed} />
        ) : null;

    const renderParagraphBody = () =>
        isDesktop ? (
            <>
                {renderImage()}
                <div className={containerDesktopClass}>
                    {renderTitle()}
                    {renderText()}
                </div>
            </>
        ) : (
            <>
                <div className="flex flex-col">
                    <div className={containerMobileClass}>
                        {renderImage()}
                        {renderTitle()}
                    </div>
                    {renderText()}
                </div>
            </>
        );

    return <div className={cn('narrow-container mx-auto flex flex-col overflow-hidden md:flex-row', className)}>{renderParagraphBody()}</div>;
};

export default ParagraphQuote;
