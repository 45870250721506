import {Metatag, SchemaMetatag, SchemaObject} from '@/lib/interfaces';
import {PathAlias} from 'next-drupal';
import Head from 'next/head';

interface MetaProps {
    title?: string;
    path?: PathAlias;
    metatags?: Metatag[] | SchemaMetatag[];
    domains?: string;
    page?: number;
}

export const Meta = ({title, path, metatags, page}: MetaProps) => {
    const domainConfig = JSON.parse(process.env.NEXT_PUBLIC_DOMAIN_CONFIG);
    const baseUrl = domainConfig[path.langcode] ? `https://${domainConfig[path.langcode]}/${path.langcode}` : '';

    const isImageUrl = (url: string): boolean => {
        const pattern = /\.(jpeg|jpg|gif|png|svg|webp)$/i;
        return pattern.test(url);
    };

    const replaceBackendUrls = (value: string | SchemaObject, baseUrl: string): string | SchemaObject => {
        if (typeof value === 'string') {
            if (!isImageUrl(value)) {
                if (value.includes(`/${path.langcode}`)) {
                    return value.replace(`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${path.langcode}`, baseUrl);
                }
                return value.replace(process.env.NEXT_PUBLIC_DRUPAL_BASE_URL, baseUrl);
            }
            return value;
        } else if (typeof value === 'object' && value !== null) {
            Object.keys(value).forEach(key => {
                value[key] = replaceBackendUrls(value[key], baseUrl);
            });
        }
        return value;
    };

    const processMetatags = (metatags: Metatag[] | SchemaMetatag[]): Metatag[] => {
        return metatags.map(item => {
            if (item.attributes) {
                if (item.attributes.href && typeof item.attributes.href === 'string') {
                    item.attributes.href = replaceBackendUrls(item.attributes.href, baseUrl);
                }
                if (item.attributes.content) {
                    item.attributes.content = replaceBackendUrls(item.attributes.content, baseUrl);
                }
            }
            return item;
        });
    };

    metatags = processMetatags(metatags);
    const regularMetatags = metatags?.filter(tag => !('schema_metatag' in tag.attributes)) as Metatag[];

    const getTag = (str: string, key: keyof Metatag['attributes'] = 'name') => regularMetatags?.find(tag => tag.attributes[key] === str)?.attributes;

    const spliceTag = (str: string, key: keyof Metatag['attributes'] = 'name'): Metatag | SchemaMetatag => {
        const index = regularMetatags?.findIndex(tag => tag.attributes[key] === str);
        if (index !== undefined && index !== -1) {
            return regularMetatags?.splice(index, 1)[0];
        }
    };

    const schemaMetatags = metatags?.filter(metatag => 'schema_metatag' in metatag.attributes) as SchemaMetatag[];

    const schemaMetatagsGroups =
        schemaMetatags?.reduce((acc: Record<string, SchemaMetatag[]>, tag: SchemaMetatag) => {
            const group = tag.attributes?.group;
            acc[group] = acc[group] || [];
            acc[group].push(tag);
            return acc;
        }, {}) || {};

    const schemaJsonLdScripts = Object.entries(schemaMetatagsGroups).map(([group, tags]) => {
        const schemaJson = tags.reduce(
            (acc, tag) => {
                const property = tag.attributes?.name;
                const content = tag.attributes?.content;
                acc['@context'] = 'https://schema.org';
                if (Array.isArray(content)) {
                    acc[property] = content.map(item => {
                        if (typeof item === 'object' && item['@type']) {
                            return {'@type': item['@type'], ...item};
                        }
                        return item;
                    });
                } else if (typeof content === 'object' && content['@type']) {
                    acc[property] = {'@type': content['@type'], ...content};
                } else {
                    acc[property] = content;
                }

                return acc;
            },
            {'@type': group}
        );

        return <script key={`schema-json-ld-${group}`} type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(schemaJson)}} />;
    });

    const computedTitle = getTag('title')?.content || `${title} | Josera Petfood`;
    const computedCanonical = (spliceTag('canonical', 'rel') as Metatag) || {attributes: {href: `${baseUrl}/${path.alias}`}};
    page = page > 0 ? page + 1 : 0;
    return (
        <Head>
            <title>{computedTitle}</title>
            <link rel="canonical" href={page ? `${computedCanonical?.attributes.href}/${page}` : computedCanonical.attributes.href} />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            {regularMetatags?.map(metatag => {
                const TagName = metatag.tag;
                const key = metatag?.attributes?.name || metatag?.attributes?.property || metatag?.attributes?.rel || metatag?.attributes?.['http-equiv'];
                return <TagName key={key} {...metatag.attributes} />;
            })}
            {schemaJsonLdScripts}
        </Head>
    );
};
