import type {DrupalParagraph} from 'next-drupal';

import {getResponsibleImageProps} from '@/lib/get-image-props';
import useScreenSize from 'hooks/useScreenSize';
import {cn} from 'lib/utils';
import ParagraphCtaButton from './paragraph--cta-button';
import ParagraphHeading from './paragraph--heading';

interface ParagraphImageProps {
    paragraph: DrupalParagraph;
    imageClassName?: string;
    buttonClassName?: string;
    className?: string;
    slide?: boolean;
    full?: boolean;
}

const ParagraphImage = ({paragraph, imageClassName, buttonClassName, className, slide, full = false}: ParagraphImageProps) => {
    const screen = useScreenSize();
    const isHeading = Boolean(paragraph.field_heading_paragraph?.field_title);
    const isButton = Boolean(paragraph.field_cta_button_paragraph?.field_link?.title);
    const style = paragraph?.class_name;
    const paragraphImageDesktop = paragraph?.field_image;
    const paragraphImageMobile = paragraph?.field_image_mobile;
    const paragraphImageTablet = paragraph?.field_image_tablet;

    const imgClassName = cn(
        ' w-full ',
        full ? 'object-cover' : 'object-contain',
        {
            'h-full': slide,
        },
        imageClassName
    );

    const {
        imgProps: {alt: imageAlt, ...restImageProps},
        desktopSource,
        mobileSource,
        tabletSource,
    } = getResponsibleImageProps(paragraphImageDesktop, paragraphImageTablet, paragraphImageMobile, imgClassName);

    return (
        <>
            {screen.name && paragraphImageDesktop && (
                <article
                    className={cn(
                        'relative',
                        {
                            'h-full': slide,
                        },
                        className,
                        style
                    )}
                >
                    <picture>
                        <source {...desktopSource} />
                        {tabletSource && <source {...tabletSource} />}
                        {mobileSource && <source {...mobileSource} />}
                        <img alt={imageAlt} srcSet={desktopSource.srcSet} {...restImageProps} />
                    </picture>

                    {(isHeading || isButton) && (
                        <div className="wide-container absolute left-1/2 top-0 z-10 flex h-full w-full -translate-x-1/2 flex-col justify-end gap-8 p-6 md:p-16 lg:p-24">
                            {isHeading && (
                                <ParagraphHeading
                                    className="max-w-[445px] text-[27px] font-light leading-[35px] lg:text-[40px] lg:leading-[50px]"
                                    containerClassName="h-2/3"
                                    paragraph={paragraph.field_heading_paragraph}
                                />
                            )}
                            {isButton && <ParagraphCtaButton className={cn('h-1/3', buttonClassName)} paragraph={paragraph.field_cta_button_paragraph} />}
                        </div>
                    )}
                </article>
            )}
        </>
    );
};

export default ParagraphImage;
