import type {DrupalParagraph} from 'next-drupal';

import {COLOR_VARIANTS, TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {absoluteUrl, cn} from 'lib/utils';
import Image from 'next/image';
import ParagraphCtaButton from './paragraph--cta-button';
import ParagraphHeading from './paragraph--heading';

interface ParagraphHorizontalBannerProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphHorizontalBanner = ({paragraph, className}: ParagraphHorizontalBannerProps) => {
    // TODO: Add support for mobile image
    const HORIZONTAL_ALIGNMENT_CLASSES = {
        left: 'md:justify-start',
        center: 'md:justify-center',
        right: 'md:justify-end',
    } as const;

    const VERTICAL_ALIGNMENT_CLASSES = {
        top: 'md:items-start',
        center: 'md:items-center',
        bottom: 'md:items-end',
    } as const;

    const backgroundColorPrimary = paragraph?.field_primary_background_color
        ? COLOR_VARIANTS[paragraph?.field_primary_background_color]
        : COLOR_VARIANTS['bg-white'];
    const textColor = paragraph?.field_heading_paragraph?.field_text_color ?? TEXT_COLOR_VARIANTS['text-white'];

    const textAlignmentHorizontal = paragraph?.field_heading_paragraph?.field_horizontal_position;
    const textAlignmentHorizontalClass = textAlignmentHorizontal ? HORIZONTAL_ALIGNMENT_CLASSES[textAlignmentHorizontal] : HORIZONTAL_ALIGNMENT_CLASSES.left;

    const textAlignmentVertical = paragraph?.field_heading_paragraph?.field_vertical_position;
    const textAlignmentVerticalClass = textAlignmentVertical ? VERTICAL_ALIGNMENT_CLASSES[textAlignmentVertical] : VERTICAL_ALIGNMENT_CLASSES.center;

    const buttonAlignmentHorizontal = paragraph?.field_cta_button_paragraph?.field_horizontal_position;
    const buttonAlignmentHorizontalClass = textAlignmentHorizontal
        ? HORIZONTAL_ALIGNMENT_CLASSES[buttonAlignmentHorizontal]
        : HORIZONTAL_ALIGNMENT_CLASSES.right;

    const buttonAlignmentVertical = paragraph?.field_cta_button_paragraph?.field_vertical_position;
    const buttonAlignmentVerticalClass = textAlignmentVertical ? VERTICAL_ALIGNMENT_CLASSES[buttonAlignmentVertical] : VERTICAL_ALIGNMENT_CLASSES.center;

    return (
        <div className={cn(className)}>
            <div className="pt-24 md:px-8">
                <div
                    className={cn(
                        'relative flex min-h-[420px] flex-col flex-wrap justify-center px-6 pb-0 text-center md:max-h-[200px] md:min-h-[200px] md:flex-row md:rounded-2xl lg:px-0',
                        backgroundColorPrimary
                    )}
                >
                    {paragraph?.field_heading_paragraph && (
                        <div className={cn('flex flex-1 items-start justify-center', textAlignmentHorizontalClass, textAlignmentVerticalClass)}>
                            <ParagraphHeading
                                className={cn(
                                    'mt-8 max-w-[340px] py-4 text-center text-[24px] leading-[36px] md:mx-16 md:mt-0 md:text-start lg:text-[40px] lg:leading-[48px]',
                                    textColor
                                )}
                                paragraph={paragraph.field_heading_paragraph}
                            />
                        </div>
                    )}

                    {paragraph?.field_image && (
                        <div className="flex-2 flex">
                            <div className="absolute bottom-0 left-1/2 w-full -translate-x-1/2 transform md:w-5/12">
                                <Image
                                    key={paragraph.id}
                                    src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                                    alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                                    width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                                    height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                                    className="max-h-[280px] object-contain md:max-h-[320px]"
                                />
                            </div>
                        </div>
                    )}

                    {paragraph?.field_cta_button_paragraph && (
                        <div className={cn('flex flex-1 items-end justify-center py-4', buttonAlignmentHorizontalClass, buttonAlignmentVerticalClass)}>
                            <ParagraphCtaButton paragraph={paragraph.field_cta_button_paragraph} className="mx-16 mb-4 md:mb-0" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ParagraphHorizontalBanner;
