import type {DrupalParagraph} from 'next-drupal';

import {cn} from 'lib/utils';
import Body from '../atoms/body';
import ParagraphCtaButton from './paragraph--cta-button';
import ParagraphHeading from './paragraph--heading';

interface ParagraphCallToActionProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphCallToAction = ({paragraph, className}: ParagraphCallToActionProps) => (
    <div className={cn('relative', className)}>
        <div className="flex flex-col items-center justify-center px-4 text-center lg:items-start">
            {paragraph.field_heading_paragraph && (
                <ParagraphHeading
                    className="max-w-[70%]"
                    containerClassName="max-lg:justify-center max-lg:text-center"
                    paragraph={paragraph.field_heading_paragraph}
                />
            )}

            {paragraph.field_body?.processed && (
                <div className="flex  items-center justify-center text-center lg:text-left">
                    <Body className="mb-11 mt-9 line-clamp-4 h-[calc(25px*4)] leading-[25px]" value={paragraph.field_body?.processed} />
                </div>
            )}

            {paragraph.field_cta_button_paragraph && <ParagraphCtaButton paragraph={paragraph.field_cta_button_paragraph} />}
        </div>
    </div>
);

export default ParagraphCallToAction;
