import type {DrupalParagraph} from 'next-drupal';

import Body from '@/components/atoms/body';
import {TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {absoluteUrl, cn} from '@/lib/utils';
import Image from 'next/image';
interface ParagraphAuthorProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphAuthor = ({paragraph, className}: ParagraphAuthorProps) => {
    const textColor = paragraph?.field_text_color ? TEXT_COLOR_VARIANTS[paragraph?.field_text_color] : TEXT_COLOR_VARIANTS['text-black'];

    return (
        <div className={cn('narrow-container mx-auto', className)}>
            {paragraph.field_image && (
                <Image
                    key={paragraph.id}
                    src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
                    alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt ?? paragraph.field_image.name}
                    width={paragraph.field_image.field_media_image.resourceIdObjMeta.width}
                    height={paragraph.field_image.field_media_image.resourceIdObjMeta.height}
                    className="mx-auto w-full max-w-sm object-cover px-16 transition-transform duration-300 ease-in-out group-hover:-translate-y-4 group-hover:scale-110"
                />
            )}
            <div className="pb-[33px] pt-[27px]">
                {paragraph.field_title?.processed && (
                    <Body
                        className={cn('text-center text-[20px] font-semibold leading-[30px]', 'prose', {textColor})}
                        value={paragraph.field_title?.processed}
                    />
                )}
                {paragraph.field_subtitle?.processed && (
                    <Body
                        className={cn('font-regular text-center text-base leading-[25px]', 'prose', {textColor})}
                        value={paragraph.field_subtitle?.processed}
                    />
                )}
            </div>
            {paragraph.field_body?.processed && (
                <Body
                    className={cn('font-regular text-left text-base leading-[25px] md:text-center', 'prose', {textColor})}
                    value={paragraph.field_body?.processed}
                />
            )}
        </div>
    );
};

export default ParagraphAuthor;
