import type {WebformObject} from 'nextjs-drupal-webform/src/types';

import CTAButton from '@/components/atoms/buttons/cta-button';
import WebformComponent from '@/components/webforms/WebformComponent';
import {cn} from '@/lib/utils';
import {useState} from 'react';
import {Dialog, DialogContent, DialogTrigger} from './dialog';
import {DialogHeaderWrapper} from './dialog-elements';

interface Props {
    webform: WebformObject;
    triggerText: string;
    icon: React.ReactNode;
    buttonClassName?: string;
}

const ContactDialog = ({webform, triggerText, icon, buttonClassName}: Props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(prev => !prev);
    };

    return (
        <>
            <Dialog open={open} onOpenChange={handleOpen}>
                <DialogTrigger asChild>
                    <div>
                        <CTAButton
                            textColor="text-primary"
                            backgroundColor="bg-black"
                            excludeArrow
                            className={cn('gap-2 text-sm font-semibold', buttonClassName)}
                            icon={icon}
                        >
                            {triggerText}
                        </CTAButton>
                    </div>
                </DialogTrigger>
                <DialogContent className="!pb-0">
                    <DialogHeaderWrapper handleClose={handleOpen} />
                    <WebformComponent
                        modal
                        onClose={() => {
                            setOpen(false);
                        }}
                        webform={webform}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ContactDialog;
