import type {HeadingProps} from '@/lib/interfaces';
import type {DrupalParagraph} from 'next-drupal';

import {TEXT_COLOR_VARIANTS} from '@/lib/constants';
import {cn} from '@/lib/utils';
import {getAlignmentClasses} from 'lib/alignment';
import Heading from '../atoms/heading';
import ParagraphIcon from './paragraph--icon';

interface ParagraphHeadingProps extends HeadingProps {
    paragraph: DrupalParagraph;
    containerClassName?: string;
}

const ParagraphHeading = ({paragraph, containerClassName, ...props}: ParagraphHeadingProps) => {
    const alignmentClasses = getAlignmentClasses({
        horizontalAlignment: paragraph.field_horizontal_position,
        verticalAlignment: paragraph.field_vertical_position,
        textAlignment: paragraph.field_horizontal_position,
    });
    const textColor = TEXT_COLOR_VARIANTS[paragraph.field_text_color] ?? TEXT_COLOR_VARIANTS['text-black'];
    const isIcon = Boolean(paragraph?.field_icon_paragraph?.field_image?.field_media_image?.uri?.url);
    const iconHorizontalAlignment = paragraph?.field_icon_paragraph?.field_horizontal_position ?? 'left';
    const iconVerticalAlignment = paragraph?.field_icon_paragraph?.field_vertical_position ?? 'center';

    const renderHeading = () => (
        <Heading
            {...props}
            type={paragraph.field_heading_type || 'h1'}
            tag={paragraph.field_heading_type || 'h1'}
            dangerouslySetInnerHTML={{
                __html: paragraph.field_title?.processed,
            }}
        />
    );

    return isIcon ? (
        <div className={cn('flex', alignmentClasses, textColor, containerClassName)}>
            <div
                className={cn('flex flex-col', {
                    'md:flex-row': !((iconHorizontalAlignment === 'center' && iconVerticalAlignment === 'center') || iconVerticalAlignment !== 'center'),
                    'md:flex-col': (iconHorizontalAlignment === 'center' && iconVerticalAlignment === 'center') || iconVerticalAlignment !== 'center',
                    'items-center justify-center': iconVerticalAlignment === 'center' && iconHorizontalAlignment !== 'center',
                })}
            >
                <ParagraphIcon
                    className={cn('order-0 flex-row', {
                        'md:order-0 pr-6': iconHorizontalAlignment === 'left' && iconVerticalAlignment === 'center',
                        'pl-6 md:order-1': (iconHorizontalAlignment === 'right' && iconVerticalAlignment === 'center') || iconVerticalAlignment === 'bottom',
                    })}
                    paragraph={paragraph.field_icon_paragraph}
                    imageClassName={cn('mx-auto', {
                        'md:ml-0 md:mr-auto': iconHorizontalAlignment === 'left' && iconVerticalAlignment !== 'center',
                        'md:ml-auto md:mr-0': iconHorizontalAlignment === 'right' && iconVerticalAlignment !== 'center',
                        'md:mx-auto':
                            (iconHorizontalAlignment === 'center' && iconVerticalAlignment !== 'center') ||
                            (iconVerticalAlignment === 'center' && iconHorizontalAlignment === 'center'),
                    })}
                />
                {renderHeading()}
            </div>
        </div>
    ) : (
        <div className={cn(alignmentClasses, textColor, containerClassName)}>{renderHeading()}</div>
    );
};

export default ParagraphHeading;
