import {cn} from 'lib/utils';

interface TextProps extends React.HTMLProps<HTMLParagraphElement> {
    html?: string;
}

const Text: React.FC<TextProps> = ({children, className, html, ...props}) => (
    <div
        {...(html && {
            dangerouslySetInnerHTML: {
                __html: html,
            },
        })}
        className={cn('text-base font-normal', className)}
        {...props}
    >
        {children}
    </div>
);

export default Text;
