interface AlignmentProps {
    horizontalAlignment?: 'left' | 'center' | 'right';
    verticalAlignment?: 'top' | 'center' | 'bottom';
    textAlignment?: 'left' | 'center' | 'right';
}

export const getAlignmentClasses = (props: AlignmentProps) => {
    const JUSTIFY_CLASSES = {
        left: 'justify-start',
        center: 'justify-center',
        right: 'justify-end',
    } as const;

    const ITEMS_CLASSES = {
        top: 'items-start',
        center: 'items-center',
        bottom: 'items-end',
    } as const;

    const TEXT_CLASSES = {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    } as const;

    const justifyClass = JUSTIFY_CLASSES[props.horizontalAlignment] ?? JUSTIFY_CLASSES.center;
    const itemsClass = ITEMS_CLASSES[props.verticalAlignment] ?? ITEMS_CLASSES.center;
    const textAlign = TEXT_CLASSES[props.textAlignment] ?? TEXT_CLASSES.center;

    return `flex ${justifyClass} ${itemsClass} ${textAlign}`;
};
