import {motion, MotionConfig, type Variants} from 'framer-motion';

const Filter = () => {
    const variantFirstCircle: Variants = {
        initial: {
            translateX: -2.584,
            translateY: -1.306,
        },
        hover: {
            translateX: 4.416,
            translateY: -1.306,
        },
    };
    const variantSecondCircle: Variants = {
        initial: {
            translateX: -9.282,
            translateY: -1.726,
        },
        hover: {
            translateX: -16.282,
            translateY: -1.726,
        },
    };
    const variantFirstLine1: Variants = {
        initial: {
            translateX: 11.202,
            translateY: 2.66,
            x2: 11.202,
        },
        hover: {
            translateX: 18.202,
            translateY: 2.66,
            x2: 4.202,
        },
    };
    const variantFirstLine2: Variants = {
        initial: {
            x2: 4.205,
        },
        hover: {
            x2: 12.202,
        },
    };
    const variantSecondLine1: Variants = {
        initial: {
            translateX: 18.199,
            translateY: 13.693,
            x1: 4.205,
        },
        hover: {
            translateX: 10.202,
            translateY: 13.693,
            x1: 12.202,
        },
    };
    const variantSecondLine2: Variants = {
        initial: {
            x1: 11.678,
        },
        hover: {
            x1: 4,
        },
    };

    return (
        <MotionConfig
            transition={{
                ease: 'easeInOut',
                type: 'tween',
            }}
        >
            <motion.svg
                className="overflow-hidden"
                initial={'initial'}
                whileHover={'hover'}
                xmlns="http://www.w3.org/2000/svg"
                width="51"
                height="48"
                viewBox="0 0 51 48"
            >
                <g fill="#fecc00" stroke="#f9cc01" strokeWidth="2">
                    <rect width="51" height="48" rx="12" stroke="none" />
                    <rect x="1" y="1" width="49" height="46" rx="11" fill="none" />
                </g>
                <g transform="translate(14.298 16.051)">
                    <motion.path
                        d="M13.108,3.966a2.659,2.659,0,1,0-2.66,2.66A2.66,2.66,0,0,0,13.108,3.966Z"
                        fill="none"
                        stroke="#000"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                        variants={variantFirstCircle}
                    />
                    <motion.line
                        variants={variantFirstLine2}
                        transform="translate(0 2.66)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <motion.line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" variants={variantFirstLine1} />
                    <motion.path
                        d="M21.162,15.419a2.659,2.659,0,1,0,2.66-2.66A2.66,2.66,0,0,0,21.162,15.419Z"
                        fill="none"
                        stroke="#000"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                        variants={variantSecondCircle}
                    />
                    <motion.line variants={variantSecondLine1} fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <motion.line
                        variants={variantSecondLine2}
                        transform="translate(0.202 13.693)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                </g>
            </motion.svg>
        </MotionConfig>
    );
};

export default Filter;
